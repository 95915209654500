export const ActionTypes = {
    IS_LOGIN: "IS_LOGIN",
    GET_USER_ID: "GET_USER_ID",
    GET_DEVICE_ID: "GET_DEVICE_ID",
    SELF_DETAILS: "SELF_DETAILS",
    EDIT_SELF_DETAILS: "EDIT_SELF_DETAILS",
    GET_ACCESSTOKEN: "GET_ACCESSTOKEN",
    ADMIN_LOGOUT: "ADMIN_LOGOUT",
    GET_PERMISSION_LIST: "GET_PERMISSION_LIST",
    GET_USER_LIST: "GET_USER_LIST",
    GET_RELATION_LIST:"GET_RELATION_LIST",
    GET_USER_LIST_ID_WISE: "GET_USER_LIST_ID_WISE",
    ADD_USER_LIST: "ADD_USER_LIST",
    EDIT_USER_LIST: "EDIT_USER_LIST",
    DELETE_USER: "DELETE_USER",
    GET_BRAND_LIST: "GET_BRAND_LIST",
    ADD_NEW_BRAND: "ADD_NEW_BRAND",
    EDIT_BRAND: "EDIT_BRAND",
    DELETE_BRAND: "DELETE_BRAND",
    GET_MODEL_LIST: "GET_MODEL_LIST",
    ADD_NEW_MODEL: "ADD_NEW_MODEL",
    EDIT_MODEL: "EDIT_MODEL",
    DELETE_MODEL: "DELETE_MODEL",
    GET_BRAND_MODELS_LIST: "GET_BRAND_MODELS_LIST",
    GET_SUPPLIER_LIST: "GET_SUPPLIER_LIST",
    GET_PRODUCT_LIST: "GET_PRODUCT_LIST",
    ADD_SUPPLIER_LIST: "ADD_SUPPLIER_LIST",
    UPDATE_SUPPLIER_LIST: "UPDATE_SUPPLIER_LIST",
    GET_ALL_BRANDS_LIST: "GET_ALL_BRANDS_LIST",
    GET_ALL_MODEL_LIST: "GET_ALL_MODEL_LIST",
    SELECT_BRAND_GET_MODEL_LIST: "SELECT_BRAND_GET_MODEL_LIST",
    ADD_PRODUCT_LIST: "ADD_PRODUCT_LIST",
    UPDATE_PRODUCT_LIST: "UPDATE_PRODUCT_LIST",
    VIEW_PRODUCT_DETAILS: "VIEW_PRODUCT_DETAILS",
    GET_SUPPLIER_DETAILS: "GET_SUPPLIER_DETAILS",
    DELETE_SUPPLIER: "DELETE_SUPPLIER",
    LOADER_MAIN_START: "LOADER_MAIN_START",
    GET_ALL_SUPPLIERS_LIST: "GET_ALL_SUPPLIERS_LIST",
    DELETE_PRODUCT: "DELETE_PRODUCT",
    GET_ALL_PRODUCT_LIST: "GET_ALL_PRODUCT_LIST",
    GET_DASHBOARD_DETAILS: "GET_DASHBOARD_DETAILS",
    GET_CATEGORY_LIST:"GET_CATEGORY_LIST",
    ADD_CATEGORY_LIST:"ADD_CATEGORY_LIST",
    UPDATE_CATEGORY_LIST:"UPDATE_CATEGORY_LIST",
    DELETE_CATEGORY_LIST:"DELETE_CATEGORY_LIST",
    GET_ALL_CATEGORY_LIST:"GET_ALL_CATEGORY_LIST",
    CLEAR_FORM_SET_FUCT:"CLEAR_FORM_SET_FUCT",
    GET_SETTINGS_LIST: "GET_SETTINGS_LIST",
    ADD_SETTINGS_LIST: "ADD_SETTINGS_LIST",
    DELETE_SETTINGS_LIST: "DELETE_SETTINGS_LIST",
    UPDATE_SETTINGS_LIST: "UPDATE_SETTINGS_LIST",
    GET_ALL_FAQ_LIST:"GET_ALL_FAQ_LIST",
    GET_FAQ_LIST:"GET_FAQ_LIST",
    UPDATE_FAQ_LIST:"UPDATE_FAQ_LIST",
    ADD_FAQ_LIST:"ADD_FAQ_LIST",
    DELETE_FAQ:"DELETE_FAQ",
    GET_ALL_USER_MEMBER_LIST:"GET_ALL_USER_MEMBER_LIST",
    GET_USER_MEMBER_LIST:"GET_USER_MEMBER_LIST",
    ADD_USER_MEMBER_LIST:"ADD_USER_MEMBER_LIST",
    UPDATE_USER_MEMBER_LIST:"UPDATE_USER_MEMBER_LIST",
    DELETE_USER_MEMBER:"DELETE_USER_MEMBER",
    GET_MEMBER_LIST_BY_USER_ID:"GET_MEMBER_LIST_BY_USER_ID",
    GET_USERS_MEMBER_LIST_BY_ID:"GET_USERS_MEMBER_LIST_BY_ID",
    GET_ALERT_LIST_BY_USER_ID:"GET_ALERT_LIST_BY_USER_ID",
    GET_USERS_ALERT_LIST_BY_ID:"GET_USERS_ALERT_LIST_BY_ID",
    GET_SOS_LIST_BY_USER_ID:"GET_SOS_LIST_BY_USER_ID",
    GET_USERS_SOS_LIST_BY_ID:"GET_USERS_SOS_LIST_BY_ID",
    GET_PATIENT_LIST_BY_USER_ID:"GET_PATIENT_LIST_BY_USER_ID",
    GET_USERS_PATIENT_LIST_BY_ID:"GET_USERS_PATIENT_LIST_BY_ID",
    GET_MEMBER_LIST_BY_PATIENT_ID:"GET_MEMBER_LIST_BY_PATIENT_ID"
};
export const getFAQList = (product) => {
    return {
        type: ActionTypes.GET_FAQ_LIST,
        payload: product,
    }
}
export const getAllFAQList = (product) => {
    return {
        type: ActionTypes.GET_ALL_FAQ_LIST,
        payload: product,
    }
}
export const addFAQList = (product) => {
    return {
        type: ActionTypes.ADD_FAQ_LIST,
        payload: product,
    }
}
export const UpdateFAQList = (product_update) => {
    return {
        type: ActionTypes.UPDATE_FAQ_LIST,
        payload: product_update
    }
}
export const deleteFAQ = (product) => {
    return {
        type: ActionTypes.DELETE_FAQ,
        payload: product
    }
}
export const checkLogin = (status) => {
    return {
        type: ActionTypes.IS_LOGIN,
        payload: status,
    }
}

export const getUserId = (id) => {
    return {
        type: ActionTypes.GET_USER_ID,
        payload: id,
    }
}

export const getDeviceId = (id) => {
    return {
        type: ActionTypes.GET_DEVICE_ID,
        payload: id,
    }
}

export const DeleteUser = (user) => {
    return {
        type: ActionTypes.DELETE_USER,
        payload: user,
    }
}

export const ClearFormSetFutios = (date_) => {
    return {
        type: ActionTypes.CLEAR_FORM_SET_FUCT,
        payload: date_,
    }
}

export const getDashBoardDetails = (dashboard_details) => {
    return {
        type: ActionTypes.GET_DASHBOARD_DETAILS,
        payload: dashboard_details,
    }
}

export const ViewProjectDetails = (project) => {
    return {
        type: ActionTypes.VIEW_PRODUCT_DETAILS,
        payload: project,
    }
}

export const LoaderStartMain = (loader) => {
    return {
        type: ActionTypes.LOADER_MAIN_START,
        payload: loader,
    }
}

export const getSelfDetails = (user) => {
    return {
        type: ActionTypes.SELF_DETAILS,
        payload: user,
    }
}

export const getUserList = (list) => {
    return {
        type: ActionTypes.GET_USER_LIST,
        payload: list,
    }
}

export const getRelationList = (list) => {
    return {
        type: ActionTypes.GET_RELATION_LIST,
        payload: list,
    }
}
export const getUserListIdWise = (list) => {
    return {
        type: ActionTypes.GET_USER_LIST_ID_WISE,
        payload: list,
    }
}

export const getAllBrandList = (list) => {
    return {
        type: ActionTypes.GET_ALL_BRANDS_LIST,
        payload: list,
    }
}
export const getAllMemberListByUserIDList = (list) => {
    return {
        type: ActionTypes.GET_MEMBER_LIST_BY_USER_ID,
        payload: list,
    }
}
export const getAllSOSListByUserIDList = (list) => {
    return {
        type: ActionTypes.GET_SOS_LIST_BY_USER_ID,
        payload: list,
    }
}
export const getAllPatientListByUserIDList = (list) => {
    return {
        type: ActionTypes.GET_PATIENT_LIST_BY_USER_ID,
        payload: list,
    }
}
export const getAllAlertListByUserIDList = (list) => {
    return {
        type: ActionTypes.GET_ALERT_LIST_BY_USER_ID,
        payload: list,
    }
}

export const getalertList = (list) => {
    return {
        type: ActionTypes.GET_CATEGORY_LIST,
        payload: list,
    }
}

export const getAllalertList = (list) => {
    return {
        type: ActionTypes.GET_ALL_CATEGORY_LIST,
        payload: list,
    }
}

export const addalertList = (list) => {
    return {
        type: ActionTypes.ADD_CATEGORY_LIST,
        payload: list,
    }
}

export const updatealertList = (list) => {
    return {
        type: ActionTypes.UPDATE_CATEGORY_LIST,
        payload: list,
    }
}

export const deletealertList = (list) => {
    return {
        type: ActionTypes.DELETE_CATEGORY_LIST,
        payload: list,
    }
}

export const getAllModelList = (list) => {
    return {
        type: ActionTypes.GET_ALL_MODEL_LIST,
        payload: list,
    }
}

export const getBrandList = (list) => {
    return {
        type: ActionTypes.GET_BRAND_LIST,
        payload: list,
    }
}

export const getUsersMemberListByID = (list) => {
    return {
        type: ActionTypes.GET_USERS_MEMBER_LIST_BY_ID,
        payload: list,
    }
}
export const getUsersAlertListByID = (list) => {
    return {
        type: ActionTypes.GET_USERS_ALERT_LIST_BY_ID,
        payload: list,
    }
}

export const getMemberListByPatientID = (list) => {
    return {
        type: ActionTypes.GET_MEMBER_LIST_BY_PATIENT_ID,
        payload: list,
    }
}
export const getUsersSOSListByID = (list) => {
    return {
        type: ActionTypes.GET_USERS_SOS_LIST_BY_ID,
        payload: list,
    }
}
export const getUsersPatientListByID = (list) => {
    return {
        type: ActionTypes.GET_USERS_PATIENT_LIST_BY_ID,
        payload: list,
    }
}
export const addNewBrand = (brandlist) => {
    return {
        type: ActionTypes.ADD_NEW_BRAND,
        payload: brandlist,
    }
}

export const editBrand = (brandlist) => {
    return {
        type: ActionTypes.EDIT_BRAND,
        payload: brandlist,
    }
}

export const editModel = (modellist) => {
    return {
        type: ActionTypes.EDIT_MODEL,
        payload: modellist,
    }
}

export const getBrandsWiseModelList = (list) => {
    return {
        type: ActionTypes.SELECT_BRAND_GET_MODEL_LIST,
        payload: list
    }
}

export const deleteBrand = (brands) => {
    return {
        type: ActionTypes.DELETE_BRAND,
        payload: brands
    }
}

export const deleteModel = (model) => {
    return {
        type: ActionTypes.DELETE_MODEL,
        payload: model
    }
}

export const getModelList = (list) => {
    return {
        type: ActionTypes.GET_MODEL_LIST,
        payload: list,
    }
}

export const addNewModel = (brandlist) => {
    return {
        type: ActionTypes.ADD_NEW_MODEL,
        payload: brandlist,
    }
}

export const GetBrandModels = (brandsmodel) => {
    return {
        type: ActionTypes.GET_BRAND_MODELS_LIST,
        payload: brandsmodel,
    }
}

export const addUserList = (list) => {
    return {
        type: ActionTypes.ADD_USER_LIST,
        payload: list,
    }
}

export const editUserList = (list) => {
    return {
        type: ActionTypes.EDIT_USER_LIST,
        payload: list,
    }
}

export const getPermissionList = (permission) => {
    return {
        type: ActionTypes.GET_PERMISSION_LIST,
        payload: permission,
    }
}

export const getAccessToken = (token) => {
    return {
        type: ActionTypes.GET_ACCESSTOKEN,
        payload: token,
    }
}

export const getSupplierList = (list) => {
    return {
        type: ActionTypes.GET_SUPPLIER_LIST,
        payload: list,
    }
}

export const getSupplierAllList = (list) => {
    return {
        type: ActionTypes.GET_ALL_SUPPLIERS_LIST,
        payload: list,
    }
}

export const addSupplierList = (supplier) => {
    return {
        type: ActionTypes.ADD_SUPPLIER_LIST,
        payload: supplier,
    }
}

export const UpdateSupplierList = (supplier) => {
    return {
        type: ActionTypes.UPDATE_SUPPLIER_LIST,
        payload: supplier
    }
}

export const getSupplierDetails = (details) => {
    return {
        type: ActionTypes.GET_SUPPLIER_DETAILS,
        payload: details,
    }
}

export const editSelfDetails = (user) => {
    return {
        type: ActionTypes.EDIT_SELF_DETAILS,
        payload: user,
    }
}

export const getProductList = (product) => {
    return {
        type: ActionTypes.GET_PRODUCT_LIST,
        payload: product,
    }
}
export const getUserMemberList = (product) => {
    return {
        type: ActionTypes.GET_USER_MEMBER_LIST,
        payload: product,
    }
}
export const getAllProductList = (product) => {
    return {
        type: ActionTypes.GET_ALL_PRODUCT_LIST,
        payload: product,
    }
}
export const getAllUserMemberList = (product) => {
    return {
        type: ActionTypes.GET_ALL_USER_MEMBER_LIST,
        payload: product,
    }
}

export const addProductList = (product) => {
    return {
        type: ActionTypes.ADD_PRODUCT_LIST,
        payload: product,
    }
}

export const addUserMemberList = (product) => {
    return {
        type: ActionTypes.ADD_USER_MEMBER_LIST,
        payload: product,
    }
}

export const UpdateProductList = (product_update) => {
    return {
        type: ActionTypes.UPDATE_PRODUCT_LIST,
        payload: product_update
    }
}
export const UpdateUserMemberList = (product_update) => {
    return {
        type: ActionTypes.UPDATE_USER_MEMBER_LIST,
        payload: product_update
    }
}


export const deleteSupplier = (supplier) => {
    return {
        type: ActionTypes.DELETE_SUPPLIER,
        payload: supplier
    }
}

export const deleteProduct = (product) => {
    return {
        type: ActionTypes.DELETE_PRODUCT,
        payload: product
    }
}
export const deleteUserMember = (product) => {
    return {
        type: ActionTypes.DELETE_USER_MEMBER,
        payload: product
    }
}

export const getHomeTabSOSList = (setting) => {
    return {
        type: ActionTypes.GET_SETTINGS_LIST,
        payload: setting,
    }
}

export const addHomeTabSOSList = (setting) => {
    return {
        type: ActionTypes.ADD_SETTINGS_LIST,
        payload: setting,
    }
}

export const updateHomeTabSOSList = (setting) => {
    return {
        type: ActionTypes.UPDATE_SETTINGS_LIST,
        payload: setting,
    }
}

export const deleteHomeTabSOSList = (setting) => {
    return {
        type: ActionTypes.DELETE_SETTINGS_LIST,
        payload: setting,
    }
}


export const clearRedux = () => {
    return {
       type: ActionTypes.ADMIN_LOGOUT,
    }
}