/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import BackButton from "../Common/BackButton";
import {
  MEMBER_MANAGEMENT_URL,
  USER_MANAGEMENT_URL,
  VIEW_MEMBER_MANAGEMENT_URL,
  VIEW_USER_MANAGEMENT_URL,
} from "../Shared/constant";
import AvatarGet from "../Common/AvatarGet";
import { Scrollbars } from "react-custom-scrollbars-2";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import CommonLoader from "../Common/CommonLoader";
import CommonPagination from "../Common/CommonPagination";
import InputField from "../Common/InputField";
import { Tab, Tabs } from "react-bootstrap";
import moment from "moment";

const ViewUser = () => {
  const Navigate = useNavigate();
  const params = useParams();
  const { websocket } = useContext(WebSocketContext);
  const {
    productList,
    viewProduct,
    loaderMain,
    device_id,
    usersMemberListByID,
    alertList,
    usersSOSListByID,
    usersPatientListByID,
    usersAlertListByID,
    userMemberListByIDall,
    userMemberList,
    userAlertListByIDall,
    userSOSListByIDall,
    userPatientListByIDall,
  } = useSelector((state) => state.adminReducers);
  const [currentPage, setCurrentPage] = useState(1);
  const [userMemberLimit, setuserMemberLimit] = useState("");
  const [userAlertLimit, setuserAlertLimit] = useState("");
  const [userPatientLimit, setuserPatientLimit] = useState("");
  const [userSOSLimit, setuserSOSLimit] = useState("");
  useEffect(() => {
    setCurrentPage(1);
  }, [userMemberLimit, userAlertLimit, userPatientLimit, userSOSLimit]);
  const [key, setKey] = useState("user-detail");
  // page change
  const currentFunction = (page) => {
    setCurrentPage(page);
  };
  // console.log(
  //   "userPatientListByIDall",
  //   userPatientListByIDall,
  //   "usersPatientListByID",
  //   usersPatientListByID
  // );
  // console.log(
  //   "userMemberListByIDall",
  //   userMemberListByIDall,
  //   "usersMemberListByID",
  //   usersMemberListByID
  // );
  // console.log(
  //   "usersAlertListByID",
  //   usersAlertListByID,
  //   "userAlertListByIDall",
  //   userAlertListByIDall
  // );

  useEffect(() => {
    let paramRequest = {
      filter: "all",
      limit: 5000,
      page_no: 1,
      DeviceId: device_id,
      type: "admin",
    };
    const paramfilter = Object.entries(paramRequest).filter((elm) => {
      if (elm[1] !== "") {
        return elm;
      }
    });
    let param = {
      transmit: "single",
      url: "member_list",
      request: Object.fromEntries(paramfilter),
    };
    wsSend_request(websocket, param);
  }, []);
  useEffect(() => {
    let paramRequest = {
      filter: "all",
      limit: 5000,
      page_no: 1,
      DeviceId: device_id,
      type: "admin",
    };
    const paramfilter = Object.entries(paramRequest).filter((elm) => {
      if (elm[1] !== "") {
        return elm;
      }
    });
    let param = {
      transmit: "broadcast",
      url: "patient_list",
      request: Object.fromEntries(paramfilter),
    };
    wsSend_request(websocket, param);
  }, []);
  useEffect(() => {
    if (params?.roomId) {
      let param = {
        transmit: "single",
        url: "user_detail",
        request: {
          user_id: params?.roomId,
          DeviceId: device_id,
        },
      };
      wsSend_request(websocket, param);
    }
  }, [params?.roomId]);
  useEffect(() => {
    if (params?.roomId) {
      let param = {
        transmit: "broadcast",
        url: "user_member_list",
        request: {
          user_id: params?.roomId,
          DeviceId: device_id,
          limit: userMemberLimit || 10,
          page_no: currentPage || 1,
        },
      };
      wsSend_request(websocket, param);
    }
  }, [params?.roomId, userMemberLimit, currentPage, userMemberLimit]);
  useEffect(() => {
    if (params?.roomId) {
      let param = {
        transmit: "single",
        url: "user_patient_list",
        request: {
          user_id: params?.roomId,
          DeviceId: device_id,
          limit: userPatientLimit || 10,
          page_no: currentPage || 1,
        },
      };
      wsSend_request(websocket, param);
    }
  }, [params?.roomId, userPatientLimit, currentPage, userMemberLimit]);
  useEffect(() => {
    if (params?.roomId) {
      let param = {
        transmit: "single",
        url: "user_alert_list",
        request: {
          user_id: params?.roomId,
          DeviceId: device_id,
          limit: userAlertLimit || 10,
          page_no: currentPage || 1,
        },
      };
      wsSend_request(websocket, param);
    }
  }, [params?.roomId, userAlertLimit, currentPage, userAlertLimit]);
  useEffect(() => {
    if (params?.roomId) {
      let param = {
        transmit: "single",
        url: "user_sos_list",
        request: {
          user_id: params?.roomId,
          DeviceId: device_id,
          limit: userSOSLimit || 10,
          page_no: currentPage || 1,
        },
      };
      wsSend_request(websocket, param);
    }
  }, [params?.roomId, userSOSLimit, currentPage, userSOSLimit]);

  const findUserMember = (searchUserMemberID) => {
    const foundUser = userMemberList?.list?.find(
      (user) => user.id === searchUserMemberID
    );
    if (foundUser) {
      return foundUser?.username;
    } else {
      return "User Not Found";
    }
  };

  const findPatient = (searchUserMemberID) => {
    const foundUser = productList?.list?.find(
      (user) => user.id === searchUserMemberID
    );
    if (foundUser) {
      return foundUser?.username;
    } else {
      return "Patient Not Found";
    }
  };

  return (
    <div className="tab-view-user" id="view-user-tab">
      <Tabs
        id="controlled-tab-user"
        activeKey={key}
        onSelect={(k) => {
          setKey(k);
          setCurrentPage(1);
        }}
        className="mb-3"
      >
        <Tab eventKey="user-detail" title="User Detail">
          <section className="dashboard_wrapper">
            <BackButton targeturl={USER_MANAGEMENT_URL} title={"Back"} />
            <div className="view_details">
              <Scrollbars
                style={{ height: "calc(100vh - 153px)" }}
                className="ScrollbarsWrapper"
                renderView={(props) => <div {...props} className="view" />}
              >
                <div className="row" style={{ margin: "0 auto" }}>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>User Profile</label>
                      <div className="product_avatar">
                        <AvatarGet
                          type="product"
                          url={
                            viewProduct?.view_file_url
                              ? viewProduct?.view_file_url
                              : null
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <InputField
                      type="text"
                      value={viewProduct?.username}
                      className={"form-control"}
                      label="User Name"
                      readOnly
                      errormessage={""}
                    />
                  </div>
                  <div className="col-md-3">
                    <InputField
                      type="text"
                      value={
                        viewProduct?.is_registered
                          ? "Registered User"
                          : "Member"
                      }
                      className={"form-control"}
                      label="User User Type"
                      readOnly
                      errormessage={""}
                    />
                  </div>
                  <div className="col-md-3">
                    <InputField
                      type="text"
                      value={
                        viewProduct?.phone === null || viewProduct?.phone === ""
                          ? "-"
                          : "+" +
                            viewProduct?.phone_code +
                            " " +
                            viewProduct?.phone
                      }
                      className={"form-control"}
                      label="Mobile Number"
                      readOnly
                      errormessage={""}
                    />
                  </div>
                  <div className="col-md-3">
                    <InputField
                      type="text"
                      value={viewProduct?.email}
                      className={"form-control"}
                      label="Email Address"
                      readOnly
                      errormessage={""}
                    />
                  </div>
                  <div className="col-md-3">
                    <InputField
                      type="text"
                      value={moment(viewProduct.created_at).format("LL")}
                      className={"form-control"}
                      label="User Created At"
                      readOnly
                      errormessage={""}
                    />
                  </div>
                  <div className="col-md-3">
                    <InputField
                      type="text"
                      value={viewProduct?.relation}
                      className={"form-control"}
                      label="Relation"
                      readOnly
                      errormessage={""}
                    />
                  </div>
                  <div
                    className="tableContent"
                    style={{
                      height:
                        window.screen.width > 540
                          ? "calc(100vh - 255px)"
                          : "calc(100vh - 143px)",
                    }}
                  ></div>
                </div>
              </Scrollbars>
            </div>
            {/* loader start here */}
            {loaderMain && <CommonLoader />}
          </section>
        </Tab>
        <Tab eventKey="member-detail" title="Member List">
          <section className="dashboard_wrapper">
            <BackButton targeturl={USER_MANAGEMENT_URL} title={"Back"} />
            <div className="d-flex justify-content-between w-100">
              <h4>{viewProduct?.username} Member List</h4>

              <div className="col-md-2">
                <div className="form-group">
                  <label>Limit</label>
                  <select
                    className="form-control userMemberLimitselect"
                    style={{ width: "100%" }}
                    value={userMemberLimit}
                    onChange={(e) =>
                      setuserMemberLimit(parseInt(e.target.value))
                    }
                  >
                    <option value="10">Items Per Page</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                    <option value="60">60</option>
                    <option value="70">70</option>
                    <option value="80">80</option>
                    <option value="90">90</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="view_details">
              <Scrollbars
                style={{ height: "calc(100vh - 153px)" }}
                className="ScrollbarsWrapper"
                renderView={(props) => <div {...props} className="view" />}
              >
                <div className="row" style={{ margin: "0 auto" }}>
                  <div
                    className="tableContent"
                    style={{
                      height:
                        window.screen.width > 540
                          ? "calc(100vh - 255px)"
                          : "calc(100vh - 143px)",
                    }}
                  >
                    <Scrollbars
                      style={{
                        height:
                          usersMemberListByID?.pagination?.total_records > 10
                            ? window.screen.width < 767
                              ? "calc(100% - 10px)"
                              : "calc(100% - 38px)"
                            : "calc(100% - 0px)",
                      }}
                      className="ScrollbarsWrapper"
                      renderView={(props) => (
                        <div {...props} className="view" />
                      )}
                    >
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>User Name</th>
                              <th>Picture</th>

                              <th>Phone</th>

                              <th>Email Address</th>
                              <th>Relation</th>
                              <th>View User Detail's</th>
                            </tr>
                          </thead>
                          <tbody>
                            {userMemberListByIDall &&
                              userMemberListByIDall?.map((user, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td>{user?.username}</td>
                                      <td>
                                        <AvatarGet
                                          type="product"
                                          url={user?.view_thumbnail_url}
                                        />
                                      </td>
                                      <td>
                                        {user?.phone === null ||
                                        user?.phone === ""
                                          ? "-"
                                          : "+" +
                                            user?.phone_code +
                                            " " +
                                            user?.phone}
                                      </td>
                                      <td>{user?.email}</td>

                                      <td>{user?.relation}</td>

                                      <td className="respoactionUsers">
                                        <button
                                          type="button"
                                          className="btn actiontblebtn"
                                          onClick={() => {
                                            setKey("user-detail");
                                            Navigate(
                                              `${VIEW_USER_MANAGEMENT_URL}/${user?.id}`
                                            );
                                          }}
                                        >
                                          <i className="bi bi-eye"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                );
                              })}
                            {usersMemberListByID &&
                              usersMemberListByID?.list?.length === 0 && (
                                <tr>
                                  <td
                                    style={{ textAlign: "center" }}
                                    colSpan={10}
                                  >
                                    Data Not Found !
                                  </td>
                                </tr>
                              )}
                          </tbody>
                        </table>
                      </div>
                    </Scrollbars>
                  </div>
                </div>
              </Scrollbars>
            </div>
            {/* loader start here */}
            {parseInt(usersMemberListByID?.pagination?.total_records) > 10 && (
              <CommonPagination
                currentPage={currentPage}
                paginationLength={usersMemberListByID?.pagination}
                currentFunction={currentFunction}
              />
            )}
            {loaderMain && <CommonLoader />}
          </section>
        </Tab>
        <Tab eventKey="patient-detail" title="Patient List">
          <section className="dashboard_wrapper">
            <BackButton targeturl={USER_MANAGEMENT_URL} title={"Back"} />
            <div className="d-flex justify-content-between w-100">
              <h4>{viewProduct?.username} Patient List</h4>

              <div className="col-md-2">
                <div className="form-group">
                  <label>Limit</label>
                  <select
                    className="form-control userPatientLimitselect"
                    style={{ width: "100%" }}
                    value={userPatientLimit}
                    onChange={(e) =>
                      setuserPatientLimit(parseInt(e.target.value))
                    }
                  >
                    <option value="10">Items Per Page</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                    <option value="60">60</option>
                    <option value="70">70</option>
                    <option value="80">80</option>
                    <option value="90">90</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="view_details">
              <Scrollbars
                style={{ height: "calc(100vh - 153px)" }}
                className="ScrollbarsWrapper"
                renderView={(props) => <div {...props} className="view" />}
              >
                <div className="row" style={{ margin: "0 auto" }}>
                  <div
                    className="tableContent"
                    style={{
                      height:
                        window.screen.width > 540
                          ? "calc(100vh - 255px)"
                          : "calc(100vh - 143px)",
                    }}
                  >
                    <Scrollbars
                      style={{
                        height:
                          usersPatientListByID?.pagination?.total_records > 10
                            ? window.screen.width < 767
                              ? "calc(100% - 10px)"
                              : "calc(100% - 38px)"
                            : "calc(100% - 0px)",
                      }}
                      className="ScrollbarsWrapper"
                      renderView={(props) => (
                        <div {...props} className="view" />
                      )}
                    >
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Patient Name</th>
                              <th>Picture</th>
                              <th>Phone</th>
                              <th>Email Address</th>
                              <th>Alert Started At</th>
                              <th>Alert Ended At</th>
                              <th>Alert Interval</th>
                            </tr>
                          </thead>
                          <tbody>
                            {userPatientListByIDall &&
                              userPatientListByIDall?.map((user, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td>{user?.username}</td>

                                      <td>
                                        <AvatarGet
                                          type="product"
                                          url={user?.view_thumbnail_url}
                                        />
                                      </td>
                                      <td>
                                        {user?.phone === null ||
                                        user?.phone === ""
                                          ? "-"
                                          : "+" +
                                            user?.phone_code +
                                            " " +
                                            user?.phone}
                                      </td>
                                      <td>{user?.email}</td>
                                      <td>
                                        {user?.alert_start_time ? moment(
                                          user?.alert_start_time,
                                          "HH:mm:ss"
                                        ).format("h:mm A"): "-"}
                                      </td>
                                      <td>
                                        {user?.alert_end_time ? moment(
                                          user?.alert_end_time,
                                          "HH:mm:ss"
                                        ).format("h:mm A"): "-"}
                                      </td>
                                      <td>{`${user?.interval}Hr` || "-"}</td>
                                    </tr>
                                  </React.Fragment>
                                );
                              })}
                            {usersPatientListByID &&
                              usersPatientListByID?.list?.length === 0 && (
                                <tr>
                                  <td
                                    style={{ textAlign: "center" }}
                                    colSpan={10}
                                  >
                                    Data Not Found !
                                  </td>
                                </tr>
                              )}
                          </tbody>
                        </table>
                      </div>
                    </Scrollbars>
                    {/* pagination */}
                    {usersPatientListByID?.pagination?.total_records > 10 && (
                      <CommonPagination
                        currentPage={currentPage}
                        paginationLength={usersPatientListByID?.pagination}
                        currentFunction={currentFunction}
                      />
                    )}
                  </div>
                </div>
              </Scrollbars>
            </div>
            {/* loader start here */}
            {loaderMain && <CommonLoader />}
          </section>
        </Tab>
        <Tab eventKey="alert-detail" title="Alert List">
          <section className="dashboard_wrapper">
            <BackButton targeturl={USER_MANAGEMENT_URL} title={"Back"} />
            <div className="d-flex justify-content-between w-100">
              <h4>{viewProduct?.username} Alert List</h4>

              <div className="col-md-2">
                <div className="form-group">
                  <label>Limit</label>
                  <select
                    className="form-control userAlertLimitselect"
                    style={{ width: "100%" }}
                    value={userAlertLimit}
                    onChange={(e) =>
                      setuserAlertLimit(parseInt(e.target.value))
                    }
                  >
                    <option value="10">Items Per Page</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                    <option value="60">60</option>
                    <option value="70">70</option>
                    <option value="80">80</option>
                    <option value="90">90</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="view_details">
              <Scrollbars
                style={{ height: "calc(100vh - 153px)" }}
                className="ScrollbarsWrapper"
                renderView={(props) => <div {...props} className="view" />}
              >
                <div className="row" style={{ margin: "0 auto" }}>
                  <div
                    className="tableContent"
                    style={{
                      height:
                        window.screen.width > 540
                          ? "calc(100vh - 255px)"
                          : "calc(100vh - 143px)",
                    }}
                  >
                    <Scrollbars
                      style={{
                        height:
                          usersAlertListByID?.pagination?.total_records > 10
                            ? window.screen.width < 767
                              ? "calc(100% - 10px)"
                              : "calc(100% - 38px)"
                            : "calc(100% - 0px)",
                      }}
                      className="ScrollbarsWrapper"
                      renderView={(props) => (
                        <div {...props} className="view" />
                      )}
                    >
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Alert From</th>
                              <th>Alert To</th>
                              <th>Alerted At</th>
                              <th>Alert Response</th>
                              <th>Alert Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {usersAlertListByID?.list &&
                              usersAlertListByID?.list?.map((user, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td>
                                        {findUserMember(user?.alert_from)}
                                      </td>
                                      <td>{findPatient(user?.alert_to)}</td>
                                      <td>
                                        {user?.created_at
                                          ? moment(user.created_at).format(
                                              "LL LT"
                                            )
                                          : "-"}
                                      </td>

                                      <td>
                                        {user?.response_at
                                          ? moment(user.response_at).format(
                                              "LL LT"
                                            )
                                          : "-"}
                                      </td>
                                      <td>
                                        {user?.status ? (
                                          <span className="">
                                            {user?.status}
                                          </span>
                                        ) : (
                                          <span className="fw-500">-</span>
                                        )}{" "}
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                );
                              })}
                            {usersAlertListByID &&
                              usersAlertListByID?.list?.length === 0 && (
                                <tr>
                                  <td
                                    style={{ textAlign: "center" }}
                                    colSpan={10}
                                  >
                                    Data Not Found !
                                  </td>
                                </tr>
                              )}
                          </tbody>
                        </table>
                      </div>
                    </Scrollbars>
                    {/* pagination */}
                    {usersAlertListByID?.pagination?.total_records > 10 && (
                      <CommonPagination
                        currentPage={currentPage}
                        paginationLength={usersAlertListByID?.pagination}
                        currentFunction={currentFunction}
                      />
                    )}
                  </div>
                </div>
              </Scrollbars>
            </div>
            {/* loader start here */}
            {loaderMain && <CommonLoader />}
          </section>
        </Tab>
        <Tab eventKey="sos-detail" title="SOS List">
          <section className="dashboard_wrapper">
            <BackButton targeturl={USER_MANAGEMENT_URL} title={"Back"} />
            <div className="d-flex justify-content-between w-100">
              <h4>{viewProduct?.username} SOS List</h4>

              <div className="col-md-2">
                <div className="form-group">
                  <label>Limit</label>
                  <select
                    className="form-control userSOSLimitselect"
                    style={{ width: "100%" }}
                    value={userSOSLimit}
                    onChange={(e) => setuserSOSLimit(parseInt(e.target.value))}
                  >
                    <option value="10">Items Per Page</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                    <option value="60">60</option>
                    <option value="70">70</option>
                    <option value="80">80</option>
                    <option value="90">90</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="view_details">
              <Scrollbars
                style={{ height: "calc(100vh - 153px)" }}
                className="ScrollbarsWrapper"
                renderView={(props) => <div {...props} className="view" />}
              >
                <div className="row" style={{ margin: "0 auto" }}>
                  <div
                    className="tableContent"
                    style={{
                      height:
                        window.screen.width > 540
                          ? "calc(100vh - 255px)"
                          : "calc(100vh - 143px)",
                    }}
                  >
                    <Scrollbars
                      style={{
                        height:
                          usersSOSListByID?.pagination?.total_records > 10
                            ? window.screen.width < 767
                              ? "calc(100% - 10px)"
                              : "calc(100% - 38px)"
                            : "calc(100% - 0px)",
                      }}
                      className="ScrollbarsWrapper"
                      renderView={(props) => (
                        <div {...props} className="view" />
                      )}
                    >
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Patient Name</th>
                              <th>Member Name</th>
                              <th>Response From</th>
                              <th>Responded</th>
                              <th>Responded At</th>
                              <th>SOS Added At</th>
                            </tr>
                          </thead>
                          <tbody>
                            {usersSOSListByID &&
                              usersSOSListByID?.list?.map((user, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <tr key={index}>
                                      {/* <td>{user?.member_id}</td> */}
                                      <td>{findPatient(user?.sos_by)}</td>
                                      <td>{findUserMember(user?.member_id)}</td>
                                      <td>
                                        {findUserMember(user?.sos_res_from)}
                                      </td>
                                      <td>
                                        {user?.is_responded
                                          ? "Yes"
                                          : "No Response"}
                                      </td>
                                      <td>
                                        {user?.response_at ? moment(user?.response_at).format(
                                          "LL h:mm A"
                                        ): "-"}
                                      </td>
                                      <td>
                                        {moment(user?.created_at).format(
                                          "LL h:mm A"
                                        )}
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                );
                              })}
                            {usersSOSListByID &&
                              usersSOSListByID?.list?.length === 0 && (
                                <tr>
                                  <td
                                    style={{ textAlign: "center" }}
                                    colSpan={10}
                                  >
                                    Data Not Found !
                                  </td>
                                </tr>
                              )}
                          </tbody>
                        </table>
                      </div>
                    </Scrollbars>
                    {/* pagination */}
                    {usersSOSListByID?.pagination?.total_records > 10 && (
                      <CommonPagination
                        currentPage={currentPage}
                        paginationLength={usersSOSListByID?.pagination}
                        currentFunction={currentFunction}
                      />
                    )}
                  </div>
                </div>
              </Scrollbars>
            </div>
            {/* loader start here */}
            {loaderMain && <CommonLoader />}
          </section>
        </Tab>
      </Tabs>
    </div>
  );
};

export default ViewUser;
