
const ForgotPasswordAPI = async (payload,url) => {
    const responce = window.axios.post(url, payload, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }}).then(function (result) {
        return {
          ...result.data,
          status:200
        };
    }).catch(function (result) {
        return {
          ...result?.response?.data,
          status:result?.response?.status
        }
    });
    return responce;
};
  
export default ForgotPasswordAPI;
  