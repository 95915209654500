/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import BackButton from "../Common/BackButton";
import {
  MEMBER_MANAGEMENT_URL,
  VIEW_USER_MANAGEMENT_URL,
} from "../Shared/constant";
import AvatarGet from "../Common/AvatarGet";
import { Scrollbars } from "react-custom-scrollbars-2";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CommonLoader from "../Common/CommonLoader";
import CommonPagination from "../Common/CommonPagination";
import InputField from "../Common/InputField";
import { Tab, Tabs } from "react-bootstrap";
import moment from "moment";

const ViewPatient = () => {
  const params = useParams();
  const { websocket } = useContext(WebSocketContext);
  const {
    usersMemberListByPatientID,
    viewProduct,
    loaderMain,
    device_id,
    userMemberList,
  } = useSelector((state) => state.adminReducers);
  const [currentPage, setCurrentPage] = useState(1);
  const [key, setKey] = useState("user-detail");

  const [userMemberLimit, setuserMemberLimit] = useState("");
  useEffect(() => {
    setCurrentPage(1);
  }, [userMemberLimit]);
  const Navigate = useNavigate();
  useEffect(() => {
    let paramRequest = {
      filter: "all",
      limit: 5000,
      page_no: 1,
      DeviceId: device_id,
      type: "admin",
    };
    const paramfilter = Object.entries(paramRequest).filter((elm) => {
      if (elm[1] !== "") {
        return elm;
      }
    });
    let param = {
      transmit: "single",
      url: "member_list",
      request: Object.fromEntries(paramfilter),
    };
    wsSend_request(websocket, param);
  }, []);

  const getMemberNameByID = (id) => {
    // 9d6a6ea5-ed8c-48f2-a960-ecd2accd1dc1
    const foundItem = userMemberList?.list?.find((item) => item?.id == id);

    if (foundItem) {
      // The item is present in the array
      return foundItem?.username;
    } else {
      // The item is not present in the array
      return "User Not Found";
    }
  };

  // page change
  const currentFunction = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    if (params?.roomId) {
      let param = {
        transmit: "single",
        url: "user_detail",
        request: {
          user_id: params?.roomId,
          DeviceId: device_id,
          type: "patient",
        },
      };
      wsSend_request(websocket, param);
    }
  }, [params?.roomId]);
  useEffect(() => {
    if (params?.roomId) {
      let param = {
        transmit: "broadcast",
        url: "member_by_patient",
        request: {
          patient_id: params?.roomId,
          DeviceId: device_id,
          limit: userMemberLimit || 10,
          page_no: currentPage || 1,
          type: "patient",
        },
      };
      wsSend_request(websocket, param);
    }
  }, [params?.roomId, userMemberLimit, currentPage]);

  return (
    <div className="tab-view-user" id="view-patient-tab">
      <Tabs
        id="controlled-tab-user"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="user-detail" title="Patient Detail">
          <section className="dashboard_wrapper">
            <BackButton targeturl={MEMBER_MANAGEMENT_URL} title={"Back"} />
            <div className="view_details">
              <Scrollbars
                style={{ height: "calc(100vh - 153px)" }}
                className="ScrollbarsWrapper"
                renderView={(props) => <div {...props} className="view" />}
              >
                <div className="row" style={{ margin: "0 auto" }}>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>User Profile</label>
                      <div className="product_avatar">
                        <AvatarGet
                          type="product"
                          url={
                            viewProduct?.view_file_url
                              ? viewProduct?.view_file_url
                              : null
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <InputField
                      type="text"
                      value={viewProduct?.username}
                      className={"form-control"}
                      label="User Name"
                      readOnly
                      errormessage={""}
                    />
                  </div>

                  <div className="col-md-3">
                    <InputField
                      type="text"
                      value={
                        viewProduct?.phone === null || viewProduct?.phone === ""
                          ? "-"
                          : "+" +
                            viewProduct?.phone_code +
                            " " +
                            viewProduct?.phone
                      }
                      className={"form-control"}
                      label="Mobile Number"
                      readOnly
                      errormessage={""}
                    />
                  </div>
                  <div className="col-md-3">
                    <InputField
                      type="text"
                      value={viewProduct?.email}
                      className={"form-control"}
                      label="Email Address"
                      readOnly
                      errormessage={""}
                    />
                  </div>
                  <div className="col-md-3">
                    <InputField
                      type="text"
                      value={getMemberNameByID(viewProduct?.member_id)}
                      className={"form-control"}
                      label="Patient's User"
                      readOnly
                      errormessage={""}
                    />
                  </div>
                  <div className="col-md-3">
                    <InputField
                      type="text"
                      value={moment(viewProduct.created_at).format("LL")}
                      className={"form-control"}
                      label="User Created At"
                      readOnly
                      errormessage={""}
                    />
                  </div>
                  <div className="col-md-3">
                    <InputField
                      type="text"
                      value={
                        viewProduct?.alert_start_time
                          ? moment(
                              `1970-01-01 ${viewProduct.alert_start_time}`
                            ).format("h:mm:ss A")
                          : "-"
                      }
                      className={"form-control"}
                      label="Alert Started At"
                      readOnly
                      errormessage={""}
                    />
                  </div>
                  <div className="col-md-3">
                    <InputField
                      type="text"
                      value={
                        viewProduct?.alert_end_time
                          ? moment(
                              `1970-01-01 ${viewProduct.alert_end_time}`
                            ).format("h:mm:ss A")
                          : "-"
                      }
                      className={"form-control"}
                      label="Alert Responded At"
                      readOnly
                      errormessage={""}
                    />
                  </div>
                  <div className="col-md-3">
                    <InputField
                      type="text"
                      value={viewProduct?.interval || "-"}
                      className={"form-control"}
                      label="Alert Interval"
                      readOnly
                      errormessage={""}
                    />
                  </div>
                  <div
                    className="tableContent"
                    style={{
                      height:
                        window.screen.width > 540
                          ? "calc(100vh - 255px)"
                          : "calc(100vh - 143px)",
                    }}
                  ></div>
                </div>
              </Scrollbars>
            </div>
            {/* loader start here */}
            {loaderMain && <CommonLoader />}
          </section>
        </Tab>
        <Tab eventKey="patient-detail" title="Member List">
          <section className="dashboard_wrapper">
            <BackButton targeturl={MEMBER_MANAGEMENT_URL} title={"Back"} />
            <div className="d-flex justify-content-between w-100">
              <h4>{viewProduct?.username} Member List</h4>

              <div className="col-md-2">
                <div className="form-group">
                  <label>Limit</label>
                  <select
                    className="form-control userMemberLimitselect"
                    style={{ width: "100%" }}
                    value={userMemberLimit}
                    onChange={(e) =>
                      setuserMemberLimit(parseInt(e.target.value))
                    }
                  >
                    <option value="10">Items Per Page</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                    <option value="60">60</option>
                    <option value="70">70</option>
                    <option value="80">80</option>
                    <option value="90">90</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="view_details">
              <Scrollbars
                style={{ height: "calc(100vh - 153px)" }}
                className="ScrollbarsWrapper"
                renderView={(props) => <div {...props} className="view" />}
              >
                <div className="row" style={{ margin: "0 auto" }}>
                  <div
                    className="tableContent"
                    style={{
                      height:
                        window.screen.width > 540
                          ? "calc(100vh - 255px)"
                          : "calc(100vh - 143px)",
                    }}
                  >
                    <Scrollbars
                      style={{
                        height:
                          usersMemberListByPatientID?.pagination
                            ?.total_records > 10
                            ? window.screen.width < 767
                              ? "calc(100% - 10px)"
                              : "calc(100% - 38px)"
                            : "calc(100% - 0px)",
                      }}
                      className="ScrollbarsWrapper"
                      renderView={(props) => (
                        <div {...props} className="view" />
                      )}
                    >
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>User Name</th>
                              <th>Picture</th>

                              <th>Phone</th>

                              <th>Email Address</th>
                              <th>User Type</th>
                              <th>Relation</th>
                              <th>View User Detail's</th>
                            </tr>
                          </thead>
                          <tbody>
                            {usersMemberListByPatientID &&
                              usersMemberListByPatientID?.list?.map(
                                (user, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <tr>
                                        <td>{user?.username}</td>
                                        <td>
                                          <AvatarGet
                                            type="product"
                                            url={user?.view_thumbnail_url}
                                          />
                                        </td>
                                        <td>
                                          {user?.phone === null ||
                                          user?.phone === ""
                                            ? "-"
                                            : "+" +
                                              user?.phone_code +
                                              " " +
                                              user?.phone}
                                        </td>
                                        <td>{user?.email}</td>
                                        <td>
                                          {user?.is_registered
                                            ? "Registered User"
                                            : "Member"}
                                        </td>

                                        <td>{user?.relation}</td>

                                        <td className="respoactionUsers">
                                          <button
                                            type="button"
                                            className="btn actiontblebtn"
                                            onClick={() =>
                                              Navigate(
                                                `${VIEW_USER_MANAGEMENT_URL}/${user?.id}`
                                              )
                                            }
                                          >
                                            <i className="bi bi-eye"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  );
                                }
                              )}
                            {usersMemberListByPatientID &&
                              usersMemberListByPatientID?.list?.length ===
                                0 && (
                                <tr>
                                  <td
                                    style={{ textAlign: "center" }}
                                    colSpan={10}
                                  >
                                    Data Not Found !
                                  </td>
                                </tr>
                              )}
                          </tbody>
                        </table>
                      </div>
                    </Scrollbars>
                  </div>
                </div>
              </Scrollbars>
            </div>
            {/* loader start here */}
            {usersMemberListByPatientID?.pagination?.total_records > 10 && (
              <CommonPagination
                currentPage={currentPage}
                paginationLength={usersMemberListByPatientID?.pagination}
                currentFunction={currentFunction}
              />
            )}
            {loaderMain && <CommonLoader />}
          </section>
        </Tab>
      </Tabs>
    </div>
  );
};

export default ViewPatient;
