import React from "react";
import Spinner from 'react-bootstrap/Spinner';

const CommonLoader = () => {
    return(<div className="commn_loaderset">
        <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
        </Spinner>
        <h4>Please Wait....</h4>
    </div>)
};

export default CommonLoader;