import { CREATE_PASSWORD_API_URL } from "../components/Shared/constant";

const CreatePasswordAPI = async (payload, token) => {
    const responce = window.axios.post(`${CREATE_PASSWORD_API_URL}/${token}`, payload, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }}).then(function (result) {
        return {
          ...result.data,
          status:200
        };
    }).catch(function (result) {
        return {
          ...result?.response?.data,
          status:result?.response?.status
        }
    });
    return responce;
};
  
export default CreatePasswordAPI;
  