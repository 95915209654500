/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import DataTableFilter from "../Common/DataTableFilter";
import { Scrollbars } from "react-custom-scrollbars-2";
import CommonToggle from "../Common/CommonToggle";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import CommonPagination from "../Common/CommonPagination";
import CommonModel from "../Common/CommonModel";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ClearFormSetFutios } from "../../redux/actions/adminActions";

const AlertManagement = () => {
  const { websocket } = useContext(WebSocketContext);
  const { selfInfo, alertList, device_id, userMemberList, productList } =
    useSelector((state) => state.adminReducers);
  const Navigate = useNavigate();
  const Dispatch = useDispatch();
  const [userSearch, setUserSearch] = useState("");
  const [userType, setUserType] = useState(null);
  const [userLimit, setUserLimit] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [setMemberUserId, setSetMemberUserId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModalNew, setShowModalNew] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });
  useEffect(() => {
    setCurrentPage(1);
  }, [userLimit]);
  const getMemberNameByID = (id) => {
    const foundItem = productList?.list?.find((item) => item?.id == id);
    if (foundItem) {
      // The item is present in the array
      return foundItem?.username;
    } else {
      // The item is not present in the array
      return "Member Not Found";
    }
  };
  const getUserNameByID = (id) => {
    const foundItem = userMemberList?.list?.find((item) => item?.id === id);

    if (foundItem) {
      // The item is present in the array
      return foundItem?.username;
    } else {
      // The item is not present in the array
      return "User Not Found";
    }
  };
  // get product list
  useEffect(() => {
    let paramRequest = {
      filter: "all",
      limit: 5000,
      page_no: 1,
      DeviceId: device_id,
      type: "admin",
    };
    const paramfilter = Object.entries(paramRequest).filter((elm) => {
      if (elm[1] !== "") {
        return elm;
      }
    });
    let param = {
      transmit: "single",
      url: "member_list",
      request: Object.fromEntries(paramfilter),
    };
    wsSend_request(websocket, param);
  }, []);
  // get product list
  useEffect(() => {
    let paramRequest = {
      filter: "all",
      limit: 5000,
      page_no: 1,
      DeviceId: device_id,
      type: "admin",
    };
    const paramfilter = Object.entries(paramRequest).filter((elm) => {
      if (elm[1] !== "") {
        return elm;
      }
    });
    let param = {
      transmit: "broadcast",
      url: "patient_list",
      request: Object.fromEntries(paramfilter),
    };
    wsSend_request(websocket, param);
  }, []);

  // page change
  const currentFunction = (page) => {
    setCurrentPage(page);
  };

  // clear search box
  const ClearSearchFiled = () => {
    setUserSearch("");
  };

  // select user type
  const SelectUserTypeFnct = (e) => {
    setUserType(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    Dispatch(
      ClearFormSetFutios({
        url: "",
        action: false,
      })
    );
  }, []);

  const ResetFnct = () => {
    setUserSearch("");
    setUserType("All");
    setUserLimit(10);
    setCurrentPage(1);
    setSelectedUserId("Select User");
    setSetMemberUserId("Select Member");
  };
  useEffect(() => {
    let param = {
      transmit: "broadcast",
      url: "alert_list",
      request: {
        limit: userLimit || 10,
        page_no: currentPage || 1,
        search: userSearch.trim(),
        type: "admin",
        alert_to: setMemberUserId === "Select Member" ? null : setMemberUserId,
        alert_from: selectedUserId === "Select User" ? null : selectedUserId,
        status: userType,
      },
    };
    wsSend_request(websocket, param);
  }, [
    userSearch,
    userLimit,
    currentPage,
    setMemberUserId,
    selectedUserId,
    userType,
  ]);
console.log("alertList",alertList)
  return (
    <section className="dashboard_wrapper">
      <div className="filter_sectio">
        <div className="row d-flex justify-content-between">
          <div className="col-md-2">
            <div className="form-group">
              <label>Status</label>
              <select
                className="form-control activestatusselet"
                style={{ width: "100%" }}
                value={userType}
                onChange={(e) => SelectUserTypeFnct(e)}
              >
                <option value="all">All</option>
                <option value="ok">Ok</option>
                <option value="not ok">Not Ok</option>
              </select>
            </div>
          </div>
          <div className="d-flex col-md-6 gap-3 justify-content-end">
            <div className="col-md-4">
              <div className="form-group">
                <label>Alert From</label>
                <select
                  className="form-control userLimitselect"
                  style={{ width: "100%" }}
                  value={selectedUserId}
                  onChange={(e) => setSelectedUserId(e.target.value)}
                >
                  <option key={null} value={null}>
                   All
                  </option>

                  {userMemberList?.list &&
                    userMemberList?.list?.length > 0 &&
                    userMemberList?.list?.map((item, index) => (
                      <option key={index} value={item?.id}>
                        {item?.username}
                      </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label>Alert To</label>
                <select
                  className="form-control userLimitselect"
                  style={{ width: "100%" }}
                  value={setMemberUserId}
                  onChange={(e) => setSetMemberUserId(e.target.value)}
                >
                  <option key={null} value={null}>
                    All
                  </option>

                  {productList?.list &&
                    productList?.list?.length > 0 &&
                    productList?.list?.map((item, index) => (
                      <option key={index} value={item?.id}>
                        {item?.username}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="col-auto">
              <div className="form-group">
                <label>Limit</label>
                <select
                  className="form-control userLimitselect"
                  style={{ width: "100%" }}
                  value={userLimit}
                  onChange={(e) => setUserLimit(parseInt(e.target.value))}
                >
                  <option value="10">Item's Per Page</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                  <option value="60">60</option>
                  <option value="70">70</option>
                  <option value="80">80</option>
                  <option value="90">90</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
            <div
              className="col-md-2"
              style={{ width: "auto", paddingRight: "0px" }}
            >
              <div className="form-group">
                <label>&nbsp;</label>
                <button
                  className="btn head_reset_btn"
                  style={{ marginLeft: "0px" }}
                  onClick={() => ResetFnct()}
                >
                  <i className="bi bi-x-circle"></i>
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="tableContent"
        style={{
          height:
            window.screen.width > 540
              ? "calc(100vh - 155px)"
              : "calc(100vh - 143px)",
        }}
      >
        <Scrollbars
          style={{
            height:
              alertList?.pagination?.total_records > 10
                ? window.screen.width < 767
                  ? "calc(100% - 10px)"
                  : "calc(100% - 58px)"
                : "calc(100% - 0px)",
          }}
          className="ScrollbarsWrapper"
          renderView={(props) => <div {...props} className="view" />}
        >
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Patient Name</th>
                  <th>User Name</th>
                  <th>Alerted At</th>
                  <th>Responded At</th>
                  <th>Alert Status</th>
                </tr>
              </thead>
              <tbody>
                {alertList &&
                  alertList?.list?.map((user, index) => {
                    return (
                      <tr key={index}>
                        <td>{getMemberNameByID(user?.alert_to)}</td>
                        <td>{getUserNameByID(user?.alert_from)}</td>
                        <td>{moment(user?.created_at).format("LL LT")}</td>
                        <td>
                          {user?.response_at
                            ? moment(user.response_at).format("LL LT")
                            : "-"}
                        </td>

                        <td>
                          {user?.status ? (
                            <span className="">{user?.status}</span>
                          ) : (
                            <span className="fw-500">-</span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                {alertList && alertList?.list.length === 0 && (
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan={8}>
                      Alerts Not Found !
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Scrollbars>
        {/* pagination */}
        {alertList?.pagination?.total_records > 10 && (
          <CommonPagination
            currentPage={currentPage}
            paginationLength={alertList?.pagination}
            currentFunction={currentFunction}
          />
        )}
      </div>
      {/* {showModal?.open && (<CommonModel setUserSearch={setUserSearch} setCurrentPage={setCurrentPage}  showModal={showModal} setShowModal={setShowModal} />)} */}
    </section>
  );
};

export default AlertManagement;
