// useWebSocket.js
import { useEffect } from 'react';
import { ws_response } from './ws/ws_response';

const useWebSocket = (url, functions, Dispatch, accessToken) => {
  useEffect(() => {
    const socket = new WebSocket(url);
    console.log("url==========>", url, accessToken);
    functions(socket);

    // Set up event handlers
    const handleSocketMessage = (evt) => {
      Dispatch(ws_response({ evt: evt, ws: socket }));
    };

    const handleSocketClose = (event) => {
      console.log("websocket close");
      // if (accessToken !== "") {
      //     window.location.reload();
      // }
    };

    socket.addEventListener('message', handleSocketMessage);
    socket.addEventListener('close', handleSocketClose);

    return () => {
      socket.removeEventListener('message', handleSocketMessage);
      socket.removeEventListener('close', handleSocketClose);
      socket.close();
    };
  }, [url, functions, Dispatch, accessToken]);
};

export default useWebSocket;
