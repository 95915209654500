/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import DataTableFilter from "../Common/DataTableFilter";
import { Scrollbars } from "react-custom-scrollbars-2";
import moment from "moment";
import { useSelector } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import CommonPagination from "../Common/CommonPagination";
import CommonModel from "../Common/CommonModel";

const SOSManagement = () => {
  const { websocket } = useContext(WebSocketContext);
  const { selfInfo, sosList, userMemberList, productList, device_id } =
    useSelector((state) => state.adminReducers);
  const [userSearch, setUserSearch] = useState("");
  const [userLimit, setUserLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [setMemberUserId, setSetMemberUserId] = useState(null);
  useEffect(() => {
    setCurrentPage(1);
  }, [userLimit]);
  const [showModal, setShowModal] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });

  // page change
  const currentFunction = (page) => {
    setCurrentPage(page);
  };

  // clear search box
  const ClearSearchFiled = () => {
    setUserSearch("");
  };
  const getPatientNameByID = (id) => {
    const foundItem = productList?.list?.find((item) => item?.id == id);
    if (foundItem) {
      return foundItem?.username;
    } else {
      return "Member Not Found";
    }
  };
  const getUserNameByID = (id) => {
    const foundItem = userMemberList?.list?.find((item) => item?.id === id);

    if (foundItem) {
      return foundItem?.username;
    } else {
      return "User Not Found";
    }
  };
  const ResetFnct = () => {
    setUserSearch("");
    setUserLimit(10);
    setCurrentPage(1);
    setSetMemberUserId("Select Patient");
  };
  // get product list
  useEffect(() => {
    let paramRequest = {
      filter: "all",
      limit: 5000,
      page_no: 1,
      DeviceId: device_id,
      type: "admin",
    };
    const paramfilter = Object.entries(paramRequest).filter((elm) => {
      if (elm[1] !== "") {
        return elm;
      }
    });
    let param = {
      transmit: "single",
      url: "member_list",
      request: Object.fromEntries(paramfilter),
    };
    wsSend_request(websocket, param);
  }, []);
  // get product list
  useEffect(() => {
    let paramRequest = {
      filter: "all",
      limit: 5000,
      page_no: 1,
      DeviceId: device_id,
      type: "admin",
    };
    const paramfilter = Object.entries(paramRequest).filter((elm) => {
      if (elm[1] !== "") {
        return elm;
      }
    });
    let param = {
      transmit: "broadcast",
      url: "patient_list",
      request: Object.fromEntries(paramfilter),
    };
    wsSend_request(websocket, param);
  }, []);
  // get supplier list
  useEffect(() => {
    let param = {
      transmit: "broadcast",
      url: "sos_list",
      request: {
        limit: userLimit,
        page_no: currentPage,
        search: userSearch.trim(),
        type: "admin",
        sos_by: setMemberUserId === "Select Patient" ? null : setMemberUserId,
      },
    };
    wsSend_request(websocket, param);
  }, [userSearch, userLimit, currentPage, setMemberUserId]);

  // edit category
  const EditCategory = (data_) => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Edit Field",
      subtitle: "Edit Field",
      description: ``,
      modalType: "form",
      button: "Save",
      Data: data_,
    });
  };

  // delete category
  const DeleteCategoryFnct = (data_) => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Delete Field",
      subtitle: "Delete Field",
      description: `Do you really want to delete <h5>${data_?.name}</h5>`,
      modalType: "not form",
      button: "Yes",
      Data: data_,
    });
  };

  return (
    <section className="dashboard_wrapper">
      {/* <DataTableFilter
        filterType={"SOS (name)"}
        userSearch={userSearch}
        setUserSearch={setUserSearch}
        userLimit={userLimit}
        setUserLimit={setUserLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        ClearSearchFiled={ClearSearchFiled}
      /> */}
      <div className="row d-flex justify-content-between">
        <div className="col-auto">
          <div className="form-group">
            <label>SOS From</label>
            <select
              className="form-control userLimitselect"
              style={{ width: "100%" }}
              value={setMemberUserId}
              onChange={(e) => setSetMemberUserId(e.target.value)}
            >
              <option key={null} value={null}>
                Select Patient
              </option>

              {productList?.list &&
                productList?.list?.length > 0 &&
                productList?.list?.map((item, index) => (
                  <option key={index} value={item?.id}>
                    {item?.username}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="d-flex col-md-6 gap-3 justify-content-end">
          <div className="col-auto">
            <div className="form-group">
              <label>Limit</label>
              <select
                className="form-control userLimitselect"
                style={{ width: "100%" }}
                value={userLimit}
                onChange={(e) => setUserLimit(parseInt(e.target.value))}
              >
                <option value="10">Item's Per Page</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
                <option value="60">60</option>
                <option value="70">70</option>
                <option value="80">80</option>
                <option value="90">90</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
          <div
            className="col-md-2"
            style={{ width: "auto", paddingRight: "0px" }}
          >
            <div className="form-group">
              <label>&nbsp;</label>
              <button
                className="btn head_reset_btn"
                style={{ marginLeft: "0px" }}
                onClick={() => ResetFnct()}
              >
                <i className="bi bi-x-circle"></i>
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="tableContent"
        style={{
          height:
            window.screen.width > 540
              ? "calc(100vh - 155px)"
              : "calc(100vh - 143px)",
        }}
      >
        <Scrollbars
          style={{
            height:
              sosList?.pagination?.total_records > 10
                ? window.screen.width < 767
                  ? "calc(100% - 10px)"
                  : "calc(100% - 66px)"
                : "calc(100% - 0px)",
          }}
          className="ScrollbarsWrapper"
          renderView={(props) => <div {...props} className="view" />}
        >
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Patient Name</th>
                  <th>Member Name</th>
                  <th>Response From</th>
                  <th>Responded</th>
                  <th>Responded At</th>
                  <th>SOS Added At</th>
                </tr>
              </thead>
              <tbody>
                {sosList &&
                  sosList?.list?.map((user, index) => {
                    return (
                      <tr key={index}>
                        {/* <td>{user?.member_id}</td> */}
                        <td>{getPatientNameByID(user?.sos_by)}</td>
                        <td>{getUserNameByID(user?.member_id)}</td>
                        <td>{getUserNameByID(user?.sos_res_from)}</td>

                        <td>{user?.is_responded ? "Yes" : "No Response"}</td>
                        <td>{user?.response_at ? moment(user?.response_at).format("LL h:mm A") : "-"}</td>
                        <td>{moment(user?.created_at).format("LL h:mm A")}</td>
                      </tr>
                    );
                  })}
                {sosList && sosList?.list.length === 0 && (
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan={8}>
                      SOS Not Found !
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Scrollbars>
        {/* pagination */}
        {sosList?.pagination?.total_records > 10 && (
          <CommonPagination
            currentPage={currentPage}
            paginationLength={sosList?.pagination}
            currentFunction={currentFunction}
          />
        )}
      </div>
      {/* {showModal?.open && (
        <CommonModel
          setUserSearch={setUserSearch}
          setCurrentPage={setCurrentPage}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )} */}
    </section>
  );
};

export default SOSManagement;
