/* eslint-disable */
import React, { useContext, useEffect } from "react";
import ModelIcons from "../../assets/img/Model_icons.png";
import BrandIcons from "../../assets/img/brand-image.png";
import { Scrollbars } from "react-custom-scrollbars-2";
import GetDashboardAPI from "../../Api/GetDashboardAPI";
import { useSelector, useDispatch } from "react-redux";
import CommonLogout from "../Common/CommonLogout";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getDashBoardDetails } from "../../redux/actions/adminActions";
import moment from "moment";
import { Link } from "react-router-dom";
import { Chart } from "react-google-charts"; // Import Chart from "react-google-charts"
import {
  ALERT_MANAGEMENT_URL,
  MEMBER_MANAGEMENT_URL,
  SOS_MANAGEMENT_URL,
  REVENUE_MANAGEMENT_URL,
  USER_MANAGEMENT_URL,
  FAQ_MANAGEMENT_URL,
} from "../Shared/constant";
import { WebSocketContext } from "../../App";
import wsSend_request from "../../Api/ws/ws_request";
// Add this import statement

const Dashboard = () => {
  const { websocket } = useContext(WebSocketContext);

  const Dispatch = useDispatch();
  const { accessToken, dashboardCount, selfInfo, relationsArray,device_id } = useSelector(
    (state) => state.adminReducers
  );

  useEffect(()=>{
    // {"transmit":"single", "url":"relations"} 
      let param = {
        transmit: "single",
        url: "relations",
        request: {
          DeviceId: device_id,
        },
    
      };
      wsSend_request(websocket, param);
 
},[accessToken])

  useEffect(() => {
    if (
      accessToken &&
      (selfInfo?.is_admin === true || selfInfo?.is_staff === true)
    ) {
      FetchAllData();
    }
  }, [accessToken]);
  useEffect(() => {
    google.charts.load('current', { packages: ['corechart'] });
    google.charts.setOnLoadCallback(() => {
      drawChart();
    });
  }, [dashboardCount]); // Include dashboardCount in the dependencies if needed

  const drawChart = () => {
    if (!dashboardCount || !dashboardCount.users_count) {
      return; // Return if data is not available
    }

    const { registered_users, members } = dashboardCount.users_count;

    const data = google.visualization.arrayToDataTable([
      ['Category', 'Count'],
      ['Registered Users', registered_users],
      ['Members', members],
    ]);

    const options = {
      title: '',
    };

    const chart = new google.visualization.PieChart(
      document.getElementById('pieChartExample')
    );

    chart.draw(data, options);
  };
  // fetch all data list
  const FetchAllData = async () => {
    const response = await GetDashboardAPI(accessToken);
    if (response?.status === 200) {
      setTimeout(() => {
        Dispatch(getDashBoardDetails(response));
        toast.success(response.msg);
      }, 100);
    } else if (response?.status === 403) {
      CommonLogout(accessToken, Dispatch);
    } else {
      toast.error(response.error);
    }
  };

  google.charts.load("current", { packages: ["corechart", "bar"] });
  google.charts.setOnLoadCallback(drawChartUsers);
  google.charts.setOnLoadCallback(drawChartProducts);
  google.charts.setOnLoadCallback(drawChartSupplier);

  const getLastSixMonth = [];
  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var today =
    dashboardCount?.past_six_month_user_count?.past_six_month_count.length > 0
      ? new Date(
          dashboardCount?.past_six_month_user_count?.past_six_month_count[
            dashboardCount?.past_six_month_user_count?.past_six_month_count
              ?.length - 1
          ]?.month
        )
      : new Date();
  var d;
  var month;
  for (var i = 6; i >= 0; i -= 1) {
    d = new Date(today.getFullYear(), today.getMonth() - i, 1);
    month = monthNames[d.getMonth()];
    getLastSixMonth.push(`${month} 1, ${moment(d).format("YYYY")}`);
  }
  var EmptyMonthArray = [
    [getLastSixMonth[0], 0],
    [getLastSixMonth[1], 0],
    [getLastSixMonth[2], 0],
    [getLastSixMonth[3], 0],
    [getLastSixMonth[4], 0],
    [getLastSixMonth[5], 0],
    [getLastSixMonth[6], 0],
  ];
  function drawChartUsers() {
    const PastSixPamentArrays = [["Year", "count"]];
    const PastSixPamentArraysNew = [];

    if (
      dashboardCount?.past_six_month_user_count?.past_six_month_count.length > 0
    ) {
      dashboardCount?.past_six_month_user_count?.past_six_month_count.filter(
        (elm) => {
          PastSixPamentArraysNew.push([
            moment.utc(elm.month).local().format("MMMM D, YYYY"),
            elm.count,
          ]);
        }
      );
      const PastData_ = PastSixPamentArraysNew.map(
        (elm) => elm[0].split(" ")[0]
      ).toString();
      EmptyMonthArray?.filter((elm) => {
        const currentValue = elm[0].split(" ")[0];
        if (PastData_.includes(currentValue) !== true) {
          PastSixPamentArraysNew.push(elm);
        }
      });
    }

    function sortByMonth(arr) {
      var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      arr.sort(function (a, b) {
        return (
          months.indexOf(a[0].split(" ")[0]) -
          months.indexOf(b[0].split(" ")[0])
        );
      });
    }
    sortByMonth(PastSixPamentArraysNew);
    var data = google.visualization.arrayToDataTable(
      PastSixPamentArrays.concat(PastSixPamentArraysNew)
    );

    var options = {
      chart: {
        // title: 'Company Performance',
        // subtitle: 'Sales, Expenses, and Profit: 2014-2017',
      },
    };
    var chart = new google.charts.Bar(
      document.getElementById("sixmonthUsers_material")
    );
    chart.draw(data, google.charts.Bar.convertOptions(options));
  }

  const getLastSixMonthNew = [];
  var monthNamess = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var todays =
    dashboardCount?.past_six_month_patient_count?.past_six_month_count.length >
    0
      ? new Date(
          dashboardCount?.past_six_month_patient_count?.past_six_month_count[
            dashboardCount?.past_six_month_patient_count?.past_six_month_count
              ?.length - 1
          ]?.month
        )
      : new Date();
  var ds;
  var months;
  for (var i = 6; i >= 0; i -= 1) {
    ds = new Date(todays.getFullYear(), todays.getMonth() - i, 1);
    months = monthNamess[ds.getMonth()];
    getLastSixMonthNew.push(`${months} 1, ${moment(ds).format("YYYY")}`);
  }
  var EmptyMonthArrays = [
    [getLastSixMonthNew[0], 0],
    [getLastSixMonthNew[1], 0],
    [getLastSixMonthNew[2], 0],
    [getLastSixMonthNew[3], 0],
    [getLastSixMonthNew[4], 0],
    [getLastSixMonthNew[5], 0],
    [getLastSixMonthNew[6], 0],
  ];
  function drawChartProducts() {
    const PastSixPamentArrays = [["Year", "count"]];
    const PastSixPamentArraysNew = [];

    if (
      dashboardCount?.past_six_month_patient_count?.past_six_month_count
        .length > 0
    ) {
      dashboardCount?.past_six_month_patient_count?.past_six_month_count.filter(
        (elm) => {
          PastSixPamentArraysNew.push([
            moment.utc(elm.month).local().format("MMMM D, YYYY"),
            elm.count,
          ]);
        }
      );
      const PastData_ = PastSixPamentArraysNew.map(
        (elm) => elm[0].split(" ")[0]
      ).toString();
      EmptyMonthArrays?.filter((elm) => {
        const currentValue = elm[0].split(" ")[0];
        if (PastData_.includes(currentValue) !== true) {
          PastSixPamentArraysNew.push(elm);
        }
      });
    }

    function sortByMonth(arr) {
      var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      arr.sort(function (a, b) {
        return (
          months.indexOf(a[0].split(" ")[0]) -
          months.indexOf(b[0].split(" ")[0])
        );
      });
    }
    sortByMonth(PastSixPamentArraysNew);

    var data = google.visualization.arrayToDataTable(
      PastSixPamentArrays.concat(PastSixPamentArraysNew)
    );

    var options = {
      chart: {
        // title: 'Company Performance',
        // subtitle: 'Sales, Expenses, and Profit: 2014-2017',
      },
    };
    var chart = new google.charts.Bar(
      document.getElementById("sixmonthProduct_material")
    );
    chart.draw(data, google.charts.Bar.convertOptions(options));
  }

  const getLastSixMonthNewz = [];
  var monthNamessz = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var todaysz =
    dashboardCount?.past_six_month_supplier_count?.past_six_month_count.length >
    0
      ? new Date(
          dashboardCount?.past_six_month_supplier_count?.past_six_month_count[
            dashboardCount?.past_six_month_supplier_count?.past_six_month_count
              ?.length - 1
          ]?.month
        )
      : new Date();
  var dss;
  var monthsz;
  for (var i = 6; i >= 0; i -= 1) {
    dss = new Date(todaysz.getFullYear(), todaysz.getMonth() - i, 1);
    monthsz = monthNamessz[dss.getMonth()];
    getLastSixMonthNewz.push(`${monthsz} 1, ${moment(dss).format("YYYY")}`);
  }
  var EmptyMonthArraysz = [
    [getLastSixMonthNewz[0], 0],
    [getLastSixMonthNewz[1], 0],
    [getLastSixMonthNewz[2], 0],
    [getLastSixMonthNewz[3], 0],
    [getLastSixMonthNewz[4], 0],
    [getLastSixMonthNewz[5], 0],
    [getLastSixMonthNewz[6], 0],
  ];
  function drawChartSupplier() {
    const PastSixPamentArrays = [["Year", "count"]];
    const PastSixPamentArraysNew = [];

    if (
      dashboardCount?.past_six_month_supplier_count?.past_six_month_count
        .length > 0
    ) {
      dashboardCount?.past_six_month_supplier_count?.past_six_month_count.filter(
        (elm) => {
          PastSixPamentArraysNew.push([
            moment.utc(elm.month).local().format("MMMM D, YYYY"),
            elm.count,
          ]);
        }
      );
      const PastData_ = PastSixPamentArraysNew.map(
        (elm) => elm[0].split(" ")[0]
      ).toString();
      EmptyMonthArraysz?.filter((elm) => {
        const currentValue = elm[0].split(" ")[0];
        if (PastData_.includes(currentValue) !== true) {
          PastSixPamentArraysNew.push(elm);
        }
      });
    }

    function sortByMonth(arr) {
      var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      arr.sort(function (a, b) {
        return (
          months.indexOf(a[0].split(" ")[0]) -
          months.indexOf(b[0].split(" ")[0])
        );
      });
    }
    sortByMonth(PastSixPamentArraysNew);
    var data = google.visualization.arrayToDataTable(
      PastSixPamentArrays.concat(PastSixPamentArraysNew)
    );

    var options = {
      chart: {
        // title: 'Company Performance',
        // subtitle: 'Sales, Expenses, and Profit: 2014-2017',
      },
    };
    var chart = new google.charts.Bar(
      document.getElementById("sixmonthSupplier_material")
    );
    chart.draw(data, google.charts.Bar.convertOptions(options));
  }

  return (
    <section className="dashboard_wrapper">
      <Scrollbars
        style={{ height: "calc(100vh - 100px)" }}
        className="ScrollbarsWrapper"
        renderView={(props) => <div {...props} className="view" />}
      >
        <div className="column_count_colus">
          {(selfInfo?.is_admin ||
            selfInfo?.permissions?.includes("user-list") ||
            selfInfo?.permissions?.includes("add-user") ||
            selfInfo?.permissions?.includes("update-user") ||
            selfInfo?.permissions?.includes("delete-user")) && (
            <Link className="dashColswe" to={USER_MANAGEMENT_URL}>
              <div
                className="dashIcon"
                style={{
                  backgroundColor: "rgba(255, 201, 108, 0.1)",
                  color: "#ffc96c",
                }}
              >
                <i className="bi bi-people-fill"></i>
              </div>
              <h1>{dashboardCount?.users_count?.total}</h1>
              <h4>Total Users</h4>
            </Link>
          )}
          {(selfInfo?.is_admin ||
            selfInfo?.permissions?.includes("product-list") ||
            selfInfo?.permissions?.includes("add-product") ||
            selfInfo?.permissions?.includes("update-product") ||
            selfInfo?.permissions?.includes("delete-product")) && (
            <Link className="dashColswe" to={MEMBER_MANAGEMENT_URL}>
              <div
                className="dashIcon"
                style={{
                  backgroundColor: "rgb(59 156 255 / 10%)",
                  color: "#017bf6",
                }}
              >
                <i className="bibi bi-person-fill-gear"></i>
              </div>
              <h1>{dashboardCount?.patients_count?.total}</h1>
              <h4>Total Patients</h4>
            </Link>
          )}
          {(selfInfo?.is_admin ||
            selfInfo?.permissions?.includes("add-alert") ||
            selfInfo?.permissions?.includes("update-alert") ||
            selfInfo?.permissions?.includes("delete-alert") ||
            selfInfo?.permissions?.includes("alert-list")) && (
            <Link className="dashColswe" to={ALERT_MANAGEMENT_URL}>
              <div
                className="dashIcon"
                style={{
                  backgroundColor: "rgba(30, 190, 44, 0.1)",
                  color: "#1ebe2c",
                }}
              >
                <i className="bi bi bi-exclamation-square-fill"></i>
              </div>
              <h1>{dashboardCount?.alerts_count?.total}</h1>
              <h4>Total Alerts</h4>
            </Link>
          )}
          {(selfInfo?.is_admin ||
            selfInfo?.permissions?.includes("sos-list") ||
            selfInfo?.permissions?.includes("add-sos") ||
            selfInfo?.permissions?.includes("update-sos") ||
            selfInfo?.permissions?.includes("delete-sos")) && (
            <Link to={SOS_MANAGEMENT_URL} className="dashColswe">
              <div
                className="dashIcon"
                style={{
                  backgroundColor: "rgba(143, 82, 161,0.1)",
                  color: "#8f52a1",
                }}
              >
                <i className="bi bi-alarm-fill"></i>{" "}
              </div>
              <h1>{dashboardCount?.sos_count?.total}</h1>
              <h4>Total SOS</h4>
            </Link>
          )}
          {(selfInfo?.is_admin ||
            selfInfo?.permissions?.includes("faq-list") ||
            selfInfo?.permissions?.includes("add-faq") ||
            selfInfo?.permissions?.includes("update-faq") ||
            selfInfo?.permissions?.includes("delete-faq")) && (
            <Link to={FAQ_MANAGEMENT_URL} className="dashColswe">
              <div
                className="dashIcon"
                style={{
                  backgroundColor: "rgba(177, 133, 255,0.1)",
                  color: "#b185ff",
                }}
              >
                <i className="bi bi-wallet-fill"></i>
              </div>
              <h1>{dashboardCount?.faqs_count?.total}</h1>
              <h4>Total FAQ's</h4>
            </Link>
          )}
        </div>
        {selfInfo?.is_admin && (
          <div className="graphs_columns">
            <h4>User Type Distribution</h4>
            <div id="pieChartExample" className="piechartwraps" style={{ width: '100%', height: '300px' }}></div>
          </div>
        )}
        {(selfInfo?.is_admin ||
          selfInfo?.permissions?.includes("user-list") ||
          selfInfo?.permissions?.includes("add-user") ||
          selfInfo?.permissions?.includes("update-user") ||
          selfInfo?.permissions?.includes("delete-user")) && (
          <div className="graphs_columns">
            <h4>Past Six Month Users</h4>
            <div
              id="sixmonthUsers_material"
              className="piechartwraps"
              style={{ width: "100%", height: "300px" }}
            ></div>
          </div>
        )}
        {(selfInfo?.is_admin ||
          selfInfo?.permissions?.includes("product-list") ||
          selfInfo?.permissions?.includes("add-product") ||
          selfInfo?.permissions?.includes("update-product") ||
          selfInfo?.permissions?.includes("delete-product")) && (
          <div className="graphs_columns">
            <h4>Past Six Month Patients</h4>
            <div
              id="sixmonthProduct_material"
              className="piechartwraps"
              style={{ width: "100%", height: "300px" }}
            ></div>
          </div>
        )}
        {(selfInfo?.is_admin ||
          selfInfo?.permissions?.includes("add-supplier") ||
          selfInfo?.permissions?.includes("update-supplier") ||
          selfInfo?.permissions?.includes("delete-supplier") ||
          selfInfo?.permissions?.includes("supplier-list")) && (
          <div className="graphs_columns">
            <h4 hidden>Past Six Month Revenue</h4>
            <div
              id="sixmonthSupplier_material"
              hidden
              className="piechartwraps"
              style={{ width: "100%", height: "300px" }}
            ></div>
          </div>
        )}
        { /* Add this section for the pie chart */ }
        


      </Scrollbars>
    </section>
  );
};

export default Dashboard;
