/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import { DASHBOARD_URL,  REVENUE_MANAGEMENT_URL, MEMBER_MANAGEMENT_URL, USER_MANAGEMENT_URL } from "../Shared/constant";
import BackButton from "../Common/BackButton";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import CheckValid from "../Common/CheckValid";
import InputField from "../Common/InputField";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useNavigate } from "react-router";
import CountrySelectInput from "../Common/CountrySelectInput/CountryMobileInput";

const Profile = () => {
    const Navigate = useNavigate();
    const { selfInfo, device_id ,relationsArray} = useSelector((state) => state.adminReducers);
    const [ inputValue, setInputValue ] = useState({ 
        username: "", relation: "", Address: "", Old_password: "", Password: "",  phone_code: "",
        phone: "",
    });
    const { username, relation, Address, Old_password, Password,  phone_code,
    phone, } = inputValue;
    const [PhoneNumber, setPhoneNumber] = useState({
        phone_code: "1",
        phone: "",
      });
  const [userRelation, setUserRelation] = useState(selfInfo?.relation);
    const [ showpassword, setshowpassword ] = useState(false);
    const [ showOldpassword, setshowOldpassword ] = useState(false);
    const [ ChangePasswordState, setChangePasswordState ] = useState(false);
    const { websocket } = useContext(WebSocketContext);

    // error message
  const [errorRelation, setErrorRealtion] = useState("");

    const [ errorusername, setErrorusername ] = useState("");
    const [ errorLname, setErrorLname ] = useState("");
    const [ errorAddress, setErrorAddress ] = useState("");
    const [ errorOldPassword, setErrorOldPassword ] = useState("");
    const [ errorPassword, setErrorPassword ] = useState("");
  const [errorPhone, setErrorPhone] = useState("");


    const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/; // eslint-disable-line

    useEffect(() => {
        if(ChangePasswordState === true) {
            const MainWrapsId = document.getElementById("chnagepasswordblog");
            MainWrapsId.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }, [ ChangePasswordState ])

    useEffect(() => {
        if(selfInfo) {
            setInputValue({
                ...inputValue, 
                username: selfInfo?.username, 
                relation: selfInfo?.relation
            });
            setPhoneNumber({
                ...PhoneNumber,
                phone_code: selfInfo?.phone_code,
                phone: selfInfo?.phone,
              });
              setUserRelation(selfInfo?.relation);
              setPhoneNumber({
                phone_code: selfInfo?.phone_code,
                phone:selfInfo?.phone,
              })
        };
    }, [ selfInfo ]);

    // Textfield common onchange function
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // show password
    const ShowHidePassword = () => {
        setshowpassword(!showpassword);
    };

    // show password
    const ShowOldHidePassword = () => {
        setshowOldpassword(!showOldpassword);
    };

    const RoutUrlSet = () => {
        if(selfInfo?.is_admin || (selfInfo?.permissions?.includes("dashboard"))) {
            return DASHBOARD_URL;
        } else {
            if(selfInfo?.permissions !== null) {
                if(selfInfo?.permissions?.split(",")[0] === "user-list" || 
                selfInfo?.permissions?.split(",")[0] === "add-user" || 
                selfInfo?.permissions?.split(",")[0] === "update-user" ||
                selfInfo?.permissions?.split(",")[0] === "delete-user"
                ) {
                    return USER_MANAGEMENT_URL;
                } else if(selfInfo?.permissions?.split(",")[0] === "product-list" || 
                selfInfo?.permissions?.split(",")[0] === "add-product" || 
                selfInfo?.permissions?.split(",")[0] === "update-product" ||
                selfInfo?.permissions?.split(",")[0] === "delete-product"
                ) {
                    return MEMBER_MANAGEMENT_URL;
                } else if(selfInfo?.permissions?.split(",")[0] === "add-supplier" || 
                selfInfo?.permissions?.split(",")[0] === "update-supplier" || 
                selfInfo?.permissions?.split(",")[0] === "delete-supplier" ||
                selfInfo?.permissions?.split(",")[0] === "supplier-list"
                ) {
                    return REVENUE_MANAGEMENT_URL;
                }
            } 
        }
    };

    // edit profile
    const EditProfile = () => {
        let param = "";
      
        if (
          inputValue?.username !== "" ||
          (inputValue?.Old_password !== "" && inputValue?.Password !== "" &&
            PasswordRegex.test(inputValue?.Old_password) && PasswordRegex.test(inputValue?.Password))
        ) {
          if (inputValue?.Old_password === inputValue?.Password && (inputValue?.Old_password !== "" && inputValue?.Password !== "")) {
            setErrorPassword("Old Password and new password cannot be the same.");
          } else {
            param = {
              transmit: "broadcast",
              url: "profile_update",
              request: {
                username: inputValue?.username,
                DeviceId: device_id,
                old_pass: inputValue?.Old_password || null,
                new_pass: inputValue?.Password || null,
                phone_code: PhoneNumber?.phone_code,
                phone: PhoneNumber?.phone,
              relation:userRelation 

              },
            };
            wsSend_request(websocket, param);
            setInputValue({
              ...inputValue,
              username: "",
              Password: "",
              Old_password: "",
              relation:""
            });
            setPhoneNumber({
                phone_code: "1",
                phone: "",
              })
            setTimeout(() => Navigate(RoutUrlSet()), 200);
          }
        } else {
          CheckValid(inputValue?.username, { type: 'username', error: setErrorusername });
          // Add similar CheckValid calls for Lname, Address, Old_password, and Password
        }
      };
      
      
      

    // change password api 
    const EditProfilePassword = () => {
        if(inputValue?.Old_password !== "" &&  inputValue?.Password !== "") {
            if(PasswordRegex.test(inputValue?.Old_password) === true && PasswordRegex.test(inputValue?.Password) === true) {
                if(inputValue?.Old_password !==  inputValue?.Password) {
                    let param = { 
                        "transmit": "broadcast", 
                        "url": "profile_update", 
                        "request" :  { 
                            "old_password" : inputValue?.Old_password,
                            "new_password" : inputValue?.Password,
                            "DeviceId" : device_id
                        }
                    };
                    wsSend_request(websocket, param);
                    setInputValue({
                        ...inputValue, 
                        Old_password: "",
                        Password: ""
                    });
                    // setTimeout(() => Navigate(DASHBOARD_URL), 200);
                } else {
                    if(inputValue?.Old_password === inputValue?.Password) {
                        setErrorPassword("Old Password and new password cannot be same.");
                    } else {
                        setErrorPassword("");
                    }
                }
            } else {
                CheckValid(inputValue?.Old_password, {type: 'oldpassword', error: setErrorOldPassword });
                CheckValid(inputValue?.Password, {type: 'newpassword', error: setErrorPassword });
            }
        } else {
            CheckValid(inputValue?.Old_password, {type: 'oldpassword', error: setErrorOldPassword });
            CheckValid(inputValue?.Password, {type: 'newpassword', error: setErrorPassword });
        };
    }

    return(<section className="dashboard_wrapper">
        <BackButton targeturl={RoutUrlSet()} title={"Back"} />
        <Scrollbars 
            style={{ height: "calc(100% - 53px)" }} 
            className="ScrollbarsWrapper"
            renderView={props => <div {...props} className="view"/>}
        >
            <div className="form-sections">
                <div className="row">
                    <div className="col-md-6">
                        <InputField
                            type="text"
                            value={username}
                            className={errorusername !== "" ? "form-control error-form" : "form-control"}
                            placeholder="Enter User Name"
                            label="User Name"
                            name="username"
                            onChange={handleChange}
                            onKeyUp={(e) => CheckValid(e.target.value, {type: 'username', error: setErrorusername})}
                            onKeyDown={EmptySpaceFieldValid}
                            errormessage={errorusername}
                        />
                    </div>
                    <div className="col-md-6">
                    <div className="form-group">
                
                  <label>Relation</label>
                  <select
                    className="form-control activestatusselet"
                    value={userRelation}
                    onChange={(e) => {
                      setUserRelation(e.target.value);
                    }}
                    style={{ width: "100%" }}
                  >
                    <option value="">Select Relation</option>
                    {relationsArray.map((relation, index) => (
                      <option value={relation} key={index}>
                        {relation}
                      </option>
                    ))}
                  </select>
              
            </div>
                    </div>
                    <div className="col-md-6">
            <CountrySelectInput
              errorPhone={errorPhone}
              phone={PhoneNumber}
              setPhone={setPhoneNumber}
              CheckValid={CheckValid}
              setErrorPhone={setErrorPhone}
            />
          </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Email</label>
                            <div className="inputText">
                                {selfInfo?.email === null ? "-" : selfInfo?.email}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6" hidden>
                        <div className="form-group">
                            <label>Mobile No</label>
                            <div className="inputText">
                                +{selfInfo?.phone_code === null ? "1" : selfInfo?.phone_code} &nbsp; {selfInfo?.phone === null ? "-" : selfInfo?.phone}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <InputField
                            type={showOldpassword ? "text" : "password"}
                            value={Old_password}
                            className={errorOldPassword !== "" ? "form-control error-form" : "form-control"}
                            placeholder="Enter Old password"
                            label="Enter Old Password"
                            name="Old_password"
                            onChange={handleChange}
                            onKeyUp={(e) => CheckValid(e.target.value, {type: 'oldpassword', error: setErrorOldPassword})}
                            onKeyDown={EmptySpaceFieldValid}
                            errormessage={errorOldPassword}
                            onClick={ShowOldHidePassword}
                            showOldpassword={showOldpassword}
                        />
                    </div>
                    <div className="col-md-6">
                        <InputField
                            type={showpassword ? "text" : "password"}
                            value={Password}
                            className={errorPassword !== "" ? "form-control error-form" : "form-control"}
                            placeholder="Enter New password"
                            label="Enter New Password"
                            name="Password"
                            onChange={handleChange}
                            onKeyUp={(e) => CheckValid(e.target.value, {type: 'newpassword', error: setErrorPassword})}
                            onKeyDown={EmptySpaceFieldValid}
                            errormessage={errorPassword}
                            onClick={ShowHidePassword}
                            showpassword={showpassword}
                        />
                    </div>
                    <div className="col-md-12">
                        <div className="form-group-btn" style={{ textAlign: "right" }}>
                            <button type="button" className="btn addbtncmn" onClick={() => EditProfile()}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-sections" hidden>
            <h4 className="headecenter">Change Password</h4>
                <div className="row">
                    <div className="col-md-6">
                        <InputField
                            type={showOldpassword ? "text" : "password"}
                            value={Old_password}
                            className={errorOldPassword !== "" ? "form-control error-form" : "form-control"}
                            placeholder="Enter Old password"
                            label="Enter Old Password"
                            name="Old_password"
                            onChange={handleChange}
                            onKeyUp={(e) => CheckValid(e.target.value, {type: 'oldpassword', error: setErrorOldPassword})}
                            onKeyDown={EmptySpaceFieldValid}
                            errormessage={errorOldPassword}
                            onClick={ShowOldHidePassword}
                            showOldpassword={showOldpassword}
                        />
                    </div>
                    <div className="col-md-6">
                        <InputField
                            type={showpassword ? "text" : "password"}
                            value={Password}
                            className={errorPassword !== "" ? "form-control error-form" : "form-control"}
                            placeholder="Enter New password"
                            label="Enter New Password"
                            name="Password"
                            onChange={handleChange}
                            onKeyUp={(e) => CheckValid(e.target.value, {type: 'newpassword', error: setErrorPassword})}
                            onKeyDown={EmptySpaceFieldValid}
                            errormessage={errorPassword}
                            onClick={ShowHidePassword}
                            showpassword={showpassword}
                        />
                    </div>
                    <div className="col-md-12">
                        <div className="form-group-btn" style={{ textAlign: "right" }}>
                            <button type="button" className="btn addbtncmn" onClick={() => EditProfilePassword()}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Scrollbars>
    </section>)
}

export default Profile;