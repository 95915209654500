import { parsePhoneNumber, getRegionCodeForCountryCode } from "awesome-phonenumber";

// empty space validation fucntion
const CheckValid = (value, states) => {
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
    const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/; // eslint-disable-line
    
    // email validation
    if(states?.type === "email") {
        if(value !== "") {
            if(EmailRegex.test(value) === true) {
                return states?.error("");    
            } else {
                if(EmailRegex.test(value) === false) {
                    return states?.error("Please enter valid email address");
                } else {
                    return states?.error("");
                }
            }
        } else {
            if(value === "") {
                return states?.error("Email address cannot be empty");
            } else if(EmailRegex.test(value) === false) {
                return states?.error("Please enter valid email address");
            } else {
                return states?.error("");
            }
        };
    };

    // password validation
    if(states?.type === "password"){
        if(value !== "") {
            if(PasswordRegex.test(value) === true) {
                return states?.error("");
            } else {
                if(PasswordRegex.test(value) === false) {
                    return states?.error("Passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters.");
                } else {
                    return states?.error("");
                }
            }
        } else {
            if (value === "") {
                return states?.error("Password cannot be empty");
            } else if(PasswordRegex.test(value) === false) {
                return states?.error("Passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters.");
            } else {
                return states?.error("");
            }
        }
    }

    // old password validation
    if(states?.type === "oldpassword"){
        if(value !== "") {
            if(PasswordRegex.test(value) === true) {
                return states?.error("");
            } else {
                if(PasswordRegex.test(value) === false) {
                    return states?.error("Passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters.");
                } else {
                    return states?.error("");
                }
            }
        } else {
            if (value === "") {
                return states?.error("Old Password cannot be empty");
            } else if(PasswordRegex.test(value) === false) {
                return states?.error("Passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters.");
            } else {
                return states?.error("");
            }
        }
    }

    // new password validation
    if(states?.type === "newpassword"){
        if(value !== "") {
            if(PasswordRegex.test(value) === true) {
                return states?.error("");
            } else {
                if(PasswordRegex.test(value) === false) {
                    return states?.error("Passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters.");
                } else {
                    return states?.error("");
                }
            }
        } else {
            if (value === "") {
                return states?.error("New Password cannot be empty");
            } else if(PasswordRegex.test(value) === false) {
                return states?.error("Passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters.");
            } else {
                return states?.error("");
            }
        }
    }
      // Full name validation
      if(states?.type === "full_name"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Full name cannot be empty");
        }
    }

    // User name validation
    if(states?.type === "username"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("User name cannot be empty");
        }
    }

    // relation validation
    if(states?.type === "relation"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Relation cannot be empty");
        }
    }

    // first name validation
    if(states?.type === "SupplierName"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Supplier name cannot be empty");
        }
    }

    // Currency validation
    if(states?.type === "Currency"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Currency cannot be empty");
        }
    }

    // title validation
    if(states?.type === "Title"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Title cannot be empty");
        }
    }

    // WebSiteUrl validation
    if(states?.type === "WebSiteUrl"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("WebSite cannot be empty");
        }
    }

    // Business name validation
    if(states?.type === "Business_name"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Business name cannot be empty");
        }
    }

    // Address validation
    if(states?.type === "Address"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Address cannot be empty");
        }
    }
     // Member PIN validation
     if(states?.type === "Member PIN"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Member PIN cannot be empty");
        }
    }

    // phone validation
    if(states?.type === "phone"){
        const CountryStates = getRegionCodeForCountryCode(value?.phone_code);
        const CheckMobileNumber = parsePhoneNumber(value?.phone?.toString(), { regionCode: CountryStates });
        if(value?.phone !== "") {
            if(CheckMobileNumber?.valid === true) {
                return states?.error("");
            } else {
                return states?.error(`Please enter valid region mobile number`);
            }
        } else {
            if (value?.phone === "") {
                return states?.error("Mobile no cannot be empty");
            } else if(CheckMobileNumber?.valid === false) {
                return states?.error("please enter valid region mobile number");
            } else {
                return states?.error("");
            }
        }
    };
      // Mobile Number validation
      if(states?.type === "mobile_no"){
        if (value === "") {
            return states?.error("Please enter a valid mobile number");
        } else if (value?.length !== 10) {
            return states?.error("Mobile number should be 10 digits long");
        } else {
            return states?.error("");
        }        
    }

    // Product Name validation
    if(states?.type === "ProductName"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Product name cannot be empty");
        }
    }

    // Product Name validation
    if(states?.type === "Model"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Model cannot be empty");
        }
    }

    // Brand validation
    if(states?.type === "Brand"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Brand cannot be empty");
        }
    }

    // Select brand validation
    if(states?.type === "Select Brand"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Please select brand");
        }
    }

    // Unit const price validation
    if(states?.type === "Unitconstprice"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Unit cost price cannot be empty");
        }
    }

    // Unit const price validation
    if(states?.type === "Description"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Description cannot be empty");
        }
    }

    // Supplier name validation
    if(states?.type === "SupplierName"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Supplier name cannot be empty");
        }
    }

    // Supplier name validation
    if(states?.type === "SupplierSelect"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Please select supplier");
        }
    }

    // permission validation
    if(states?.type === "permission"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Please select permisssion");
        }
    }

    // brand name validation
    if(states?.type === "Brand Name"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Brand name cannot be empty");
        }
    }

    // model name validation
    if(states?.type === "Model Name"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Model name cannot be empty");
        }
    }

    // Company name validation
    if(states?.type === "CompanyName"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Company name cannot be empty");
        }
    }

    // VATRegistrationNo name validation
    if(states?.type === "VATRegistrationNo"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("VAT registration no cannot be empty");
        }
    }
    
    
    // RegistrationNo name validation
    if(states?.type === "RegistrationNo"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Registration no cannot be empty");
        }
    }

    // Amount validation
    if(states?.type === "Amount"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Amount cannot be empty");
        }
    }

    // InCoterm validation
    if(states?.type === "InCoterm"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("In-Coterm cannot be empty");
        }
    }

    // Quantity validation
    if(states?.type === "Quantity"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Quantity cannot be empty");
        }
    }

    // ItemNumber validation
    if(states?.type === "ItemNumber"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Item number cannot be empty");
        }
    }

    // PI number validation
    if(states?.type === "PInumber"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("PI-number cannot be empty");
        }
    }

    // HSCode validation
    if(states?.type === "HSCode"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("HS-code cannot be empty");
        }
    }

    // PcCode validation
    if(states?.type === "PcCode"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("PcCode cannot be empty");
        }
    }

    // Category Name validation
    if(states?.type === 'Category Name'){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Category Name cannot be empty");
        }
    }

    // setting name validation
    if(states?.type === "Setting Name"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Field name cannot be empty");
        }
    }
};

export default CheckValid;