/* eslint-disable */
import { ActionTypes } from "../actions/adminActions";

const initailData = {
  is_login: "no",
  user_id: "",
  device_id: "",
  selfInfo: {},
  accessToken: "",
  permissionList: [],
  dashboardCount: {},
  userList: [],
  relationsArray: [],
  userListIdWise: {},
  usersMemberListByID: {
    list: [],
    pagination: {},
  },
  usersAlertListByID: {
    list: [],
    pagination: {},
  },
  usersMemberListByPatientID: {
    list: [],
    pagination: {},
  },
  usersSOSListByID: {
    list: [],
    pagination: {},
  },
  usersPatientListByID: {
    list: [],
    pagination: {},
  },
  brandList: {
    list: [],
    pagination: {},
  },
  modelList: {
    list: [],
    pagination: {},
  },
  faqListAll: [],

  brandmodelsList: {
    list: [],
    pagination: {},
  },
  supplierList: {
    list: [],
    pagination: {},
  },
  supplierListall: [],
  supplierdetails: {},
  productList: {
    list: [],
    pagination: {},
  },
  userMemberList: {
    list: [],
    pagination: {},
  },
  productListAll: [],
  userMemberListAll: [],

  brandlistall: [],
  userMemberListByIDall: [],
  userAlertListByIDall: [],
  userSOSListByIDall: [],
  userPatientListByIDall: [],
  modellistall: [],
  selectedBrandWiseModel: [],
  viewProduct: {},
  loaderMain: false,
  ClearCaches_setww: "",
  alertList: {
    list: [],
    pagination: {},
  },
  categoryAllList: [],
  ClearFormSet: {
    url: "",
    action: false,
  },
  sosList: {
    list: [],
    pagination: {},
  },
  settingsObjectIdWise: {},
};

export const adminReducers = (state = initailData, action) => {
  switch (action.type) {
    case ActionTypes.IS_LOGIN:
      return {
        ...state,
        is_login: action.payload,
      };
    case ActionTypes.GET_USER_ID:
      return {
        ...state,
        user_id: action.payload,
      };
    case ActionTypes.GET_DEVICE_ID:
      return {
        ...state,
        device_id: action.payload,
      };
    case ActionTypes.SELF_DETAILS:
      return {
        ...state,
        selfInfo: action.payload,
      };
    case ActionTypes.CLEAR_FORM_SET_FUCT:
      return {
        ...state,
        ClearFormSet: {
          url: action?.payload?.url,
          action: action?.payload?.action,
        },
      };
    case ActionTypes.EDIT_SELF_DETAILS:
      return {
        ...state,
        selfInfo: action.payload,
      };
    case ActionTypes.GET_ACCESSTOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };
    case ActionTypes.LOADER_MAIN_START:
      return {
        ...state,
        loaderMain: action.payload,
      };
    case ActionTypes.GET_USER_LIST:
      return {
        ...state,
        userList: action.payload,
      };
    case ActionTypes.GET_RELATION_LIST:
      return {
        ...state,
        relationsArray: action.payload?.data,
      };
    case ActionTypes.GET_DASHBOARD_DETAILS:
      return {
        ...state,
        dashboardCount: action.payload,
      };
    case ActionTypes.GET_USER_LIST_ID_WISE:
      const ObjectUserListId = {};
      action.payload.map((elm) => {
        ObjectUserListId[elm?.id] = elm;
      });

      return {
        ...state,
        userListIdWise: ObjectUserListId,
      };
    case ActionTypes.ADD_USER_LIST:
      const OldUserlist = [...state.userList];
      OldUserlist.unshift(action.payload);
      return {
        ...state,
        userList: OldUserlist,
      };
    case ActionTypes.EDIT_USER_LIST:
      const OldUserlistx = [...state.userList];
      const UpdateUserList = OldUserlistx.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            first_name: action.payload.first_name,
            last_name: action.payload.last_name,
            email: action.payload.email,
            password: action.payload.password,
            phone_code: action.payload.phone_code,
            phone: action.payload.phone,
            address: action.payload.address,
            permissions: action.payload.permissions,
            is_admin: action.payload.is_admin,
            is_staff: action.payload.is_staff,
            is_active: action.payload.is_active,
          };
        } else return elm;
      });
      return {
        ...state,
        userList: UpdateUserList,
      };
    case ActionTypes.DELETE_USER:
      const OldUserListz = [...state.userList];
      const NewUpdatedUserList = [];
      OldUserListz &&
        OldUserListz.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdatedUserList.push(elm);
          }
        });
      return {
        ...state,
        userList: NewUpdatedUserList,
      };
    case ActionTypes.GET_BRAND_LIST:
      return {
        ...state,
        brandList: {
          list:
            Object.keys(action.payload).length === 0
              ? []
              : action?.payload?.data?.data,
          pagination:
            Object.keys(action.payload).length === 0
              ? []
              : action?.payload?.data?.pagination,
        },
      };
    case ActionTypes.GET_USERS_MEMBER_LIST_BY_ID:
      return {
        ...state,
        usersMemberListByID: {
          list:
            Object.keys(action.payload).length === 0
              ? []
              : action?.payload?.data?.data,
          pagination:
            Object.keys(action.payload).length === 0
              ? []
              : action?.payload?.data?.pagination,
        },
      };
    case ActionTypes.GET_USERS_ALERT_LIST_BY_ID:
      return {
        ...state,
        usersAlertListByID: {
          list:
            Object.keys(action.payload).length === 0
              ? []
              : action?.payload?.data?.data,
          pagination:
            Object.keys(action.payload).length === 0
              ? []
              : action?.payload?.data?.pagination,
        },
      };
    case ActionTypes.GET_MEMBER_LIST_BY_PATIENT_ID:
      return {
        ...state,
        usersMemberListByPatientID: {
          list:
            Object.keys(action.payload).length === 0
              ? []
              : action?.payload?.data?.data,
          pagination:
            Object.keys(action.payload).length === 0
              ? []
              : action?.payload?.data?.pagination,
        },
      };
    case ActionTypes.GET_USERS_SOS_LIST_BY_ID:
      return {
        ...state,
        usersSOSListByID: {
          list:
            Object.keys(action.payload).length === 0
              ? []
              : action?.payload?.data?.data,
          pagination:
            Object.keys(action.payload).length === 0
              ? []
              : action?.payload?.data?.pagination,
        },
      };
    case ActionTypes.GET_USERS_PATIENT_LIST_BY_ID:
      return {
        ...state,
        usersPatientListByID: {
          list:
            Object.keys(action.payload).length === 0
              ? []
              : action?.payload?.data?.data,
          pagination:
            Object.keys(action.payload).length === 0
              ? []
              : action?.payload?.data?.pagination,
        },
      };
    case ActionTypes.GET_ALL_PRODUCT_LIST:
      return {
        ...state,
        productListAll:
          Object.keys(action.payload).length === 0 ||
          action.payload.data.data === undefined
            ? []
            : action.payload.data.data,
      };
    case ActionTypes.GET_ALL_USER_MEMBER_LIST:
      return {
        ...state,
        userMemberListAll:
          Object.keys(action.payload).length === 0 ||
          action.payload.data.data === undefined
            ? []
            : action.payload.data.data,
      };
    case ActionTypes.GET_ALL_BRANDS_LIST:
      return {
        ...state,
        brandlistall:
          Object.keys(action.payload).length === 0
            ? []
            : action?.payload?.data?.data,
      };
    case ActionTypes.GET_MEMBER_LIST_BY_USER_ID:
      return {
        ...state,
        userMemberListByIDall:
          Object.keys(action.payload).length === 0
            ? []
            : action?.payload?.data?.data,
      };
    case ActionTypes.GET_ALERT_LIST_BY_USER_ID:
      return {
        ...state,
        userAlertListByIDall:
          Object.keys(action.payload).length === 0
            ? []
            : action?.payload?.data?.data,
      };
    case ActionTypes.GET_SOS_LIST_BY_USER_ID:
      return {
        ...state,
        userSOSListByIDall:
          Object.keys(action.payload).length === 0
            ? []
            : action?.payload?.data?.data,
      };
    case ActionTypes.GET_PATIENT_LIST_BY_USER_ID:
      return {
        ...state,
        userPatientListByIDall:
          Object.keys(action.payload).length === 0
            ? []
            : action?.payload?.data?.data,
      };
    case ActionTypes.GET_ALL_MODEL_LIST:
      return {
        ...state,
        modellistall:
          Object.keys(action.payload).length === 0
            ? []
            : action.payload?.data?.data,
      };
    case ActionTypes.SELECT_BRAND_GET_MODEL_LIST:
      return {
        ...state,
        selectedBrandWiseModel:
          Object.keys(action.payload).length === 0
            ? []
            : action.payload.data.data,
      };
    case ActionTypes.ADD_NEW_BRAND:
      const OldBrandlist = [...state.brandList.list];
      const OldAllBrandList = [...state.brandlistall];
      OldBrandlist.unshift(action.payload.data);
      OldAllBrandList.unshift(action.payload.data);
      return {
        ...state,
        brandList: {
          list: OldBrandlist,
        },
        brandlistall: OldAllBrandList,
      };
    case ActionTypes.EDIT_BRAND:
      const OldBrandlistx = [...state.brandList.list];
      const UpdateBrandList = OldBrandlistx.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            name: action.payload.name,
            is_active: action.payload.is_active,
          };
        } else return elm;
      });
      return {
        ...state,
        brandList: {
          list: UpdateBrandList,
        },
      };
    case ActionTypes.DELETE_BRAND:
      const OldBrandListz = [...state.brandList.list];
      const NewUpdatedBrandList = [];
      OldBrandListz &&
        OldBrandListz?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdatedBrandList.push(elm);
          }
        });
      return {
        ...state,
        brandList: {
          list: NewUpdatedBrandList,
        },
      };
    case ActionTypes.GET_BRAND_MODELS_LIST:
      return {
        ...state,
        brandmodelsList: {
          list:
            action.payload.data.data === undefined
              ? []
              : action.payload.data.data,
          pagination:
            action.payload.pagination === undefined
              ? []
              : action.payload.pagination,
        },
      };
    case ActionTypes.GET_MODEL_LIST:
      return {
        ...state,
        modelList: {
          list:
            Object.keys(action.payload).length === 0
              ? []
              : action.payload.data.data,
          pagination:
            Object.keys(action.payload).length === 0
              ? []
              : action.payload.data.pagination,
        },
      };
    case ActionTypes.ADD_NEW_MODEL:
      const OldModellist = [...state.modelList.list];
      OldModellist.unshift(action.payload.data);
      const OldSelectBrandWiseModel = [...state.selectedBrandWiseModel];
      OldSelectBrandWiseModel.unshift(action.payload.data);
      return {
        ...state,
        modelList: {
          list: OldModellist,
        },
        selectedBrandWiseModel: OldSelectBrandWiseModel,
      };
    case ActionTypes.EDIT_MODEL:
      const OldModellistx = [...state.modelList.list];
      const UpdateModelList = OldModellistx.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            name: action.payload.name,
            brand_id: action.payload.brand_id,
            is_active: action.payload.is_active,
            added_by: action.payload.added_by,
          };
        } else return elm;
      });
      return {
        ...state,
        modelList: {
          list: UpdateModelList,
        },
      };
    case ActionTypes.DELETE_MODEL:
      const OldModelListz = [...state.modelList.list];
      const NewUpdatedModelList = [];
      OldModelListz &&
        OldModelListz?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdatedModelList.push(elm);
          }
        });
      return {
        ...state,
        modelList: {
          list: NewUpdatedModelList,
        },
      };
    case ActionTypes.GET_PERMISSION_LIST:
      return {
        ...state,
        permissionList: action.payload,
      };
    case ActionTypes.GET_ALL_SUPPLIERS_LIST:
      return {
        ...state,
        supplierListall:
          Object.keys(action.payload).length === 0
            ? []
            : action.payload?.data?.data,
      };
    case ActionTypes.GET_SUPPLIER_LIST:
      return {
        ...state,
        supplierList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.ADD_SUPPLIER_LIST:
      const OldSupplierlist = [...state.supplierList.list];
      OldSupplierlist.unshift(action.payload.data);
      const OldSupplierlistAll = [...state.supplierListall];
      OldSupplierlistAll.unshift(action.payload.data);
      return {
        ...state,
        supplierList: {
          list: OldSupplierlist,
        },
        supplierListall: OldSupplierlistAll,
      };
    case ActionTypes.UPDATE_SUPPLIER_LIST:
      const OldSupplierlistx = [...state.supplierList.list];
      const UpdateSupplierList = OldSupplierlistx.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            title: action.payload.title,
            website: action.payload.website,
            is_active: action.payload.is_active,
            name: action.payload.name,
            email: action.payload.email,
            phone_code: action.payload.phone_code,
            phone: action.payload.phone,
            company: action.payload.company,
            address: action.payload.address,
            vat_registration_no: action.payload.vat_registration_no,
            registration_no: action.payload.registration_no,
            contacts: action.payload.contacts,
          };
        } else return elm;
      });
      return {
        ...state,
        supplierList: {
          list: UpdateSupplierList,
        },
      };
    case ActionTypes.DELETE_SUPPLIER:
      const OldSupplierListz = [...state.supplierList.list];
      const NewUpdatedSupplierList = [];
      OldSupplierListz &&
        OldSupplierListz?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdatedSupplierList.push(elm);
          }
        });
      return {
        ...state,
        supplierList: {
          list: NewUpdatedSupplierList,
        },
      };
    case ActionTypes.GET_SUPPLIER_DETAILS:
      return {
        ...state,
        supplierdetails: action.payload,
      };
    case ActionTypes.GET_PRODUCT_LIST:
      return {
        ...state,
        productList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.GET_USER_MEMBER_LIST:
      return {
        ...state,
        userMemberList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.ADD_PRODUCT_LIST:
      const OldProductlist = [...state.productList.list];
      OldProductlist.unshift(action.payload);
      return {
        ...state,
        productList: {
          list: OldProductlist,
        },
      };
    case ActionTypes.ADD_USER_MEMBER_LIST:
      const OlduserMemberlist = [...state.userMemberList.list];
      OlduserMemberlist.unshift(action.payload);
      return {
        ...state,
        userMemberList: {
          list: OlduserMemberlist,
        },
      };
    case ActionTypes.UPDATE_PRODUCT_LIST:
      const OldProductlistx = [...state.productList.list];
      const UpdateProductList = OldProductlistx.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            pi_no: action.payload.pi_no,
            name: action.payload.name,
            brand: action.payload.brand,
            model: action.payload.model,
            quantity: action.payload.quantity,
            supplier: action.payload.supplier,
            item_code: action.payload.item_code,
            currency: action.payload.currency,
            unit_cost_price: action.payload.unit_cost_price,
            incoterm: action.payload.incoterm,
            hs_code: action.payload.hs_code,
            dimensions: action.payload.dimensions,
            floor: action.payload.floor,
            ps: action.payload.ps,
            date: action.payload.date,
            client_code: action.payload.client_code,
            pc_code: action.payload.pc_code,
            description_french: action.payload.description_french,
            description_english: action.payload.description_english,
            department: action.payload.department,
            unit: action.payload.unit,
            ucp_usd: action.payload.ucp_usd,
            tcp_usd: action.payload.tcp_usd,
            usp_usd: action.payload.usp_usd,
            tsp_usd: action.payload.tsp_usd,
            lot_number: action.payload.lot_number,
            brochure: action.payload.brochure,
            remarks: action.payload.remarks,
            country_of_origin: action.payload.country_of_origin,
            catalog: action.payload.catalog,
            cs: action.payload.cs,
            ce: action.payload.ce,
            iso: action.payload.iso,
            maf: action.payload.maf,
            warranty: action.payload.warranty,
            lead_time: action.payload.lead_time,
            category: action.payload.category,
            fator: action.payload.fator,
            is_active: action.payload.is_active,
            view_thumbnail_url: action.payload.view_thumbnail_url,
            view_file_url: action.payload.view_file_url,
            meta_field: action.payload.meta_field,
          };
        } else return elm;
      });
      return {
        ...state,
        productList: {
          list: UpdateProductList,
        },
      };
    case ActionTypes.UPDATE_USER_MEMBER_LIST:
      const OlduserMemberlistx = [...state.userMemberList.list];
      const UpdateuserMemberList = OlduserMemberlistx.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            pi_no: action.payload.pi_no,
            name: action.payload.name,
            brand: action.payload.brand,
            model: action.payload.model,
            quantity: action.payload.quantity,
            supplier: action.payload.supplier,
            item_code: action.payload.item_code,
            currency: action.payload.currency,
            unit_cost_price: action.payload.unit_cost_price,
            incoterm: action.payload.incoterm,
            hs_code: action.payload.hs_code,
            dimensions: action.payload.dimensions,
            floor: action.payload.floor,
            ps: action.payload.ps,
            date: action.payload.date,
            client_code: action.payload.client_code,
            pc_code: action.payload.pc_code,
            description_french: action.payload.description_french,
            description_english: action.payload.description_english,
            department: action.payload.department,
            unit: action.payload.unit,
            ucp_usd: action.payload.ucp_usd,
            tcp_usd: action.payload.tcp_usd,
            usp_usd: action.payload.usp_usd,
            tsp_usd: action.payload.tsp_usd,
            lot_number: action.payload.lot_number,
            brochure: action.payload.brochure,
            remarks: action.payload.remarks,
            country_of_origin: action.payload.country_of_origin,
            catalog: action.payload.catalog,
            cs: action.payload.cs,
            ce: action.payload.ce,
            iso: action.payload.iso,
            maf: action.payload.maf,
            warranty: action.payload.warranty,
            lead_time: action.payload.lead_time,
            category: action.payload.category,
            fator: action.payload.fator,
            is_active: action.payload.is_active,
            view_thumbnail_url: action.payload.view_thumbnail_url,
            view_file_url: action.payload.view_file_url,
            meta_field: action.payload.meta_field,
          };
        } else return elm;
      });
      return {
        ...state,
        userMemberList: {
          list: UpdateuserMemberList,
        },
      };
    case ActionTypes.DELETE_PRODUCT:
      const OldProductListz = [...state.productList.list];
      const NewUpdatedProductList = [];
      OldProductListz &&
        OldProductListz?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdatedProductList.push(elm);
          }
        });
      return {
        ...state,
        productList: {
          list: NewUpdatedProductList,
        },
      };
    case ActionTypes.DELETE_USER_MEMBER:
      const OlduserMemberListz = [...state.userMemberList.list];
      const NewUpdateduserMemberList = [];
      OlduserMemberListz &&
        OlduserMemberListz?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdateduserMemberList.push(elm);
          }
        });
      return {
        ...state,
        userMemberList: {
          list: NewUpdateduserMemberList,
          pagination:{...state.userMemberList.pagination}
        },
      };
    case ActionTypes.VIEW_PRODUCT_DETAILS:
      return {
        ...state,
        viewProduct: action.payload,
      };
    case ActionTypes.GET_CATEGORY_LIST:
      return {
        ...state,
        alertList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.GET_ALL_CATEGORY_LIST:
      return {
        ...state,
        categoryAllList:
          Object.keys(action.payload).length === 0 ||
          action.payload.data.data === undefined
            ? []
            : action.payload.data.data,
      };
    case ActionTypes.ADD_CATEGORY_LIST:
      const OldalertList = [...state.alertList.list];
      OldalertList.unshift(action.payload);
      return {
        ...state,
        alertList: {
          list: OldalertList,
        },
      };
    case ActionTypes.UPDATE_CATEGORY_LIST:
      const OldalertListx = [...state.alertList.list];
      const UpdatealertList = OldalertListx.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            name: action.payload.name,
            is_active: action.payload.is_active,
          };
        } else return elm;
      });
      return {
        ...state,
        alertList: {
          list: UpdatealertList,
        },
      };
    case ActionTypes.DELETE_CATEGORY_LIST:
      const OldalertListz = [...state.productList.list];
      const NewUpdatedalertList = [];
      OldalertListz &&
        OldalertListz?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdatedalertList.push(elm);
          }
        });
      return {
        ...state,
        alertList: {
          list: NewUpdatedalertList,
        },
      };
    case ActionTypes.GET_SETTINGS_LIST:
      const ObjectSettingsListId = {};
      action.payload?.data?.data.map((elm) => {
        ObjectSettingsListId[elm?.slug] = elm;
      });

      return {
        ...state,
        sosList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
        settingsObjectIdWise: ObjectSettingsListId,
      };
    case ActionTypes.ADD_SETTINGS_LIST:
      const OldHomeTabSOSList = [...state.sosList.list];
      OldHomeTabSOSList.unshift(action.payload);
      return {
        ...state,
        sosList: {
          list: OldHomeTabSOSList,
        },
      };
    case ActionTypes.UPDATE_CATEGORY_LIST:
      const OldHomeTabSOSListx = [...state.sosList.list];
      const UpdateHomeTabSOSList = OldHomeTabSOSListx.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            name: action.payload.name,
          };
        } else return elm;
      });
      return {
        ...state,
        sosList: {
          list: UpdateHomeTabSOSList,
        },
      };
    case ActionTypes.DELETE_SETTINGS_LIST:
      const OldsosListListz = [...state.sosList.list];
      const NewUpdatedsosListList = [];
      OldsosListListz &&
        OldsosListListz?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdatedsosListList.push(elm);
          }
        });
      return {
        ...state,
        sosList: {
          list: NewUpdatedsosListList,
        },
      };

    case ActionTypes.GET_ALL_FAQ_LIST:
      return {
        ...state,
        faqListAll:
          Object.keys(action.payload).length === 0 ||
          action.payload.data.data === undefined
            ? []
            : action.payload.data.data,
      };
    case ActionTypes.GET_FAQ_LIST:
      return {
        ...state,
        faqList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.ADD_FAQ_LIST:
      const OldFAQlist = [...state.faqList.list];
      OldFAQlist.unshift(action.payload);
      return {
        ...state,
        documentList: {
          list: OldFAQlist,
        },
      };
    case ActionTypes.UPDATE_FAQ_LIST:
      const oldFAQ = [...state.faqList.list];
      const UpdatedFAQ = oldFAQ.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            name: action.payload.name,
          };
        } else return elm;
      });
      return {
        ...state,
        documentList: {
          list: UpdatedFAQ,
        },
      };
    case ActionTypes.DELETE_FAQ:
      const oldFAQBfore = [...state.faqList.list];
      const NewFaqFiltereddelete = [];
      oldFAQBfore &&
        oldFAQBfore?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewFaqFiltereddelete.push(elm);
          }
        });
      return {
        ...state,
        faqList: {
          list: NewFaqFiltereddelete,
        },
      };
    case ActionTypes.ADMIN_LOGOUT:
      return {
        ...initailData,
      };
    default:
      return state;
  }
};
