import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getRelationList,
  editSelfDetails,
  getPermissionList,
  deleteHomeTabSOSList,
  addalertList,
  getAllFAQList,
  getFAQList,
  UpdateFAQList,
  addFAQList,
  deleteFAQ,
  getHomeTabSOSList,
  addHomeTabSOSList,
  updateHomeTabSOSList,
  ClearFormSetFutios,
  editUserList,
  getAllalertList,
  getSelfDetails,
  deletealertList,
  addNewBrand,
  updatealertList,
  getAllUserMemberList,
  getAllProductList,
  UpdateUserMemberList,
  UpdateProductList,
  deleteUserMember,
  deleteProduct,
  getSupplierAllList,
  LoaderStartMain,
  deleteSupplier,
  addUserMemberList,
  addProductList,
  UpdateSupplierList,
  getSupplierDetails,
  getAllModelList,
  editBrand,
  getAllMemberListByUserIDList,
  getAllBrandList,
  getBrandList,
  deleteBrand,
  deleteModel,
  getModelList,
  addNewModel,
  editModel,
  GetBrandModels,
  getSupplierList,
  getUserMemberList,
  getProductList,
  addSupplierList,
  getBrandsWiseModelList,
  ViewProjectDetails,
  getalertList,
  getAllAlertListByUserIDList,
  getUsersMemberListByID,
  getUsersAlertListByID,
  getMemberListByPatientID,
  getAllSOSListByUserIDList,
  getUsersPatientListByID,
  getUsersSOSListByID,
  getAllPatientListByUserIDList,
} from "../../redux/actions/adminActions";

export const ws_response = ({ evt, ws }) => {
  return async (dispatch, getState) => {
    const ws_onmessage = JSON.parse(evt.data);
    const { device_id, user_id } = getState()?.adminReducers;
    // console.log("ws_onmessage", ws_onmessage);
    // Sw notification setup
    switch (ws_onmessage.url) {
      case "profile_update":
        if (ws_onmessage?.response?.status === true) {
          dispatch(editSelfDetails(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "profile_update",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "profile_update",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "permission_list":
        dispatch(getPermissionList(ws_onmessage?.response));
        break;
      case "product_brand_list":
        dispatch(getAllBrandList(ws_onmessage?.response));
        dispatch(getBrandList(ws_onmessage?.response));
        break;
      case "user_member_list":
        dispatch(getAllMemberListByUserIDList(ws_onmessage?.response));
        dispatch(getUsersMemberListByID(ws_onmessage?.response));
        break;
      case "user_alert_list":
        dispatch(getAllAlertListByUserIDList(ws_onmessage?.response));
        dispatch(getUsersAlertListByID(ws_onmessage?.response));
        break;
      case "member_by_patient":
        dispatch(getMemberListByPatientID(ws_onmessage?.response));
        break;
      case "user_sos_list":
        dispatch(getAllSOSListByUserIDList(ws_onmessage?.response));
        dispatch(getUsersSOSListByID(ws_onmessage?.response));
        break;
      case "user_patient_list":
        dispatch(getAllPatientListByUserIDList(ws_onmessage?.response));
        dispatch(getUsersPatientListByID(ws_onmessage?.response));
        break;
      case "add_product_brand":
        if (ws_onmessage?.response?.status === true) {
          dispatch(addNewBrand(ws_onmessage?.response));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "add_product_brand",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "add_product_brand",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "update_user":
        if (ws_onmessage?.response?.status === true) {
          dispatch(editUserList(ws_onmessage?.response?.data));
          if (ws_onmessage?.response?.data?.id === user_id) {
            dispatch(getSelfDetails(ws_onmessage?.response?.data));
          }
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "update_user",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "update_user",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "update_product_brand":
        if (ws_onmessage?.response?.status === true) {
          dispatch(editBrand(ws_onmessage?.response));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "update_product_brand",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "update_product_brand",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "delete_product_brand":
        if (ws_onmessage?.response?.status === true) {
          dispatch(deleteBrand(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.response?.brand_exist_in_model) {
            toast.error(ws_onmessage?.response?.msg);
          } else {
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "product_model_list":
        dispatch(getAllModelList(ws_onmessage?.response));
        dispatch(getModelList(ws_onmessage?.response));
        break;
      case "add_product_model":
        if (ws_onmessage?.response?.status === true) {
          dispatch(addNewModel(ws_onmessage?.response));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "add_product_model",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "add_product_model",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "update_product_model":
        if (ws_onmessage?.response?.status === true) {
          dispatch(editModel(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "update_product_model",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "update_product_model",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "delete_product_model":
        if (ws_onmessage?.response?.status === true) {
          dispatch(deleteModel(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "brand_model_list":
        if (ws_onmessage?.response?.status === true) {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(LoaderStartMain(true));
            dispatch(getBrandsWiseModelList(ws_onmessage?.response));
            dispatch(GetBrandModels(ws_onmessage?.response));
            setTimeout(() => {
              dispatch(LoaderStartMain(false));
            }, 2000);
          } else {
            dispatch(getBrandsWiseModelList(ws_onmessage?.response));
            dispatch(GetBrandModels(ws_onmessage?.response));
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(LoaderStartMain(true));
            dispatch(getBrandsWiseModelList(ws_onmessage?.response));
            dispatch(GetBrandModels(ws_onmessage?.response));
            setTimeout(() => {
              dispatch(LoaderStartMain(false));
            }, 2000);
          } else {
            dispatch(getBrandsWiseModelList(ws_onmessage?.response));
            dispatch(GetBrandModels(ws_onmessage?.response));
          }
        }
        break;
      case "supplier_list":
        dispatch(getSupplierAllList(ws_onmessage?.response));
        dispatch(getSupplierList(ws_onmessage?.response));
        break;
      case "add_supplier":
        if (ws_onmessage?.response?.status) {
          dispatch(addSupplierList(ws_onmessage?.response));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "add_supplier",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "add_supplier",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "supplier_detail":
        if (ws_onmessage?.response?.status) {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(LoaderStartMain(true));
            dispatch(getSupplierDetails(ws_onmessage?.response?.data));
            setTimeout(() => {
              dispatch(LoaderStartMain(false));
            }, 2000);
          }
        } else {
          dispatch(getSupplierDetails({}));
        }
        break;
      case "update_supplier":
        if (ws_onmessage?.response?.status) {
          dispatch(UpdateSupplierList(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "update_supplier",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "update_supplier",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "delete_supplier":
        if (ws_onmessage?.response?.status === true) {
          dispatch(deleteSupplier(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "patient_list":
        if (ws_onmessage?.request?.DeviceId === device_id) {
          dispatch(getAllProductList(ws_onmessage?.response));
          dispatch(getProductList(ws_onmessage?.response));
        }
        break;
      case "member_list":
        if (ws_onmessage?.request?.DeviceId === device_id) {
          dispatch(getAllUserMemberList(ws_onmessage?.response));
          dispatch(getUserMemberList(ws_onmessage?.response));
        }
        break;
      case "add_patient":
        if (ws_onmessage?.response?.status) {
          dispatch(addProductList(ws_onmessage?.response));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "add_product",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "add_product",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "add_user_member":
        if (ws_onmessage?.response?.status) {
          dispatch(addUserMemberList(ws_onmessage?.response));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "add_user_member",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "add_user_member",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "update_patient":
        if (ws_onmessage?.response?.status) {
          dispatch(UpdateProductList(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "update_product",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "update_product",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "update_user_member":
        if (ws_onmessage?.response?.status) {
          dispatch(UpdateUserMemberList(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "update_user_member",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "update_user_member",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "get_patient_detail":
        if (ws_onmessage?.response?.status) {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(LoaderStartMain(true));
            dispatch(ViewProjectDetails(ws_onmessage?.response?.data));
            setTimeout(() => {
              dispatch(LoaderStartMain(false));
            }, 3000);
          }
        } else {
          dispatch(getSupplierDetails({}));
        }
        break;
      case "user_detail":
        if (ws_onmessage?.response?.status) {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(LoaderStartMain(true));
            dispatch(ViewProjectDetails(ws_onmessage?.response?.data));
            setTimeout(() => {
              dispatch(LoaderStartMain(false));
            }, 3000);
          }
        } else {
          dispatch(getSupplierDetails({}));
        }
        break;
      case "get_user_detail":
        if (ws_onmessage?.response?.status) {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(LoaderStartMain(true));
            dispatch(ViewProjectDetails(ws_onmessage?.response?.data));
            setTimeout(() => {
              dispatch(LoaderStartMain(false));
            }, 3000);
          }
        } else {
          dispatch(getSupplierDetails({}));
        }
        break;
      case "delete_patient":
        if (ws_onmessage?.response?.status) {
          dispatch(deleteProduct(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "delete_member":
        if (ws_onmessage?.response?.status) {
          dispatch(deleteUserMember(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "delete_user_member":
        if (ws_onmessage?.response?.status) {
          dispatch(deleteUserMember(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "alert_list":
        dispatch(getAllalertList(ws_onmessage?.response));
        dispatch(getalertList(ws_onmessage?.response));
        break;
      case "add_alert":
        if (ws_onmessage?.response?.status === true) {
          dispatch(addalertList(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "add_alert",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "add_alert",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "update_alert":
        if (ws_onmessage?.response?.status === true) {
          dispatch(updatealertList(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "update_alert",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "update_alert",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "delete_alert":
        if (ws_onmessage?.response?.status === true) {
          dispatch(deletealertList(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "sos_list":
        if (ws_onmessage?.response?.status === true) {
          dispatch(getHomeTabSOSList(ws_onmessage?.response));
        }
        break;
      case "add_sos":
        if (ws_onmessage?.response?.status) {
          dispatch(addHomeTabSOSList(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "add_sos",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "add_sos",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "update_sos":
        if (ws_onmessage?.response?.status === true) {
          dispatch(updateHomeTabSOSList(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "update_sos",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "update_sos",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "delete_sos":
        if (ws_onmessage?.response?.status === true) {
          dispatch(deleteHomeTabSOSList(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "relations":
        if (ws_onmessage?.request?.DeviceId === device_id) {
          dispatch(getRelationList(ws_onmessage?.response));
        }
        break;
      case "faq_list":
        if (ws_onmessage?.request?.DeviceId === device_id) {
          dispatch(getAllFAQList(ws_onmessage?.response));
          dispatch(getFAQList(ws_onmessage?.response));
        }
        break;
      case "add_faq":
        if (ws_onmessage?.response?.status) {
          dispatch(addFAQList(ws_onmessage?.response));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "add_faq",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "add_faq",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "update_faq":
        if (ws_onmessage?.response?.status) {
          dispatch(UpdateFAQList(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "update_faq",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "update_faq",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "faq_detail":
        if (ws_onmessage?.response?.status) {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(LoaderStartMain(true));
            dispatch(ViewProjectDetails(ws_onmessage?.response?.data));
            setTimeout(() => {
              dispatch(LoaderStartMain(false));
            }, 3000);
          }
        } else {
          dispatch(getSupplierDetails({}));
        }
        break;
      case "delete_faq":
        if (ws_onmessage?.response?.status) {
          dispatch(deleteFAQ(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            toast.success(
              ws_onmessage?.response?.msg || "FAQ deleted successfully"
            );
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      default:
        return;
    }
  };
};
