export const LOGIN_URL = "/login";
export const FORGOT_PASSWORD_URL = "/forgot-password";
export const RESET_PASSWORD_URL = "/reset-password";
export const DASHBOARD_URL = "/dashboard";
export const USER_MANAGEMENT_URL = "/user-management";
export const ADD_USER_URL = "/add-user";
export const EDIT_USER_URL = "/edit-user";
export const VIEW_PROFILE_URL = "/profile";
export const MEMBER_MANAGEMENT_URL = "/patient-management";
export const ADD_MEMBER_MANAGEMENT_URL = "/add-patient";
export const EDIT_MEMBER_MANAGEMENT_URL = "/edit-patient";
export const VIEW_MEMBER_MANAGEMENT_URL = "/view-patient";
export const VIEW_USER_MANAGEMENT_URL="/view-user"
export const REVENUE_MANAGEMENT_URL = "/revenue-management";
export const ADD_REVENUE_MANAGEMENT_URL = "/add-revenue";
export const EDIT_REVENUE_MANAGEMENT_URL = "/edit-revenue";
export const VIEW_REVENUE_MANAGEMENT_URL = "/view-revenue";
export const CATEGORY_MANAGEMENT_URL = "/alert-management";
export const SOS_MANAGEMENT_URL = "/sos-management";
export const ALERT_MANAGEMENT_URL = "/alert-management";
export const FAQ_MANAGEMENT_URL="/faq-management";
export const ADD_FAQ_MANAGEMENT_URL = "/add-faq";
export const EDIT_FAQ_MANAGEMENT_URL = "/edit-faq";
export const VIEW_FAQ_MANAGEMENT_URL = "/view-faq";

// Api login urls
export const API_URL = process.env.REACT_APP_BASE_URL;
export const LOGIN_API_URL = API_URL + "user/login";
export const LOGOUT_API_URL = API_URL + "user/logout";
export const GET_DASHBOARD_API_URL = API_URL + "admin/dashboard";
export const FORGOT_PASSWORD_API_URL = API_URL + "user/forgot-password";
export const VERIFY_OTP_API_URL = API_URL + "user/verify-otp";
export const CHANGE_PASSWORD_API_URL = API_URL + "user/create-password"
export const CREATE_PASSWORD_API_URL = API_URL + "user/create-password";
export const ADMIN_ADD_USER_API_URL = API_URL + "admin/user/add";
export const ADMIN_UPDATE_USER_API_URL = API_URL + "admin/user/update";
export const ADMIN_GET_USER_LIST_API_URL = API_URL + "admin/user/list";
export const ADMIN_DELETE_USER_LIST_API_URL = API_URL + "admin/user/delete";

// upload file urls
export const UPLOAD_FILE_API_URL = API_URL + "file/upload";

// import member file urls
export const IPMPORT_PRODUCT_FILE_API_URL = API_URL + "file/import/member";

// export member urls
export const EXPORT_PRODUCT_FILE_API_URL = API_URL + "file/export/member";

//export relations array



  // common truncate string function
  export const truncateString = (str, maxLength) => {
    if (str.length <= maxLength) {
      return str;
    }
    return str.slice(0, maxLength) + "...";
  };
  