/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { WebSocketContext } from "../../App";
import wsSend_request from "../../Api/ws/ws_request";
import { USER_MANAGEMENT_URL } from "../Shared/constant";
import BackButton from "../Common/BackButton";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import CheckValid from "../Common/CheckValid";
import InputField from "../Common/InputField";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import CountrySelectInput from "../Common/CountrySelectInput/CountryMobileInput";
import CreateUserAPI from "../../Api/CreateUserAPI";
import UpdateUserAPI from "../../Api/UpdateUserAPI";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import CommonLogout from "../Common/CommonLogout";
import Multiselect from "multiselect-react-dropdown";
import { addUserList, editUserList } from "../../redux/actions/adminActions";
import BtnLoader from "../Common/BtnLoader";
import {
  parsePhoneNumber,
  getRegionCodeForCountryCode,
} from "awesome-phonenumber";
import AvatarGet from "../Common/AvatarGet";
import { OverlayTrigger, Tooltip, Spinner, Image } from "react-bootstrap";
import UploadFileAPI from "../../Api/UploadFileAPI";
import ViewPatient from "../PatientMangement/ViewPatientt";

const AddEditUser = () => {
  const Params = useParams();
  const Dispatch = useDispatch();
  const Location = useLocation()?.pathname?.split("/")[1];
  const {
    accessToken,
    permissionList,
    userList,
    device_id,
    viewProduct,
    relationsArray,
  } = useSelector((state) => state.adminReducers);
  const GetCurrentUserSelected = userList.filter(
    (user) => user?.id === Params?.roomId
  )[0];
  const Navigate = useNavigate();
  const [userRelation, setUserRelation] = useState(viewProduct?.relation);
  const [AvatarImage, setAvatarImage] = useState("");
  const [AvatarImageId, setAvatarImageId] = useState("");
  const [UploadFileLoader, setUploadFileLoader] = useState(false);

  const [inputValue, setInputValue] = useState({
    Email: "",
    username: "",
    is_admin: "",
    Password: "",
    id: "",
    phone_code: "",
    phone: "",
    avatar: "",
    relation: "",
    parent_id: "",
    is_registered: "",
    created_at: "",
    updated_at: "",
  });
  const {
    Email,
    username,
    Password,
    id,
    phone_code,
    phone,
    avatar,
    relation,
    parent_id,
    is_admin,
    is_registered,
    created_at,
    updated_at,
  } = inputValue;
  const [PhoneNumber, setPhoneNumber] = useState({
    phone_code: "1",
    phone: "",
  });
  const [permissionGet, setPermissionGet] = useState("");
  const [showpassword, setshowpassword] = useState(false);
  const { websocket } = useContext(WebSocketContext);
  const [selectedValueUser, setselectedValueUser] = useState("");
  const [loader, setLoader] = useState(false);

  // error message
  const [errorEmail, setErrorEmail] = useState("");
  const [errorusername, setErrorusername] = useState("");
  const [erroris_admin, setErroris_admin] = useState("");
  const [errorRelation, setErrorRealtion] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorPermission, setErrorPermission] = useState("");

  // Regex
  const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
  const PasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/; // eslint-disable-line

  // upload product image
  const UploadImage = async (e) => {
    setUploadFileLoader(true);
    const files = e.target.files[0];
    const formData = new FormData();
    formData.append("file", files, files.name);
    const fileresponse = await UploadFileAPI(accessToken, formData);
    if (fileresponse) {
      setUploadFileLoader(false);
      setAvatarImage(fileresponse?.view_thumbnail_url);
      setAvatarImageId(fileresponse?.id);
    }
  };
  // remove image uploaded
  const RemoveUploadImage = () => {
    setAvatarImage("");
    setAvatarImageId("");
  };
  // clear form
  const ClearForm = () => {
    setInputValue({
      ...inputValue,
    });
    setAvatarImage("");
    setAvatarImageId("");
  };
  // get product details
  useEffect(() => {
    if (Location === "edit-user") {
      if (Params?.roomId) {
        let param = {
          transmit: "single",
          url: "user_detail",
          request: {
            user_id: Params?.roomId,
            DeviceId: device_id,
          },
        };
        wsSend_request(websocket, param);
      }
    }
  }, [Params?.roomId, Location]);
  useEffect(() => {
    if (GetCurrentUserSelected !== undefined) {
      setInputValue({
        ...inputValue,
        Email: GetCurrentUserSelected?.email,
        username: GetCurrentUserSelected?.first_name,
        is_admin: GetCurrentUserSelected?.last_name,
        Address: GetCurrentUserSelected?.address,
      });
      setPhoneNumber({
        ...PhoneNumber,
        phone_code: GetCurrentUserSelected?.phone_code,
        phone: GetCurrentUserSelected?.phone,
      });
      setPermissionGet(
        GetCurrentUserSelected?.permissions === null
          ? ""
          : GetCurrentUserSelected?.permissions
      );
      setselectedValueUser(
        GetCurrentUserSelected?.permissions === null
          ? ""
          : GetCurrentUserSelected?.permissions.split(",")
      );
    }
  }, [GetCurrentUserSelected]);
  useEffect(() => {
    if (Location === "edit-user") {
      if (viewProduct) {
        setAvatarImage(
          viewProduct?.avatar === null ? "" : viewProduct?.view_thumbnail_url
        );
      }
      setInputValue({
        username: viewProduct?.username,
        Email: viewProduct?.email,
        relation: inputValue?.relation,
      });
      setAvatarImageId(viewProduct?.avatar);
      setPhoneNumber({
        ...PhoneNumber,
        phone_code: viewProduct?.phone_code,
        phone: viewProduct?.phone,
      });
      setUserRelation(viewProduct?.relation);
    }
  }, [viewProduct]);
  useEffect(() => {
    if (accessToken) {
      let param = {
        transmit: "broadcast",
        url: "permission_list",
      };
      wsSend_request(websocket, param);
    }
  }, [accessToken]);

  // Textfield common onchange function
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setInputValue((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // show password
  const ShowHidePassword = () => {
    setshowpassword(!showpassword);
  };

  // select permsissions
  const PermissionAddID = [];
  const onSelectUser = (selectedList, selectedItem) => {
    selectedList.filter((elm) => {
      if (
        elm === "add-user" ||
        elm === "update-user" ||
        elm === "delete-user"
      ) {
        return PermissionAddID.push(elm, "user-list");
      } else if (elm === "add-product" || elm === "update-product") {
        return PermissionAddID.push(
          elm,
          "product-list",
          "model-list",
          "add-model",
          "brand-list",
          "add-brand"
        );
      } else if (
        elm === "add-model" ||
        elm === "update-model" ||
        elm === "delete-model"
      ) {
        return PermissionAddID.push(elm, "model-list");
      } else if (elm === "delete-product") {
        return PermissionAddID.push(elm, "product-list");
      } else if (
        elm === "add-brand" ||
        elm === "update-brand" ||
        elm === "delete-brand"
      ) {
        return PermissionAddID.push(elm, "brand-list");
      } else if (
        elm === "add-supplier" ||
        elm === "update-supplier" ||
        elm === "delete-supplier"
      ) {
        return PermissionAddID.push(elm, "supplier-list");
      } else {
        return PermissionAddID.push(elm);
      }
    });
    var unique = PermissionAddID.filter(onlyUnique);
    function onlyUnique(value, index, array) {
      return array.indexOf(value) === index;
    }
    setselectedValueUser(unique);
    setPermissionGet(unique.toString());
  };

  // remove permsissions
  const onRemoveUser = (selectedList, removedItem) => {
    selectedList.filter((elm) => {
      PermissionAddID.push(elm);
    });
    setselectedValueUser(selectedList);
    setPermissionGet(PermissionAddID.toString());
  };

  const CountryStates = getRegionCodeForCountryCode(PhoneNumber?.phone_code);
  const CheckMobileNumber = parsePhoneNumber(PhoneNumber?.phone?.toString(), {
    regionCode: CountryStates,
  });

  // edit profile
  const AddUserFunct = async () => {
    if (Location === "edit-user") {
      // if(inputValue?.Password?.length > 0 && inputValue?.Email !== "") {
      //     if(PasswordRegex.test(inputValue?.Password) === true && EmailRegex.test(inputValue?.Email) === true) {
      //         let param = {
      //             "transmit": "broadcast",
      //             "url": "update_user",
      //             "request":{
      //                 "staff_id": GetCurrentUserSelected?.id,
      //                 "first_name": inputValue?.username,
      //                 "last_name": inputValue?.is_admin,
      //                 "phone_code": PhoneNumber?.phone_code,
      //                 "phone": PhoneNumber?.phone,
      //                 "email": inputValue?.Email,
      //                 "address": inputValue?.Address,
      //                 "password": inputValue?.Password,
      //                 "permissions": permissionGet,
      //                 "DeviceId" : device_id
      //             }
      //         };
      //         wsSend_request(websocket, param);
      //         setTimeout(() => {
      //             Navigate(USER_MANAGEMENT_URL);
      //             ResetForm();
      //         }, 200);
      //     } else {
      //         CheckValid(inputValue?.Password, {type: 'password', error: setErrorPassword });
      //     }
      // } else if(PhoneNumber?.phone !== "") {
      //     if(CheckMobileNumber?.valid === true) {
      //         if(inputValue?.Email !== "" || inputValue?.Password !== "" || inputValue?.username !== "" || inputValue?.is_admin !== "" || inputValue?.Address !== "" || permissionGet !== "") {
      //             if(EmailRegex.test(inputValue?.Email) === true || PasswordRegex.test(inputValue?.Password) === true) {
      //                 let param = {
      //                     "transmit": "broadcast",
      //                     "url": "update_user",
      //                     "request":{
      //                         "staff_id": GetCurrentUserSelected?.id,
      //                         "first_name": inputValue?.username,
      //                         "last_name": inputValue?.is_admin,
      //                         "phone_code": PhoneNumber?.phone_code,
      //                         "phone": PhoneNumber?.phone,
      //                         "email": inputValue?.Email,
      //                         "address": inputValue?.Address,
      //                         "password": inputValue?.Password,
      //                         "permissions": permissionGet,
      //                         "DeviceId" : device_id
      //                     }
      //                 };
      //                 wsSend_request(websocket, param);
      //                 setTimeout(() => {
      //                     Navigate(USER_MANAGEMENT_URL);
      //                     ResetForm();
      //                 }, 200);
      //             } else {
      //                 CheckValid(inputValue?.Email, {type: 'email', error: setErrorEmail });
      //                 CheckValid(inputValue?.Password, {type: 'password', error: setErrorPassword });
      //             }
      //         } else {
      //             CheckValid(inputValue?.username, {type: 'username', error: setErrorusername });
      //             CheckValid(inputValue?.is_admin, {type: 'is_admin', error: setErroris_admin });
      //             // CheckValid(inputValue?.Address, {type: 'Address', error: setErrorAddress });
      //             CheckValid(inputValue?.Email, {type: 'email', error: setErrorEmail });
      //             CheckValid(inputValue?.Password, {type: 'password', error: setErrorPassword });
      //             CheckValid(permissionGet, {type: 'permission', error: setErrorPermission });
      //         };
      //     } else {
      //         CheckValid(PhoneNumber, {type: 'phone', error: setErrorPhone });
      //     }
      // } else {
      //     if(inputValue?.Email !== "" || inputValue?.Password !== "" || inputValue?.username !== "" || inputValue?.is_admin !== "" || inputValue?.Address !== "" || permissionGet !== "") {
      //         if(EmailRegex.test(inputValue?.Email) === true || PasswordRegex.test(inputValue?.Password) === true) {
      //             let param = {
      //                 "transmit": "broadcast",
      //                 "url": "update_user",
      //                 "request":{
      //                     "staff_id": GetCurrentUserSelected?.id,
      //                     "first_name": inputValue?.username,
      //                     "last_name": inputValue?.is_admin,
      //                     "phone_code": PhoneNumber?.phone_code,
      //                     "phone": PhoneNumber?.phone,
      //                     "email": inputValue?.Email,
      //                     "address": inputValue?.Address,
      //                     "password": inputValue?.Password,
      //                     "permissions": permissionGet,
      //                     "DeviceId" : device_id
      //                 }
      //             };
      //             wsSend_request(websocket, param);
      //             setTimeout(() => {
      //                 Navigate(USER_MANAGEMENT_URL);
      //                 ResetForm();
      //             }, 200);
      //         } else {
      //             CheckValid(inputValue?.Email, {type: 'email', error: setErrorEmail });
      //             CheckValid(inputValue?.Password, {type: 'password', error: setErrorPassword });
      //         }
      //     } else {
      //         CheckValid(inputValue?.username, {type: 'username', error: setErrorusername });
      //         CheckValid(inputValue?.is_admin, {type: 'is_admin', error: setErroris_admin });
      //         // CheckValid(inputValue?.Address, {type: 'Address', error: setErrorAddress });
      //         CheckValid(inputValue?.Email, {type: 'email', error: setErrorEmail });
      //         CheckValid(inputValue?.Password, {type: 'password', error: setErrorPassword });
      //         CheckValid(permissionGet, {type: 'permission', error: setErrorPermission });
      //     };
      // }
      if (
        inputValue?.Email !== "" &&
        inputValue?.username !== "" &&
        PhoneNumber?.phone !== ""
      ) {
        if (
          EmailRegex.test(inputValue?.Email) === true &&
          CheckMobileNumber?.valid === true
        ) {
          let param = {
            transmit: "broadcast",
            url: "update_user",
            request: {
              user_id: Params?.roomId,
              username: inputValue?.username,
              phone_code: PhoneNumber?.phone_code,
              phone: PhoneNumber?.phone,
              email: inputValue?.Email,
              DeviceId: device_id,
              avatar: AvatarImageId === "" ? null : AvatarImageId,
              relation: userRelation,
              parent_id: null,
              // is_admin:inputValue?.is_admin ? "1" : "0",
              // is_registered:inputValue?.is_registered ? "1" : "0",
            },
          };
          wsSend_request(websocket, param);
          setTimeout(() => {
            Navigate(USER_MANAGEMENT_URL);
            ResetForm();
          }, 200);
        } else {
          CheckValid(inputValue?.Email, {
            type: "email",
            error: setErrorEmail,
          });
          CheckValid(PhoneNumber, { type: "phone", error: setErrorPhone });
        }
      } else {
        CheckValid(inputValue?.username, {
          type: "username",
          error: setErrorusername,
        });

        CheckValid(PhoneNumber, { type: "phone", error: setErrorPhone });
        CheckValid(inputValue?.Email, { type: "email", error: setErrorEmail });
        CheckValid(PhoneNumber, { type: "phone", error: setErrorPhone });
      }
    } else {
      if (
        inputValue?.Email !== "" &&
        inputValue?.username !== "" &&
        PhoneNumber?.phone !== ""
        // permissionGet !== ""
      ) {
        if (
          EmailRegex.test(inputValue?.Email) === true &&
          PasswordRegex.test(inputValue?.Password) === true &&
          CheckMobileNumber?.valid === true
        ) {
          setLoader(true);
          const jsonData = JSON.stringify({
            username: inputValue?.username,
            phone_code: PhoneNumber?.phone_code,
            phone: PhoneNumber?.phone,
            email: inputValue?.Email,
            address: inputValue?.Address,
            password: inputValue?.Password,
            permissions: permissionGet,
            avatar: AvatarImageId === "" ? null : AvatarImageId,
            relation: userRelation,
            parent_id: null,
            // is_admin:inputValue?.is_admin ? "1" : "0",
            // is_registered:inputValue?.is_registered ? "1" : "0",
          });
          const response = await CreateUserAPI(jsonData, accessToken);
          if (response?.status === 200) {
            setTimeout(() => {
              toast.success(response.msg);
              Dispatch(addUserList(response?.data));
              ResetForm();
              Navigate(USER_MANAGEMENT_URL);
              setLoader(false);
            }, 100);
          } else if (response?.status === 403) {
            CommonLogout(accessToken, Dispatch);
          } else {
            toast.error(response.error);
            setLoader(false);
          }
        } else {
          CheckValid(inputValue?.Email, {
            type: "email",
            error: setErrorEmail,
          });
          CheckValid(inputValue?.Password, {
            type: "password",
            error: setErrorPassword,
          });
          CheckValid(PhoneNumber, { type: "phone", error: setErrorPhone });
        }
      } else {
        CheckValid(inputValue?.username, {
          type: "username",
          error: setErrorusername,
        });
        CheckValid(inputValue?.is_admin, {
          type: "is_admin",
          error: setErroris_admin,
        });
        // CheckValid(inputValue?.Address, {type: 'Address', error: setErrorAddress });
        CheckValid(inputValue?.Email, { type: "email", error: setErrorEmail });
        CheckValid(inputValue?.Password, {
          type: "password",
          error: setErrorPassword,
        });
        CheckValid(PhoneNumber, { type: "phone", error: setErrorPhone });
        CheckValid(permissionGet, {
          type: "permission",
          error: setErrorPermission,
        });
      }
    }
  };

  const ResetForm = () => {
    setInputValue({
      ...inputValue,
      Email: "",
      username: "",
      is_admin: "",
      Address: "",
      Password: "",
    });
    setPhoneNumber({ ...PhoneNumber, phone: "" });
    setPermissionGet("");
  };

  return (
    <section className="dashboard_wrapper_form">
      <BackButton targeturl={USER_MANAGEMENT_URL} title={"Back"} />
      <Scrollbars
        style={{ height: "calc(100%)" }}
        className="ScrollbarsWrapper"
        renderView={(props) => <div {...props} className="view" />}
      >
        <div className="row" style={{ margin: "0 auto" }}>
          <div className="col-md-4">
            <div className="form-group fileUploadingform">
              <label>Profile Image</label>
              {AvatarImage === "" || AvatarImage === null ? (
                <>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => UploadImage(e)}
                    disabled={UploadFileLoader}
                    accept=".png, .jpg, .jpeg"
                  />
                  {UploadFileLoader && (
                    <div className="filUploading">
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                      Please wait, your file is uploading.
                    </div>
                  )}
                </>
              ) : (
                <div className="uploadedImagewrps">
                  <AvatarGet type="product" url={AvatarImage} />
                  <div
                    className="closeIocn"
                    onClick={() => RemoveUploadImage()}
                  >
                    <i className="bi bi-x-circle-fill"></i>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <InputField
              type="text"
              value={username}
              className={
                errorusername !== ""
                  ? "form-control error-form"
                  : "form-control"
              }
              placeholder="Enter User Name"
              label="User Name <span class='redmednstar'>*</span>"
              name="username"
              onChange={handleChange}
              onKeyUp={(e) =>
                CheckValid(e.target.value, {
                  type: "username",
                  error: setErrorusername,
                })
              }
              onKeyDown={EmptySpaceFieldValid}
              errormessage={errorusername}
            />
          </div>

          <div className="col-md-4">
            <div className="form-group">
              <label>Relation</label>
              <select
                className="form-control activestatusselet"
                value={userRelation}
                onChange={(e) => {
                  setUserRelation(e.target.value);
                }}
                style={{ width: "100%" }}
              >
                <option value="all">Select Relation</option>
                {relationsArray?.map((relation, index) => (
                  <option value={relation} key={index}>
                    {relation}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-md-4">
            <InputField
              type="text"
              value={Email}
              className={
                errorEmail !== "" ? "form-control error-form" : "form-control"
              }
              placeholder="Enter Email Address"
              label="Email Address <span class='redmednstar'>*</span>"
              name="Email"
              onChange={handleChange}
              onKeyUp={(e) =>
                CheckValid(e.target.value, {
                  type: "email",
                  error: setErrorEmail,
                })
              }
              onKeyDown={EmptySpaceFieldValid}
              errormessage={errorEmail}
            />
          </div>
          <div className="col-md-4">
            <CountrySelectInput
              errorPhone={errorPhone}
              phone={PhoneNumber}
              setPhone={setPhoneNumber}
              CheckValid={CheckValid}
              setErrorPhone={setErrorPhone}
            />
          </div>
          <div className="col-md-4" hidden>
            <InputField
              type={showpassword ? "text" : "password"}
              value={Password}
              className={
                errorPassword !== ""
                  ? "form-control error-form"
                  : "form-control"
              }
              placeholder="Enter Password"
              label="Enter Password <span class='redmednstar'>*</span>"
              name="Password"
              onChange={handleChange}
              onKeyUp={(e) =>
                CheckValid(e.target.value, {
                  type: "newpassword",
                  error: setErrorPassword,
                })
              }
              onKeyDown={EmptySpaceFieldValid}
              errormessage={errorPassword}
              onClick={ShowHidePassword}
              showpassword={showpassword}
            />
          </div>
          <div className="col-md-12">
            <div className="form-group-btn" style={{ textAlign: "right" }}>
              <button
                type="button"
                className="btn addbtncmn"
                onClick={() => AddUserFunct()}
              >
                {loader && <BtnLoader />}
                {Location === "edit-user" ? "Save" : "Create"}
              </button>
            </div>
          </div>
        </div>
      </Scrollbars>
    </section>
  );
};

export default AddEditUser;
