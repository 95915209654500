/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { MEMBER_MANAGEMENT_URL } from "../Shared/constant";
import BackButton from "../Common/BackButton";
import InputField from "../Common/InputField";
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import { OverlayTrigger, Tooltip, Spinner, Image, Form } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useSelector, useDispatch } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import UploadFileAPI from "../../Api/UploadFileAPI";
import CommonModel from "../Common/CommonModel";
import AvatarGet from "../Common/AvatarGet";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Country } from "country-state-city";
import Countrycurrency from "../Common/Countrycurrency.json";
import CommonLoader from "../Common/CommonLoader";
import ImportProductAPI from "../../Api/ImportProductAPI";
import BtnLoader from "../Common/BtnLoader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import SampleFile from "../../assets/sample/sample_product.xlsx";
import ExportIcon from "../../assets/img/export_icon.png";
import CountrySelectInput from "../Common/CountrySelectInput/CountryMobileInput";
// import TimePicker from 'react-time-picker';
import { TimePicker } from "react-ios-time-picker";
import "react-time-picker/dist/TimePicker.css";
const AddEditPatient = () => {
  const Navigate = useNavigate();
  const Dispatch = useDispatch();
  const params = useParams();
  const { viewProduct, accessToken, device_id, loaderMain, ClearFormSet } =
    useSelector((state) => state.adminReducers);
  const Location = useLocation()?.pathname?.split("/")[1];
  const { websocket } = useContext(WebSocketContext);
  const [inputValue, setInputValue] = useState({
    username: "",
    email: "",
    password: "",
    member_pin: "",
    interval: "",
  });
  const {
    PInumber,
    MemberName,
    username,
    email,
    password,
    member_pin,
    interval,
  } = inputValue;
  const [timeErrors, setTimeErrors] = useState({
    startEnd: true,
    interval: true,
    initialTimeRender: true,
    initialIntervalRender: true,
  });
  const [errorPhone, setErrorPhone] = useState("");

  const [selectedIntervalTime, setselectedIntervalTime] = useState(
    viewProduct?.interval?.length === 4
      ? `0${viewProduct?.interval}`
      : viewProduct?.interval || "00:10"
  );
  const [selectedStartTime, setselectedStartTime] = useState(
    viewProduct?.alert_start_time?.length === 8
      ? viewProduct?.alert_start_time?.slice(0, -3)
      : "00:00"
  );
  const [PhoneNumber, setPhoneNumber] = useState({
    phone_code: "1",
    phone: "",
  });
  const [selectedEndTime, setselectedEndTime] = useState(
    viewProduct?.alert_end_time?.length === 8
      ? viewProduct?.alert_end_time?.slice(0, -3)
      : "00:10"
  );

  const [isChecked, setIsChecked] = useState(false);
  const handleToggle = () => setIsChecked(!isChecked);
  const handleEndTimeChange = (time) => {
    const startTime = new Date(`1970-01-01T${selectedStartTime}:00Z`);
    const endTime = new Date(`1970-01-01T${time}:00Z`);
    const payload = {
      ...timeErrors,
    };
    if (timeErrors?.initialTimeRender) {
      payload.initialTimeRender === false;
    }
    if (
      endTime >= startTime &&
      endTime.getHours() - startTime.getHours() >= 1
    ) {
      setselectedEndTime(time);
      setTimeErrors({
        ...payload,
        startEnd: true,
      });
    } else {
      // if (!timeErrors.initialTimeRender) {
      setTimeErrors({
        ...payload,
        startEnd: false,
      });
      // }
    }
  };

  const handleIntervalTimeChange = (time) => {
    const startTime = new Date(`1970-01-01T00:09:00Z`);
    const payload = {
      ...timeErrors,
    };
    if (timeErrors?.initialIntervalRender) {
      payload.initialIntervalRender === false;
    }

    const selectedTime = new Date(`1970-01-01T${time}:00Z`);

    if (selectedTime > startTime) {
      setselectedIntervalTime(time);
      setTimeErrors({
        ...payload,
        interval: true,
      });
    } else {
      setTimeErrors({
        ...payload,
        interval: false,
      });
    }
  };

  useEffect(() => {
    // Ensure that the end time is at least one hour after the start time
    handleIntervalTimeChange(selectedIntervalTime);
  }, [selectedIntervalTime]);

  useEffect(() => {
    // Ensure that the end time is at least one hour after the start time
    handleEndTimeChange(selectedEndTime);
  }, [selectedEndTime]);

  const [UploadFileLoader, setUploadFileLoader] = useState(false);
  const [AvatarImage, setAvatarImage] = useState("");
  const [AvatarImageId, setAvatarImageId] = useState("");
  const [showModal, setShowModal] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });

  // error field
  const [errorMemberName, setErrorMemberName] = useState("");
  const [errorMemberPhone, setErrorMemberPhone] = useState("");
  const [errorMemberEmail, setErrorMemberEmail] = useState("");
  const [errorMemberPin, setErrorMemberPin] = useState("");
  const [errorMemberID, setErrorMemberID] = useState("");
  const [errorPassword, setErrorPassword] = useState("");

  // get product details
  useEffect(() => {
    if (Location === "edit-patient") {
      if (params?.roomId) {
        let param = {
          transmit: "single",
          url: "user_detail",
          request: {
            user_id: params?.roomId,
            DeviceId: device_id,
            type: "patient",
          },
        };
        wsSend_request(websocket, param);
      }
    }
  }, [params?.roomId, Location]);

  useEffect(() => {
    if (Location === "edit-patient") {
      if (viewProduct) {
        setAvatarImage(
          viewProduct?.avatar === null ? "" : viewProduct?.view_thumbnail_url
        );
        setInputValue({
          ...inputValue,
          interval: viewProduct?.interval,
          username: viewProduct?.username,
          start_time: viewProduct?.start_time,
          end_time: viewProduct?.end_time,
          email: viewProduct?.email,
        });

        setAvatarImageId(viewProduct?.avatar);
        setPhoneNumber({
          ...PhoneNumber,
          phone_code: viewProduct?.phone_code,
          phone: viewProduct?.phone,
        });
        if (
          viewProduct?.interval === "" ||
          viewProduct?.interval === undefined ||
          viewProduct?.interval === null ||
          viewProduct?.alert_end_time === "" ||
          viewProduct?.alert_end_time === undefined ||
          viewProduct?.alert_end_time === null ||
          viewProduct?.alert_start_time === "" ||
          viewProduct?.alert_start_time === undefined ||
          viewProduct?.alert_start_time === null
        ) {
          setIsChecked(false);
        } else {
          setIsChecked(true);
        }
        setselectedIntervalTime(
          viewProduct?.interval ? viewProduct?.interval : "00:10"
        );

        setselectedEndTime(
          viewProduct?.alert_end_time
            ? viewProduct?.alert_end_time?.slice(0, -3)
            : "00:10"
        );

        setselectedStartTime(
          viewProduct?.alert_start_time
            ? viewProduct?.alert_start_time?.slice(0, -3)
            : "00:00"
        );
      }
    }
  }, [viewProduct]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // upload product image
  const UploadImage = async (e) => {
    setUploadFileLoader(true);
    const files = e.target.files[0];
    const formData = new FormData();
    formData.append("file", files, files.name);
    const fileresponse = await UploadFileAPI(accessToken, formData);
    if (fileresponse) {
      setUploadFileLoader(false);
      setAvatarImage(fileresponse?.view_thumbnail_url);
      setAvatarImageId(fileresponse?.id);
    }
  };

  const fileInput = document.getElementById("file-input");
  // import product list

  // remove image uploaded
  const RemoveUploadImage = () => {
    setAvatarImage("");
    setAvatarImageId("");
  };

  const CreateProduct = () => {

    if (Location === "edit-patient") {
        
      if (
        inputValue?.username !== "" &&
        // inputValue?.password !== undefined && inputValue.password.length === 4 &&
        inputValue?.email !== "" &&
        PhoneNumber?.phone !== "" &&
        timeErrors?.startEnd === true &&
        timeErrors?.interval === true &&
        !errorPhone

      ) {
        let param;

        param = {
          transmit: "broadcast",
          url: "update_patient",
          request: {
            patient_id: params?.roomId,
            phone_code: PhoneNumber?.phone_code,
            phone: PhoneNumber?.phone,
            // member_pin: inputValue?.member_pin,
            password: inputValue?.password ? inputValue?.password : null,
            interval: !isChecked
              ? "0"
              : timeErrors?.interval
              ? selectedIntervalTime
              : "0",
            avatar: AvatarImageId === "" ? null : AvatarImageId,
            DeviceId: device_id,
            start_time: !isChecked ? "0" : `${selectedStartTime}:00` || "0",
            end_time: !isChecked
              ? "0"
              : timeErrors?.startEnd
              ? `${selectedEndTime}:00`
              : "0",
            username: inputValue?.username,
            type: "admin",
            email: inputValue?.email,
          },
        };

        wsSend_request(websocket, param);
      } else {
        if (!timeErrors?.startEnd) {
          toast.error("End time must be at least one hour after start time");
        }
        if (!timeErrors?.interval) {
          toast.error("Interval time must be at least 10 minutes");
        }
        CheckValid(PhoneNumber, { type: "phone", error: setErrorPhone });
         CheckValid(inputValue?.username, {
          type: "MemberName",
          error: setErrorMemberName,
        });
        CheckValid(inputValue?.email, {
          type: "email",
          error: setErrorMemberEmail,
        });
      }
    } else {
      if (
        inputValue?.username !== "" &&
        inputValue?.email !== "" &&
        inputValue?.password !== "" &&
        PhoneNumber?.phone !== ""
      ) {
        let paramRequest;

        paramRequest = {
          avatar: AvatarImageId,
          DeviceId: device_id,
          phone_code: PhoneNumber?.phone_code,
          phone: PhoneNumber?.phone,
          member_pin: inputValue?.member_pin,
          password: inputValue?.password,
          interval: inputValue?.interval,
          start_time: selectedStartTime,
          end_time: selectedEndTime,
          username: inputValue?.username,
        };

        const paramfilter = Object.entries(paramRequest).filter((elm) => {
          if (elm[1] !== "") {
            return elm;
          }
        });
        let param = {
          transmit: "broadcast",
          url: "add_product",
          request: Object.fromEntries(paramfilter),
        };
        wsSend_request(websocket, param);
      } else {
        CheckValid(inputValue?.username, {
          type: "username",
          error: setErrorMemberName,
        });
        CheckValid(inputValue?.email, {
          type: "email",
          error: setErrorMemberEmail,
        });
        // CheckValid(inputValue?.member_pin, {
        //   type: "Member PIN",
        //   error: setErrorMemberPin,
        // });
        CheckValid(PhoneNumber?.phone, {
          type: "phone",
          error: setErrorMemberPhone,
        });
        
      }
    }
  };

  useEffect(() => {
    if (ClearFormSet.url === "update_product" && ClearFormSet.action === true) {
      Navigate(MEMBER_MANAGEMENT_URL);
      ClearForm();
    } else if (
      ClearFormSet.url === "add_product" &&
      ClearFormSet.action === true
    ) {
      Navigate(MEMBER_MANAGEMENT_URL);
      ClearForm();
    }
  }, [ClearFormSet]);

  document.addEventListener("wheel", function (event) {
    if (
      document.activeElement.type === "number" &&
      document.activeElement.classList.contains("inputBoxs")
    ) {
      document.activeElement.blur();
    }
  });

  // clear form
  const ClearForm = () => {
    setInputValue({
      ...inputValue,
      username: "",
      email: "",
      password: "",
      member_pin: "",
    });
    setAvatarImage("");
    setAvatarImageId("");
  };

  useEffect(() => {
    if (!isChecked) {
      setTimeErrors(prev => ({
        ...prev,
        startEnd: true,
        interval: true
      }));
    }
  }, [isChecked]);
  
  return (
    <React.Fragment>
      <div
        className="header_back header_back_with_btn"
        style={{ justifyContent: Location === "edit-product" ? "end" : "end" }}
      >
        <BackButton targeturl={MEMBER_MANAGEMENT_URL} title={"Back"} />
      </div>
      <section className="dashboard_wrapper_form">
        <div className="scrollform">
          <Scrollbars
            style={{ height: "calc(100vh - 150px)" }}
            className="ScrollbarsWrapper"
            renderView={(props) => <div {...props} className="view" />}
          >
            <div className="scrollformCustom">
              <div className="row" style={{ margin: "0 auto" }}>
                <div className="col-md-4">
                  <div className="form-group fileUploadingform">
                    <label>Profile Image</label>
                    {AvatarImage === "" || AvatarImage === null ? (
                      <>
                        <input
                          type="file"
                          className="form-control"
                          onChange={(e) => UploadImage(e)}
                          disabled={UploadFileLoader}
                          accept=".png, .jpg, .jpeg"
                        />
                        {UploadFileLoader && (
                          <div className="filUploading">
                            <Spinner animation="border" role="status">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </Spinner>
                            Please wait, your file is uploading.
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="uploadedImagewrps">
                        <AvatarGet type="product" url={AvatarImage} />
                        <div
                          className="closeIocn"
                          onClick={() => RemoveUploadImage()}
                        >
                          <i className="bi bi-x-circle-fill"></i>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <InputField
                    type="text"
                    value={inputValue?.username}
                    className={
                      errorMemberName !== ""
                        ? "form-control error-form"
                        : "form-control"
                    }
                    placeholder="Enter Full User Name"
                    label="User Name <span class='redmednstar'>*</span>"
                    name="username"
                    onChange={handleChange}
                    onKeyUp={(e) =>
                      CheckValid(e.target.value, {
                        type: "username",
                        error: setErrorMemberName,
                      })
                    }
                    onKeyDown={EmptySpaceFieldValid}
                    errormessage={errorMemberName}
                  />
                </div>
                <div className="col-md-4">
                <CountrySelectInput
              errorPhone={errorPhone}
              phone={PhoneNumber}
              setPhone={setPhoneNumber}
              CheckValid={CheckValid}
              setErrorPhone={setErrorPhone}
            />
                </div>
                <div className="col-md-4">
                  <InputField
                    type="email"
                    value={inputValue?.email}
                    className={
                      errorMemberEmail !== ""
                        ? "form-control error-form"
                        : "form-control"
                    }
                    placeholder="Enter Member Email Address"
                    label="Email Address <span class='redmednstar'>*</span>"
                    name="email"
                    onChange={handleChange}
                    onKeyUp={(e) =>
                      CheckValid(e.target.value, {
                        type: "email",
                        error: setErrorMemberEmail,
                      })
                    }
                    onKeyDown={EmptySpaceFieldValid}
                    errormessage={errorMemberEmail}
                  />
                </div>
                <div className="col-md-4">
                  <InputField
                    type="text"
                    value={inputValue?.password}
                    className={
                      inputValue?.password !== ""
                        ? "form-control error-form"
                        : "form-control"
                    }
                    placeholder="Enter Password"
                    label="Password"
                    name="password"
                    onChange={handleChange}
                    errormessage={errorPassword}
                  />
                </div>
                <div className="col-md-4"></div>
                <div
                  className="d-flex gap-5"
                  style={{ borderTop: "1px solid #2c59c0" }}
                >
                  <div className="col-md-2 mt-4">
                    <div className="d-flex align-items-center">
                      <span className="me-3">
                        {isChecked ? " Set Alert On" : " Set Alert Off"}
                      </span>
                      <Form.Check
                        type="switch"
                        id="toggle-switch"
                        label=""
                        checked={isChecked}
                        onChange={handleToggle}
                      />
                    </div>
                  </div>
                  {isChecked && (
                    <>
                      <div className="col-md-3 mt-4">
                        <div className="form-group">
                          <label>Interval Time</label>
                          <div className="formInput">
                            <TimePicker
                              onChange={(time) => {
                                setselectedIntervalTime(time);
                              }}
                              value={selectedIntervalTime}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 mt-4">
                        <div className="form-group">
                          <label>Start Time</label>
                          <div className="formInput">
                            <TimePicker
                              onChange={(time) => {
                                setselectedStartTime(time);
                              }}
                              value={selectedStartTime}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 mt-4">
                        <div className="form-group">
                          <label>End Time</label>
                          <div className="formInput">
                            <TimePicker
                              onChange={(time) => {
                                setselectedEndTime(time);
                              }}
                              value={selectedEndTime}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="col-md-4" hidden>
                  <InputField
                    type="number"
                    value={inputValue?.member_pin}
                    className={
                      errorMemberPin !== ""
                        ? "form-control error-form"
                        : "form-control"
                    }
                    placeholder="Enter Member PIN"
                    label="Member PIN <span class='redmednstar'>*</span>"
                    name="member_pin"
                    onChange={handleChange}
                    onKeyUp={(e) =>
                      CheckValid(e.target.value, {
                        type: "Member PIN",
                        error: setErrorMemberPin,
                      })
                    }
                    onKeyDown={EmptySpaceFieldValid}
                    errormessage={errorMemberPin}
                  />
                </div>
                <div className="col-md-12">
                  <div
                    className="form-group-btn"
                    style={{ textAlign: "right" }}
                  >
                    <button
                      className="btn addbtncmn"
                      onClick={() => CreateProduct()}
                    >
                      {Location === "edit-patient" ? "Save" : "Create"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>
        </div>
        {loaderMain && <CommonLoader />}
      </section>
      {showModal?.open && (
        <CommonModel
          fromUrl={"product"}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </React.Fragment>
  );
};

export default AddEditPatient;
