/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import DeleteUserAPI from "../../Api/DeleteUserAPI";
import CommonLogout from "./CommonLogout";
import { DeleteUser, editUserList } from "../../redux/actions/adminActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UpdateUserAPI from "../../Api/UpdateUserAPI";
import BtnLoader from "./BtnLoader";
import InputField from "./InputField";
import EmptySpaceFieldValid from "./EmptySpaceFieldValid";
import CheckValid from "./CheckValid";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";

const CommonModel = (props) => {
  const { websocket } = useContext(WebSocketContext);
  const [loader, setLoader] = useState(false);
  const [inputValue, setInputValue] = useState({
    BrandName: "",
    ModelName: "",
    CategoryName: "",
    SettingName: "",
  });
  const { BrandName, ModelName, CategoryName, SettingName } = inputValue;
  const Dispatch = useDispatch();
  const { showModal, setShowModal, setCurrentPage, setUserSearch, fromUrl } =
    props;

  const { accessToken, brandlistall, device_id } = useSelector(
    (state) => state.adminReducers
  );
  const [selectBrand, setSelectBrand] = useState("");

  // error message
  const [errorBrandName, setErrorBrandName] = useState("");
  const [errorModelName, setErrorModelName] = useState("");
  const [errorSelectBrand, setErrorSelectBrand] = useState("");
  const [errorCategoryName, setErrorCategoryName] = useState("");
  const [errorSettingName, setErrorSettingName] = useState("");

  // close modal
  const handleClose = () => {
    setShowModal({ ...showModal, open: false });
  };

  // Textfield common onchange function
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (showModal?.subtitle === "Edit Brand") {
      setInputValue({ ...inputValue, BrandName: showModal?.Data?.name });
    } else if (showModal?.subtitle === "Edit Model") {
      setInputValue({ ...inputValue, ModelName: showModal?.Data?.name });
      setSelectBrand(showModal?.Data?.brand_id);
    } else if (showModal?.subtitle === "Edit Category") {
      setInputValue({ ...inputValue, CategoryName: showModal?.Data?.name });
    } else if (showModal?.subtitle === "Edit Field") {
      setInputValue({ ...inputValue, SettingName: showModal?.Data?.name });
    }
  }, [showModal]);

  // select brand
  const selectBrandChange = (e) => {
    if (e.target.value) {
      setSelectBrand(e.target.value);
      CheckValid(e.target.value, {
        type: "Select Brand",
        error: setErrorSelectBrand,
      });
    } else {
      setSelectBrand("");
      CheckValid(e.target.value, {
        type: "Select Brand",
        error: setErrorSelectBrand,
      });
    }
  };

  const CommonFunction = async () => {
    if (showModal?.subtitle === "Delete User") {
      let param = {
        transmit: "broadcast",
        url: "delete_member",
        request: {
          member_id: showModal?.Data?.id,
          DeviceId: device_id,
          type: "admin",
        },
      };
      wsSend_request(websocket, param);
      setUserSearch("");
      CommonListUpdate("member_list");
      handleClose();
      // setLoader(true);
      // const response = await DeleteUserAPI(accessToken, showModal?.Data?.id);
      // if(response?.status === 200){
      //     setTimeout(() => {
      //         setLoader(false);
      //         toast.success(response.msg);
      //         Dispatch(DeleteUser(response.data));
      //         setUserSearch("");
      //         handleClose();
      //     }, 100);
      // } else if(response?.detail === "Token expired.") {
      //     setLoader(false);
      //     CommonLogout(accessToken, Dispatch);
      //     toast.error(response?.error || response?.data?.error);
      // } else {
      //     setLoader(false);
      //     toast.error(response?.error || response?.data?.error);
      // };
    } else if (showModal?.subtitle === "active User") {
      // setLoader(true);
      // const jsonData = JSON.stringify({
      //     user_id: showModal?.Data?.id,
      //     is_active: showModal?.Data?.is_active ? 0 : 1,
      // });
      // const response = await UpdateUserAPI(jsonData, accessToken);
      // if(response?.status === 200){
      //     setTimeout(() => {
      //         setLoader(false);
      //         toast.success(response.msg);
      //         Dispatch(editUserList(response.data));
      //         handleClose();
      //     }, 100);
      // } else if(response?.detail === "Token expired.") {
      //     setLoader(false);
      //     CommonLogout(accessToken, Dispatch);
      //     toast.error(response?.error || response?.data?.error);
      // } else {
      //     setLoader(false);
      //     toast.error(response?.error || response?.data?.error);
      // };
      let param = {
        transmit: "broadcast",
        url: "update_user",
        request: {
          staff_id: showModal?.Data?.id,
          is_active: showModal?.Data?.is_active ? 0 : 1,
          DeviceId: device_id,

          first_name: showModal?.Data?.first_name,
          last_name: showModal?.Data?.last_name,
          phone_code: showModal?.Data?.phone_code,
          phone: showModal?.Data?.phone,
          email: showModal?.Data?.email,
          address: showModal?.Data?.address,
          permissions: showModal?.Data?.permissions,
        },
      };
      wsSend_request(websocket, param);
      handleClose();
    } else if (showModal?.subtitle === "admin User") {
      // setLoader(true);
      // const jsonData = JSON.stringify({
      //     user_id: showModal?.Data?.id,
      //     is_admin: showModal?.Data?.is_admin ? 0 : 1,
      // });
      // const response = await UpdateUserAPI(jsonData, accessToken);
      // if(response?.status === 200){
      //     setTimeout(() => {
      //         setLoader(false);
      //         toast.success(response.msg);
      //         Dispatch(editUserList(response.data));
      //         handleClose();
      //     }, 100);
      // } else if(response?.detail === "Token expired.") {
      //     setLoader(false);
      //     CommonLogout(accessToken, Dispatch);
      //     toast.error(response?.error || response?.data?.error);
      // } else {
      //     setLoader(false);
      //     toast.error(response?.error || response?.data?.error);
      // };
      let param = {
        transmit: "broadcast",
        url: "update_user",
        request: {
          staff_id: showModal?.Data?.id,
          is_admin: showModal?.Data?.is_admin ? 0 : 1,
          DeviceId: device_id,

          first_name: showModal?.Data?.first_name,
          last_name: showModal?.Data?.last_name,
          phone_code: showModal?.Data?.phone_code,
          phone: showModal?.Data?.phone,
          email: showModal?.Data?.email,
          address: showModal?.Data?.address,
          permissions: showModal?.Data?.permissions,
        },
      };
      wsSend_request(websocket, param);
      handleClose();
    } else if (showModal?.subtitle === "staff User") {
      // setLoader(true);
      // const jsonData = JSON.stringify({
      //     user_id: showModal?.Data?.id,
      //     is_staff: showModal?.Data?.is_staff ? 0 : 1,
      // });
      // const response = await UpdateUserAPI(jsonData, accessToken);
      // if(response?.status === 200){
      //     setTimeout(() => {
      //         setLoader(false);
      //         toast.success(response.msg);
      //         Dispatch(editUserList(response.data));
      //         handleClose();
      //     }, 100);
      // } else if(response?.detail === "Token expired.") {
      //     setLoader(false);
      //     CommonLogout(accessToken, Dispatch);
      //     toast.error(response?.error || response?.data?.error);
      // } else {
      //     setLoader(false);
      //     toast.error(response?.error || response?.data?.error);
      // };
      let param = {
        transmit: "broadcast",
        url: "update_user",
        request: {
          staff_id: showModal?.Data?.id,
          is_staff: showModal?.Data?.is_staff ? 0 : 1,
          DeviceId: device_id,

          first_name: showModal?.Data?.first_name,
          last_name: showModal?.Data?.last_name,
          phone_code: showModal?.Data?.phone_code,
          phone: showModal?.Data?.phone,
          email: showModal?.Data?.email,
          address: showModal?.Data?.address,
          permissions: showModal?.Data?.permissions,
        },
      };
      wsSend_request(websocket, param);
      handleClose();
    } else if (showModal?.subtitle === "Create Brand") {
      if (BrandName.trim() !== "") {
        let param = {
          transmit: "broadcast",
          url: "add_product_brand",
          request: {
            name: BrandName,
            DeviceId: device_id,
          },
        };
        wsSend_request(websocket, param);
        CommonListUpdate("product_brand_list");
        handleClose();
      } else {
        CheckValid(BrandName.trim(), {
          type: "Brand Name",
          error: setErrorBrandName,
        });
      }
    } else if (showModal?.subtitle === "Edit Brand") {
      if (
        BrandName?.trim() !== "" &&
        BrandName?.trim() !== undefined &&
        BrandName?.trim() !== null
      ) {
        let param = {
          transmit: "broadcast",
          url: "update_product_brand",
          request: {
            brand_id: showModal?.Data?.id,
            name: BrandName,
            DeviceId: device_id,
          },
        };
        wsSend_request(websocket, param);
        CommonListUpdate("product_brand_list");
        handleClose();
      } else {
        CheckValid(BrandName?.trim(), {
          type: "Brand Name",
          error: setErrorBrandName,
        });
      }
    } else if (showModal?.subtitle === "Active Brand") {
      let param = {
        transmit: "broadcast",
        url: "update_product_brand",
        request: {
          brand_id: showModal?.Data?.id,
          is_active: showModal?.Data?.is_active ? 0 : 1,
          DeviceId: device_id,
        },
      };
      wsSend_request(websocket, param);
      CommonListUpdate("product_brand_list");
      handleClose();
    } else if (showModal?.subtitle === "Delete Brand") {
      let param = {
        transmit: "broadcast",
        url: "delete_product_brand",
        request: {
          brand_id: showModal?.Data?.id,
          DeviceId: device_id,
        },
      };
      wsSend_request(websocket, param);
      setUserSearch("");
      CommonListUpdate("product_brand_list");
      handleClose();
    } else if (showModal?.subtitle === "Create Model") {
      if (ModelName !== "" && selectBrand !== "") {
        let param = {
          transmit: "broadcast",
          url: "add_product_model",
          request: {
            brand_id: selectBrand,
            name: ModelName,
            DeviceId: device_id,
          },
        };
        wsSend_request(websocket, param);
        CommonListUpdate("product_model_list");
        handleClose();
      } else {
        CheckValid(ModelName, { type: "Model Name", error: setErrorModelName });
        CheckValid(selectBrand, {
          type: "Select Brand",
          error: setErrorSelectBrand,
        });
      }
    } else if (showModal?.subtitle === "Active Model") {
      let param = {
        transmit: "broadcast",
        url: "update_product_model",
        request: {
          model_id: showModal?.Data?.id,
          is_active: showModal?.Data?.is_active ? 0 : 1,
          DeviceId: device_id,
        },
      };
      wsSend_request(websocket, param);
      CommonListUpdate("product_model_list");
      handleClose();
    } else if (showModal?.subtitle === "Edit Model") {
      if (
        showModal?.Data?.name !== ModelName &&
        showModal?.Data?.brand_id !== selectBrand
      ) {
        let param = {
          transmit: "broadcast",
          url: "update_product_model",
          request: {
            name: ModelName,
            model_id: showModal?.Data?.id,
            brand_id: selectBrand,
            DeviceId: device_id,
          },
        };
        wsSend_request(websocket, param);
        CommonListUpdate("product_model_list");
        handleClose();
      } else if (showModal?.Data?.name !== ModelName) {
        let param = {
          transmit: "broadcast",
          url: "update_product_model",
          request: {
            name: ModelName,
            model_id: showModal?.Data?.id,
            DeviceId: device_id,
          },
        };
        wsSend_request(websocket, param);
        CommonListUpdate("product_model_list");
        handleClose();
      } else if (showModal?.Data?.brand_id !== selectBrand) {
        let param = {
          transmit: "broadcast",
          url: "update_product_model",
          request: {
            brand_id: selectBrand,
            model_id: showModal?.Data?.id,
            DeviceId: device_id,
          },
        };
        wsSend_request(websocket, param);
        CommonListUpdate("product_model_list");
        handleClose();
      } else {
        toast.error(
          "If you want to edit model then change model name or brand name"
        );
      }
    } else if (showModal?.subtitle === "Delete Model") {
      let param = {
        transmit: "broadcast",
        url: "delete_product_model",
        request: {
          model_id: showModal?.Data?.id,
          DeviceId: device_id,
        },
      };
      wsSend_request(websocket, param);
      setUserSearch("");
      CommonListUpdate("product_model_list");
      handleClose();
    } else if (showModal?.subtitle === "Delete Supplier") {
      let param = {
        transmit: "broadcast",
        url: "delete_supplier",
        request: {
          supplier_id: showModal?.Data?.id,
          DeviceId: device_id,
        },
      };
      wsSend_request(websocket, param);
      setUserSearch("");
      CommonListUpdate("supplier_list");
      handleClose();
    } else if (showModal?.subtitle === "Active Supplier") {
      let param = {
        transmit: "broadcast",
        url: "update_supplier",
        request: {
          supplier_id: showModal?.Data?.id,
          is_active: showModal?.Data?.is_active ? 0 : 1,
          DeviceId: device_id,
          name: showModal?.Data?.name,
          email: showModal?.Data?.email,
          phone_code: showModal?.Data?.phone_code,
          title: showModal?.Data?.title,
          website: showModal?.Data?.website,
          phone: showModal?.Data?.phone,
          company: showModal?.Data?.company,
          address: showModal?.Data?.address,
          vat_registration_no: showModal?.Data?.vat_registration_no,
          registration_no: showModal?.Data?.registration_no,
          has_contact: showModal?.Data?.has_contact,
        },
      };
      wsSend_request(websocket, param);
      CommonListUpdate("supplier_list");
      handleClose();
    } else if (showModal?.subtitle === "Active Product") {
      let param = {
        transmit: "broadcast",
        url: "update_product",
        request: {
          product_id: showModal?.Data?.id,
          is_active: showModal?.Data?.is_active ? 0 : 1,
          pi_no: showModal?.Data?.pi_no,
          avatar:
            showModal?.Data?.avatar === "" ? null : showModal?.Data?.avatar,
          name: showModal?.Data?.name,
          brand: showModal?.Data?.brand,
          model: showModal?.Data?.model,
          quantity: showModal?.Data?.quantity,
          supplier: showModal?.Data?.supplier,
          unit_cost_price: showModal?.Data?.unit_cost_price,
          incoterm: showModal?.Data?.incoterm,
          hs_code: showModal?.Data?.hs_code,
          dimensions: showModal?.Data?.dimensions,
          currency: showModal?.Data?.currency,
          floor: showModal?.Data?.floor,
          ps: showModal?.Data?.ps,
          date: showModal?.Data?.date,
          client_code: showModal?.Data?.client_code,
          pc_code: showModal?.Data?.pc_code,
          description_french: showModal?.Data?.description_french,
          description_english: showModal?.Data?.description_english,
          department: showModal?.Data?.department,
          unit: showModal?.Data?.unit,
          ucp_usd: showModal?.Data?.ucp_usd,
          tcp_usd: showModal?.Data?.tcp_usd,
          usp_usd: showModal?.Data?.usp_usd,
          tsp_usd: showModal?.Data?.tsp_usd,
          lot_number: showModal?.Data?.lot_number,
          brochure: showModal?.Data?.brochure,
          remarks: showModal?.Data?.remarks,
          country_of_origin: showModal?.Data?.country_of_origin,
          catalog: showModal?.Data?.catalog,
          cs: showModal?.Data?.cs,
          ce: showModal?.Data?.ce,
          iso: showModal?.Data?.iso,
          maf: showModal?.Data?.maf,
          warranty: showModal?.Data?.warranty,
          lead_time: showModal?.Data?.lead_time,
          category: showModal?.Data?.category,
          fator: showModal?.Data?.fator,
          DeviceId: device_id,
        },
      };
      wsSend_request(websocket, param);
      CommonListUpdate("member_list");
      handleClose();
    } else if (showModal?.subtitle === "Delete Product") {
      let param = {
        transmit: "broadcast",
        url: "delete_product",
        request: {
          product_id: showModal?.Data?.id,
          DeviceId: device_id,
          type: "admin",
        },
      };
      wsSend_request(websocket, param);
      setUserSearch("");
      CommonListUpdate("member_list");
      handleClose();
    } else if (showModal?.subtitle === "Export Products") {
      const ResultFile =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
        showModal?.Data;
      var link = document.createElement("a");
      link.href = ResultFile;
      link.download = "sample.xlsx";
      link.click();
      setTimeout(() => {
        handleClose();
      }, 1000);
    } else if (showModal?.subtitle === "Products Update") {
      const ResultFile =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
        showModal?.Data;
      var link = document.createElement("a");
      link.href = ResultFile;
      link.download = "sample.xlsx";
      link.click();
      setTimeout(() => {
        handleClose();
      }, 1000);
    } else if (showModal?.subtitle === "Add Category") {
      if (CategoryName !== "") {
        let param = {
          transmit: "broadcast",
          url: "add_alert",
          request: {
            name: CategoryName,
            DeviceId: device_id,
          },
        };
        wsSend_request(websocket, param);
        CommonListUpdate("alert_list");
        handleClose();
      } else {
        CheckValid(CategoryName, {
          type: "Category Name",
          error: setErrorCategoryName,
        });
      }
    } else if (showModal?.subtitle === "Edit Category") {
      if (CategoryName !== "") {
        let param = {
          transmit: "broadcast",
          url: "update_alert",
          request: {
            category_id: showModal?.Data?.id,
            name: CategoryName,
            DeviceId: device_id,
          },
        };
        wsSend_request(websocket, param);
        CommonListUpdate("alert_list");
        handleClose();
      } else {
        CheckValid(CategoryName, {
          type: "Category Name",
          error: setErrorCategoryName,
        });
      }
    } else if (showModal?.subtitle === "Active Category") {
      let param = {
        transmit: "broadcast",
        url: "update_alert",
        request: {
          category_id: showModal?.Data?.id,
          is_active: showModal?.Data?.is_active ? 0 : 1,
          DeviceId: device_id,
        },
      };
      wsSend_request(websocket, param);
      CommonListUpdate("alert_list");
      handleClose();
    } else if (showModal?.subtitle === "Delete Category") {
      let param = {
        transmit: "broadcast",
        url: "delete_alert",
        request: {
          category_id: showModal?.Data?.id,
          DeviceId: device_id,
        },
      };
      wsSend_request(websocket, param);
      setUserSearch("");
      CommonListUpdate("alert_list");
      handleClose();
    } else if (showModal?.subtitle === "Add Field") {
      if (SettingName !== "") {
        let param = {
          transmit: "broadcast",
          url: "add_sos",
          request: {
            name: SettingName,
            DeviceId: device_id,
          },
        };
        wsSend_request(websocket, param);
        CommonListUpdate("sos_list");
        handleClose();
      } else {
        CheckValid(SettingName, {
          type: "Setting Name",
          error: setErrorSettingName,
        });
      }
    } else if (showModal?.subtitle === "Delete Field") {
      let param = {
        transmit: "broadcast",
        url: "delete_sos",
        request: {
          setting_id: showModal?.Data?.id,
          DeviceId: device_id,
        },
      };
      wsSend_request(websocket, param);
      setUserSearch("");
      CommonListUpdate("sos_list");
      handleClose();
    } else if (showModal?.subtitle === "Edit Field") {
      let param = {
        transmit: "broadcast",
        url: "update_sos",
        request: {
          setting_id: showModal?.Data?.id,
          DeviceId: device_id,
          name: SettingName,
        },
      };
      wsSend_request(websocket, param);
      setUserSearch("");
      CommonListUpdate("sos_list");
      handleClose();
    } else if (showModal?.subtitle === "Logout") {
      CommonLogout(accessToken, Dispatch);
    }
  };

  const FailProductFunction = () => {
    const ResultFile =
      "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
      showModal?.Data?.failed;
    var link = document.createElement("a");
    link.href = ResultFile;
    link.download = "sample.xlsx";
    link.click();
    // setTimeout(() => {
    //     handleClose();
    // }, 1000);
  };

  const UpdatedProductFunction = () => {
    const ResultFile =
      "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
      showModal?.Data?.updated;
    var link = document.createElement("a");
    link.href = ResultFile;
    link.download = "sample.xlsx";
    link.click();
    // setTimeout(() => {
    //     handleClose();
    // }, 1000);
  };

  // When editing API calling, at that time list API calling.
  const CommonListUpdate = (url) => {
    if (
      showModal?.subtitle === "Create Model" ||
      showModal?.subtitle === "Create Brand" ||
      showModal?.subtitle === "Add Category" ||
      showModal?.subtitle === "Create Supplier"
    ) {
      if (fromUrl === "product") {
        let param = {
          transmit: "broadcast",
          url: url,
          request: {
            filter: "all",
            limit: 5000,
            page_no: 1,
            search: "",
            type: "admin",
          },
        };
        wsSend_request(websocket, param);
      } else {
        let param = {
          transmit: "broadcast",
          url: url,
          request: {
            filter: "all",
            limit: 10,
            page_no: 1,
            search: "",
            type: "admin",
          },
        };
        wsSend_request(websocket, param);
      }
    } else {
      let param = {
        transmit: "broadcast",
        url: url,
        request: {
          filter: "all",
          limit: 10,
          page_no: 1,
          search: "",
          type: "admin",
        },
      };
      wsSend_request(websocket, param);
      if (fromUrl !== "product" && setCurrentPage) {
        setCurrentPage(1);
      }
    }
  };

  return (
    <Modal
      className={
        showModal?.title === "Create Supplier"
          ? "commonModal addSupplierModel"
          : "commonModal"
      }
      show={showModal?.open}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title
          style={{ width: showModal?.title === "Are you sure?" ? "100%" : "" }}
        >
          {showModal?.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showModal?.modalType === "form" ? (
          <Form.Group className="mb-3">
            {/* <Form.Label>{showModal?.title === "Create Brand" ? "Brand Name" : "Model Name"}</Form.Label>
                    <div className="formInput">
                        <Form.Control type="text" placeholder={showModal?.title === "Create Brand" ? "Enter Brand Name" : "Enter Model Name"} />
                    </div> */}
            {showModal?.title === "Create Brand" ||
            showModal?.title === "Edit Brand" ? (
              <InputField
                type="text"
                value={BrandName}
                className={
                  errorBrandName !== ""
                    ? "form-control error-form"
                    : "form-control"
                }
                placeholder="Enter Brand Name"
                label="Brand Name <span class='redmednstar'>*</span>"
                name="BrandName"
                onChange={handleChange}
                onKeyUp={(e) =>
                  CheckValid(e.target.value, {
                    type: "Brand Name",
                    error: setErrorBrandName,
                  })
                }
                onKeyDown={EmptySpaceFieldValid}
                errormessage={errorBrandName}
              />
            ) : showModal?.title === "Add Category" ||
              showModal?.title === "Edit Category" ? (
              <InputField
                type="text"
                value={CategoryName}
                className={
                  errorCategoryName !== ""
                    ? "form-control error-form"
                    : "form-control"
                }
                placeholder="Enter Category Name"
                label="Category Name <span class='redmednstar'>*</span>"
                name="CategoryName"
                onChange={handleChange}
                onKeyUp={(e) =>
                  CheckValid(e.target.value, {
                    type: "Category Name",
                    error: setErrorCategoryName,
                  })
                }
                onKeyDown={EmptySpaceFieldValid}
                errormessage={errorCategoryName}
              />
            ) : showModal?.title === "Add Field" ||
              showModal?.title === "Edit Field" ? (
              <InputField
                type="text"
                value={SettingName}
                className={
                  errorSettingName !== ""
                    ? "form-control error-form"
                    : "form-control"
                }
                placeholder="Enter Field Name"
                label="Field Name <span class='redmednstar'>*</span>"
                name="SettingName"
                onChange={handleChange}
                onKeyUp={(e) =>
                  CheckValid(e.target.value, {
                    type: "Setting Name",
                    error: setErrorSettingName,
                  })
                }
                onKeyDown={EmptySpaceFieldValid}
                errormessage={errorSettingName}
              />
            ) : showModal?.title === "Create Model" ||
              showModal?.title === "Edit Model" ? (
              <>
                <InputField
                  type="text"
                  value={ModelName}
                  className={
                    errorModelName !== ""
                      ? "form-control error-form"
                      : "form-control"
                  }
                  placeholder="Enter Model Name"
                  label="Model Name <span class='redmednstar'>*</span>"
                  name="ModelName"
                  onChange={handleChange}
                  onKeyUp={(e) =>
                    CheckValid(e.target.value, {
                      type: "Model Name",
                      error: setErrorModelName,
                    })
                  }
                  onKeyDown={EmptySpaceFieldValid}
                  errormessage={errorModelName}
                />
                <div className="form-group">
                  <label>
                    Brand List <span className="redmednstar">*</span>
                  </label>
                  <select
                    className="form-control"
                    value={selectBrand}
                    onChange={(e) => selectBrandChange(e)}
                  >
                    <option value="">Select Brand</option>
                    {brandlistall?.map((elm, index) => {
                      return (
                        <option value={elm?.id} key={index}>
                          {elm?.name}
                        </option>
                      );
                    })}
                  </select>
                  {errorSelectBrand !== "" && (
                    <div className="errormendatorr">
                      <i className="bi bi-x-circle-fill"></i>
                      {errorSelectBrand}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <> </>
            )}
          </Form.Group>
        ) : (
          <>
            <div className="notformsection">
              {showModal?.subtitle === "Logout" && (
                <i className="bi bi-box-arrow-right logoutIconbtn"></i>
              )}
              <p
                dangerouslySetInnerHTML={{ __html: showModal?.description }}
              ></p>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {showModal?.subtitle === "Products Update and Export" ? (
          <React.Fragment>
            <Button
              variant="primary"
              className="addbtncmn"
              style={{ padding: "7px 15px" }}
              onClick={FailProductFunction}
            >
              {loader && <BtnLoader />}
              {showModal?.button} Failed Product
            </Button>
            <Button
              variant="primary"
              className="addbtncmn"
              style={{ padding: "7px 15px" }}
              onClick={UpdatedProductFunction}
            >
              {loader && <BtnLoader />}
              {showModal?.button} Updated Product
            </Button>
          </React.Fragment>
        ) : (
          <Button
            variant="primary"
            className="addbtncmn"
            onClick={CommonFunction}
          >
            {loader && <BtnLoader />}
            {showModal?.button}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CommonModel;
