/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import DataTableFilter from "../Common/DataTableFilter";
import { Scrollbars } from "react-custom-scrollbars-2";
import CommonToggle from "../Common/CommonToggle";
import { Image, Collapse, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import MoreIcon from "../../assets/img/more_options.png";
import {
  ADD_USER_URL,
  EDIT_USER_URL,
  VIEW_USER_MANAGEMENT_URL,
} from "../Shared/constant";
import AvatarGet from "../Common/AvatarGet";
import { useSelector, useDispatch } from "react-redux";
import GetUserListAPI from "../../Api/GetUserListAPI";
import { getUserList } from "../../redux/actions/adminActions";
import CommonLogout from "../Common/CommonLogout";
import CommonPagination from "../Common/CommonPagination";
import CommonModel from "../Common/CommonModel";
import {
  getBrandsWiseModelList,
  ClearFormSetFutios,
} from "../../redux/actions/adminActions";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";

const UserManagement = () => {
  const { websocket } = useContext(WebSocketContext);

  const Dispatch = useDispatch();
  const { userMemberList, accessToken, user_id, selfInfo, device_id } =
    useSelector((state) => state.adminReducers);
  const Navigate = useNavigate();
  const [userSearch, setUserSearch] = useState("");
  const [userType, setUserType] = useState("All");
  const [userLimit, setUserLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTableDetails, setActiveTableDetails] = useState("");
  const [paginationLength, setPaginationLength] = useState({});
  const [showModal, setShowModal] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });
  useEffect(() => {
    setCurrentPage(1);
  }, [userLimit]);
  // page change
  const currentFunction = (page) => {
    setCurrentPage(page);
  };

  // clear search box
  const ClearSearchFiled = () => {
    setUserSearch("");
  };

  // select user type
  const SelectUserTypeFnct = (e) => {
    setUserType(e.target.value);
    setCurrentPage(1);
  };

  const [showModalNew, setShowModalNew] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });

  const [GetFilterCategoryIds, setGetFilterCategoryIds] = useState("");
  const [GetFilterbrandIds, setGetFilterbrandIds] = useState("");
  const [GetFiltersupplierIds, setGetFiltersupplierIds] = useState("");

  // brand name get
  const BrandNameGet = (brand_id) => {
    return brandlistall?.filter((elm) => brand_id === elm?.id)[0]?.name;
  };

  // model name get
  const ModelNameGet = (model_id) => {
    return modellistall?.filter((elm) => model_id === elm?.id)[0]?.name;
  };

  // add new vendor
  const AddVendorFnct = () => {
    Navigate(ADD_MEMBER_MANAGEMENT_URL);
    Dispatch(getBrandsWiseModelList({}));
  };

  useEffect(() => {
    Dispatch(
      ClearFormSetFutios({
        url: "",
        action: false,
      })
    );
  }, []);
  useEffect(() => {
    setPaginationLength(userMemberList.pagination);
  }, [userMemberList]);

  // get product list
  useEffect(() => {
    let paramRequest = {
      filter: userType,
      limit: userLimit,
      page_no: currentPage,
      search: userSearch.trim(),
      DeviceId: device_id,
      type: "admin",
    };
    const paramfilter = Object.entries(paramRequest).filter((elm) => {
      if (elm[1] !== "") {
        return elm;
      }
    });
    let param = {
      transmit: "single",
      url: "member_list",
      request: Object.fromEntries(paramfilter),
    };
    wsSend_request(websocket, param);
  }, [userSearch, userType, userLimit, currentPage]);
  // useEffect(() => {
  //     fetchData();
  // }, [ userSearch, userType, userLimit, currentPage ]);

  // Get data from api
  const fetchData = async () => {
    const UserListsz = await GetUserListAPI(
      accessToken,
      userSearch === "" ? 0 : userSearch,
      userType,
      userLimit,
      currentPage
    );
    if (UserListsz?.status === 200) {
      Dispatch(getUserList(UserListsz.data));
      setPaginationLength(UserListsz.pagination);
    } else if (UserListsz?.status === 403) {
      CommonLogout();
      Dispatch(getUserList([]));
    } else {
      Dispatch(getUserList([]));
    }
  };

  // show more user details
  const ViewMoreDetails = (id) => {
    setActiveTableDetails(id);
  };

  // hide more user details
  const ElseMoreDetails = () => {
    setActiveTableDetails("");
  };

  // add new user
  const AddUserFnct = () => {
    Navigate(ADD_USER_URL);
  };

  // add new vendor
  const DeleteUserFnct = (user) => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Delete User",
      subtitle: "Delete User",
      description: `Do you really want to delete to <h5>${user?.email}</h5>`,
      modalType: "not form",
      button: "Yes",
      Data: user,
    });
  };

  // allow permission for user
  const AllowPermissGet = (data_, type, booleancheck) => {
    if (type === "is_active") {
      setShowModal({
        ...showModal,
        open: !showModal?.open,
        title: "Are you sure?",
        subtitle: `${type.split("_")[1]} User`,
        description: `Do you really want to ${
          booleancheck ? "deactivate" : "active"
        } to <h5>${data_?.email}</h5>`,
        modalType: "not form",
        button: "Yes",
        Data: data_,
      });
    } else {
      setShowModal({
        ...showModal,
        open: !showModal?.open,
        title: "Are you sure?",
        subtitle: `${type.split("_")[1]} User`,
        description: `Do you really want to ${booleancheck ? "remove " : ""} ${
          type.split("_")[1]
        } to <h5>${data_?.email}</h5>`,
        modalType: "not form",
        button: "Yes",
        Data: data_,
      });
    }
  };

  return (
    <section className="dashboard_wrapper">
      <DataTableFilter
        filterType={"User"}
        tooltip="Search By Name, Email, Phone"
        userSearch={userSearch}
        setUserSearch={setUserSearch}
        userType={userType}
        setUserType={setUserType}
        userLimit={userLimit}
        setUserLimit={setUserLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        ClearSearchFiled={ClearSearchFiled}
        SelectUserTypeFnct={SelectUserTypeFnct}
        // buttonvisible={(selfInfo?.is_admin || (selfInfo?.permissions?.includes("add-user")))}
        // ButtonSet={{
        //     buttontitle: "Add User",
        //     function: AddUserFnct
        // }}
      />
      <div
        className="tableContent"
        style={{
          height:
            window.screen.width > 540
              ? "calc(100vh - 155px)"
              : "calc(100vh - 143px)",
        }}
      >
        <Scrollbars
          style={{
            height:
              paginationLength?.total_records > 10
                ? window.screen.width < 767
                  ? "calc(100% - 10px)"
                  : "calc(100% - 58px)"
                : "calc(100% - 0px)",
          }}
          className="ScrollbarsWrapper"
          renderView={(props) => <div {...props} className="view" />}
        >
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>User Name</th>
                  <th>Profile</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Relation</th>
                  <th>User Type</th>
                  {/* <th>Active</th> */}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {userMemberList &&
                  userMemberList?.list?.map((user, index) => {
                    if (user?.id !== user_id) {
                      return (
                        <React.Fragment key={index}>
                          <tr
                            className={
                              activeTableDetails === user?.id ? "active" : ""
                            }
                          >
                            <td>{user?.username}</td>

                            {/* <td>
                                                <AvatarGet type="product" url={null} />
                                            </td> */}
                            <td>
                              <AvatarGet
                                type="product"
                                url={user?.view_thumbnail_url}
                              />
                            </td>
                            <td>{user?.email}</td>
                            <td>
                              {user?.phone === null || user?.phone === ""
                                ? "-"
                                : "+" + user?.phone_code + " " + user?.phone}
                            </td>
                            <td>
                              {user?.relation === null || user?.relation === ""
                                ? "-"
                                : user?.relation}
                            </td>
                            <td>
                              {user?.is_registered
                                ? "Registered User"
                                : "Member"}
                            </td>
                            {/* <td>
                                                {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("update-user"))) && (
                                                    <CommonToggle 
                                                        valueToggle={user?.is_active}
                                                        setValueToggle={() => AllowPermissGet(user, "is_active", user?.is_active)}
                                                        name={""}
                                                    />
                                                )}
                                            </td> */}
                            <td className="respoactionUsers">
                              <button
                                type="button"
                                className="btn actiontblebtn"
                                onClick={() =>
                                  Navigate(
                                    `${VIEW_USER_MANAGEMENT_URL}/${user?.id}`
                                  )
                                }
                              >
                                <i title="View user" className="bi bi-eye"></i>
                              </button>
                              {(selfInfo?.is_admin ||
                                selfInfo?.permissions?.includes(
                                  "update-user"
                                )) && (
                                <button
                                  type="button"
                                  className="btn actiontblebtn"
                                  onClick={() =>
                                    Navigate(`${EDIT_USER_URL}/${user?.id}`)
                                  }
                                >
                                  <i
                                    title="Edit user"
                                    className="bi bi-pencil-square"
                                  ></i>
                                </button>
                              )}
                              {(selfInfo?.is_admin ||
                                selfInfo?.permissions?.includes(
                                  "delete-user"
                                )) && (
                                <button
                                  type="button"
                                  className="btn actiontblebtn"
                                  onClick={() => DeleteUserFnct(user)}
                                >
                                  <i
                                    className="bi bi-trash-fill"
                                    title="Delete user"
                                    style={{ color: "red" }}
                                  ></i>
                                </button>
                              )}
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    }
                  })}
                {userMemberList && userMemberList?.list?.length === 0 && (
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan={8}>
                      User Not Found !
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Scrollbars>
        {/* pagination */}
        {parseInt(paginationLength?.total_records) > 5 && (
          <CommonPagination
            currentPage={currentPage}
            paginationLength={paginationLength}
            currentFunction={currentFunction}
          />
        )}
        {showModal?.open && (
          <CommonModel
            setUserSearch={setUserSearch}
            showModal={showModal}
            setShowModal={setShowModal}
          />
        )}
      </div>
    </section>
  );
};

export default UserManagement;
