import React, { useState } from "react";
import InputField from "../Common/InputField";
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import { Image } from "react-bootstrap";
import ForgotPasswordImage from "../../assets/img/forgot_password.png";
import { Link, useNavigate } from "react-router-dom";
import { CHANGE_PASSWORD_API_URL, FORGOT_PASSWORD_API_URL, LOGIN_URL, VERIFY_OTP_API_URL } from "../Shared/constant";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ForgotPasswordAPI from "../../Api/ForgotPasswordAPI";
import Otpinput from "./OtpInput";
import LoginImage from "../../assets/img/login_image.png";

const ForgotPassword = () => {
  const [inputValue, setInputValue] = useState({ Email: "", otp: "", Password: "", rePassword: "" });
  const { Email, otp, Password, rePassword } = inputValue;
  const [ showpassword, setshowpassword ] = useState(false);
  const [ reshowpassword, resetshowpassword ] = useState(false);


  const [showOtpForm, setShowOtpForm] = useState(false);
  const [showUpdatePasswordForm, setShowUpdatePasswordForm] = useState(false);
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorrePassword, setErrorrePassword] = useState("");

  const Navigate = useNavigate();
  const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
 // show password
 const ShowHidePassword = () => {
    setshowpassword(!showpassword);
};

  

  const verifyOTP = async (e) => {
    e.preventDefault();

    if (Email !== "" && otp.length === 4) {
      if (EmailRegex.test(Email) === true) {
        const jsonData = JSON.stringify({ email: Email, otp: otp });
        const response = await ForgotPasswordAPI(jsonData, VERIFY_OTP_API_URL);
        if (response?.status === 200) {
          setShowUpdatePasswordForm(true);
          toast.success(response.msg);
        } else {
          clearForm();
          toast.error(response.error);
        }
      } else {
        CheckValid(Email, { type: 'email', error: setErrorEmail });
      }
    }
  };

  const forgotPasswordfunct = async () => {
    if(showUpdatePasswordForm){
        if (inputValue?.Password === inputValue?.rePassword) {
              const jsonData = JSON.stringify({ email: Email,otp:inputValue?.otp,password:inputValue?.Password });
              const response = await ForgotPasswordAPI(jsonData, CHANGE_PASSWORD_API_URL);
              if (response?.status === 200) {
                toast.success(response.msg);
        setTimeout(() => Navigate(LOGIN_URL), 200);
    } else {
                clearForm();
                toast.error(response.error);
              }
      
          }else{
            toast.error("New password and confirm password does not match")
          }
    }else{
    if (Email !== "") {
      if (EmailRegex.test(Email) === true) {
        const jsonData = JSON.stringify({ email: Email });
        const response = await ForgotPasswordAPI(jsonData, FORGOT_PASSWORD_API_URL);
        if (response?.status === 200) {
          toast.success(response.msg);
          setShowOtpForm(true);
        } else {
          clearForm();
          toast.error(response.error);
        }
      } else {
        CheckValid(Email, { type: 'email', error: setErrorEmail });
      }
    } else {
      CheckValid(Email, { type: 'email', error: setErrorEmail });
    }
}
  };

  const handleOtpChange = (otp) => {
    setInputValue({
      ...inputValue,
      otp: otp,
    });
  };

  const clearForm = () => {
    setInputValue({ Email: "", otp: "", Password: "", rePassword: "" });
    setErrorEmail("");
    setErrorPassword("");
    setShowOtpForm(false);
  };

  return (
    <section className="login_wrapper">
      <div className="login_wrap_slider">
        <Image src={showUpdatePasswordForm ? LoginImage : ForgotPasswordImage} alt="login image" />
        <h4>{showUpdatePasswordForm ? "Change Password" : "Forgot Password ?"}</h4>
        <p>{showUpdatePasswordForm ? "Change password to access your admin account" : "Enter your email and we'll send you a link to reset your password."}</p>
      </div>
      <div className="login_form_wraps">
        <div className="loginTEXTS">
          <h4>{showUpdatePasswordForm ? "Change Password" : "Forgot Password ?"}</h4>
        </div>
        {showUpdatePasswordForm ? (
          <>
            <InputField
              type={showpassword ? "text" : "password"}
              value={Password}
              className={errorPassword !== "" ? "form-control error-form" : "form-control"}
              placeholder="Enter Password"
              label="Password"
              name="Password"
              onChange={handleChange}
              onKeyUp={(e) => CheckValid(e.target.value, { type: 'password', error: setErrorPassword })}
              onKeyDown={EmptySpaceFieldValid}
              errormessage={errorPassword}
              onClick={ShowHidePassword}
              showpassword={showpassword}
            />
            <InputField
              type={reshowpassword ? "text" : "password"}
              value={rePassword}
              className={errorrePassword !== "" ? "form-control error-form" : "form-control"}
              placeholder="Confirm Password"
              label="Confirm Password"
              name="rePassword"
              onChange={handleChange}
              onKeyUp={(e) => CheckValid(e.target.value, { type: 'password', error: setErrorrePassword })}
              onKeyDown={EmptySpaceFieldValid}
              errormessage={errorrePassword}
              onClick={()=>{resetshowpassword(!reshowpassword)}}
              showpassword={reshowpassword}
            />
          </>
        ) : (
          <InputField
            type="text"
            value={Email}
            className={errorEmail !== "" ? "form-control error-form" : "form-control"}
            placeholder="Enter email"
            label="Email address"
            name="Email"
            onChange={handleChange}
            onKeyUp={(e) => CheckValid(e.target.value, { type: 'email', error: setErrorEmail })}
            onKeyDown={EmptySpaceFieldValid}
            errormessage={errorEmail}
          />
        )}
        <div className="form-group-btn">
          {showOtpForm && !showUpdatePasswordForm ? (
            <form onSubmit={verifyOTP} className={"loginformstart"}>
              <label className='input-form-label mb-3'>
                A verification code has been sent to <br />
                {Email}</label>
              <div className="form-field otp_wrapper mb-3 ">
                <Otpinput
                  inputStyle="inputStyle"
                  numInputs={4}
                  isDisabled={false}
                  hasErrored={false}
                  errorStyle="error"
                  name="otp"
                  onChange={handleOtpChange}
                  separator={<span></span>}
                  isInputNum={true}
                  isInputSecure={false}
                  shouldAutoFocus
                  value={otp}
                  placeholder={""}
                />
              </div>
              <div className="text-center otp-login-info">
                {/* ... (IntervalTimer or other components) */}
              </div>
              <div className="form-actions auth-actions text-left">
                <button className="btn loginCmn_btn" type="submit" onClick={(e) => verifyOTP(e)}>Verify OTP</button>
              <Link className="fs-6 ml-4" onClick={forgotPasswordfunct}>Resend Code</Link>

              </div>
            </form>
          ) : (
            <>
              <button className="btn loginCmn_btn" onClick={() => forgotPasswordfunct()}>
                {showUpdatePasswordForm ? "Change Password" : "Create Password"}
              </button>
              <Link to={LOGIN_URL} className="btn forgot_password_link"><i className="bi bi-arrow-left"></i> Back</Link>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
