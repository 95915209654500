/* eslint-disable */
import React from "react";
import { Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { Image } from "react-bootstrap";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { ADD_MEMBER_MANAGEMENT_URL, ADD_USER_URL, EDIT_MEMBER_MANAGEMENT_URL, EDIT_REVENUE_MANAGEMENT_URL, VIEW_MEMBER_MANAGEMENT_URL , DASHBOARD_URL, MEMBER_MANAGEMENT_URL, REVENUE_MANAGEMENT_URL, ADD_REVENUE_MANAGEMENT_URL, USER_MANAGEMENT_URL, EDIT_USER_URL, CATEGORY_MANAGEMENT_URL, SOS_MANAGEMENT_URL, FAQ_MANAGEMENT_URL, ADD_FAQ_MANAGEMENT_URL, VIEW_FAQ_MANAGEMENT_URL, EDIT_FAQ_MANAGEMENT_URL } from "../Shared/constant";
import BrandIcon from "../../assets/img/brand-image.png";
import { useSelector } from "react-redux";

const Sidebar = (props) => {
    const { setShowSidebar } = props;
    const RouteUrl = useLocation()?.pathname;
    const RouteUrlEditView = "/" + RouteUrl.split("/")[1];
    const { selfInfo } = useSelector((state) => state.adminReducers);

    return(<div className="sidebar">
        {/* <div className="navbarHeader">
            <h1>Logo</h1>
        </div> */}
        {window.screen.width < 901 && (<button className="close_sidebarbtn" onClick={() => setShowSidebar(false)}><i className="bi bi-x-lg"></i> </button>)}
        <Scrollbars 
            style={{ height: "calc(100vh)" }} 
            renderView={props => <div {...props} className="view"/>}
            className="ScrollbarsSidebar"
        >
            {window.screen.width < 901 ? (<Nav defaultActiveKey={DASHBOARD_URL} className="flex-column">
                {(selfInfo?.is_admin || selfInfo?.permissions?.includes("dashboard")) && (<Link to={DASHBOARD_URL} onClick={() => setShowSidebar(false)} className={(RouteUrl === DASHBOARD_URL || RouteUrl === "/") ? "nav-link active" : "nav-link"}>
                    <i className="bi bi-speedometer"></i>
                    <span>Dashboard</span>
                </Link>)}
                {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("user-list") || selfInfo?.permissions?.includes("add-user") || selfInfo?.permissions?.includes("update-user") || selfInfo?.permissions?.includes("delete-user"))) && (<>
                    {selfInfo?.is_admin && (<Link to={USER_MANAGEMENT_URL} onClick={() => setShowSidebar(false)} className={(RouteUrl === USER_MANAGEMENT_URL || RouteUrl === ADD_USER_URL || RouteUrlEditView === EDIT_USER_URL || RouteUrl === "/") ? "nav-link active" : "nav-link"}>
                        <i className="bi bi-person-gear"></i>
                        <span>User Management</span>
                    </Link>)}
                </>)}
                {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("patient-list") || selfInfo?.permissions?.includes("add-patient") || selfInfo?.permissions?.includes("update-patient") || selfInfo?.permissions?.includes("delete-patient"))) && (<Link to={MEMBER_MANAGEMENT_URL} onClick={() => setShowSidebar(false)} className={(RouteUrl === MEMBER_MANAGEMENT_URL || RouteUrl === ADD_MEMBER_MANAGEMENT_URL || RouteUrlEditView === VIEW_MEMBER_MANAGEMENT_URL || RouteUrlEditView === EDIT_MEMBER_MANAGEMENT_URL || RouteUrl === "/") ? "nav-link active" : "nav-link"}>
                <i className="bi bi bi-person-square"></i>
                <span>Patient Management</span>
                </Link>)}
                {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("add-alert") || selfInfo?.permissions?.includes("update-alert") || selfInfo?.permissions?.includes("delete-alert"))) && (<Link to={CATEGORY_MANAGEMENT_URL} onClick={() => setShowSidebar(false)} className={(RouteUrl === CATEGORY_MANAGEMENT_URL || RouteUrl === "/") ? "nav-link active" : "nav-link"}>
                    <i className="bi bi-layout-text-sidebar-reverse"></i>
                    <span>Alert Management</span>
                </Link>)}
                {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("sos-list") || selfInfo?.permissions?.includes("add-sos") || selfInfo?.permissions?.includes("update-sos") || selfInfo?.permissions?.includes("delete-sos"))) && (<Link onClick={() => setShowSidebar(false)} to={SOS_MANAGEMENT_URL} className={RouteUrl === SOS_MANAGEMENT_URL ? "nav-link active" : "nav-link"}>
                    <i className="bi bi-exclamation-octagon"></i>
                    <span>SOS Management</span>
                </Link>)}
                {/* {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("add-revenue") || selfInfo?.permissions?.includes("update-revenue") || selfInfo?.permissions?.includes("delete-revenue") || selfInfo?.permissions?.includes("revenue-list"))) && (<Link onClick={() => setShowSidebar(false)} to={REVENUE_MANAGEMENT_URL} className={(RouteUrl === REVENUE_MANAGEMENT_URL || RouteUrl === ADD_REVENUE_MANAGEMENT_URL || RouteUrlEditView ===  VIEW_REVENUE_MANAGEMENT_URL || RouteUrlEditView === EDIT_REVENUE_MANAGEMENT_URL || RouteUrl === "/") ? "nav-link active" : "nav-link"}>
                <i className="bi bi-coin"></i>
                    <span>Revenue Management</span>
                </Link>)} */}
            </Nav>) : (<Nav defaultActiveKey={DASHBOARD_URL} className="flex-column">
                {(selfInfo?.is_admin || selfInfo?.permissions?.includes("dashboard")) && (<Link to={DASHBOARD_URL} className={(RouteUrl === DASHBOARD_URL || RouteUrl === "/") ? "nav-link active" : "nav-link"}>
                    <i className="bi bi-speedometer"></i>
                    <span>Dashboard</span>
                </Link>)}
                {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("user-list") || selfInfo?.permissions?.includes("add-user") || selfInfo?.permissions?.includes("update-user") || selfInfo?.permissions?.includes("delete-user"))) && (<>
                    {selfInfo?.is_admin && (<Link to={USER_MANAGEMENT_URL} className={(RouteUrl === USER_MANAGEMENT_URL || RouteUrl === ADD_USER_URL || RouteUrlEditView === EDIT_USER_URL || RouteUrl === "/") ? "nav-link active" : "nav-link"}>
                        <i className="bi bi-person-gear"></i>
                        <span>User Management</span>
                    </Link>)}
                </>)}
                {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("patient-list") || selfInfo?.permissions?.includes("add-patient") || selfInfo?.permissions?.includes("update-patient") || selfInfo?.permissions?.includes("delete-patient"))) && (<Link to={MEMBER_MANAGEMENT_URL} className={(RouteUrl === MEMBER_MANAGEMENT_URL || RouteUrl === ADD_MEMBER_MANAGEMENT_URL || RouteUrlEditView === VIEW_MEMBER_MANAGEMENT_URL || RouteUrlEditView === EDIT_MEMBER_MANAGEMENT_URL || RouteUrl === "/") ? "nav-link active" : "nav-link"}>
                <i className="bi bi bi-person-square"></i>
                    <span>Patient Management</span>
                </Link>)}
                {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("add-alert") || selfInfo?.permissions?.includes("update-alert") || selfInfo?.permissions?.includes("delete-alert"))) && (<Link to={CATEGORY_MANAGEMENT_URL} className={(RouteUrl === CATEGORY_MANAGEMENT_URL || RouteUrl === "/") ? "nav-link active" : "nav-link"}>
                    <i className="bi bi-layout-text-sidebar-reverse"></i>
                    <span>Alert Management</span>
                </Link>)}
                {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("sos-list") || selfInfo?.permissions?.includes("add-sos") || selfInfo?.permissions?.includes("update-sos") || selfInfo?.permissions?.includes("delete-sos"))) && (<Link to={SOS_MANAGEMENT_URL} className={RouteUrl === SOS_MANAGEMENT_URL ? "nav-link active" : "nav-link"}>
                    <i className="bi bi-exclamation-octagon"></i>
                    <span>SOS Management</span>
                </Link>)}
                {/* {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("add-revenue") || selfInfo?.permissions?.includes("update-revenue") || selfInfo?.permissions?.includes("delete-revenue") || selfInfo?.permissions?.includes("supplier-list"))) && (<Link to={REVENUE_MANAGEMENT_URL} className={(RouteUrl === REVENUE_MANAGEMENT_URL || RouteUrl === ADD_REVENUE_MANAGEMENT_URL || RouteUrlEditView ===  VIEW_REVENUE_MANAGEMENT_URL || RouteUrlEditView === EDIT_REVENUE_MANAGEMENT_URL || RouteUrl === "/") ? "nav-link active" : "nav-link"}>
                <i className="bi bi-coin"></i>
                    <span>Revenue Management</span>
                </Link>)} */}
                {(selfInfo?.is_admin ||
              selfInfo?.permissions?.includes("faq-list") ||
              selfInfo?.permissions?.includes("add-faq") ||
              selfInfo?.permissions?.includes("update-faq") ||
              selfInfo?.permissions?.includes("delete-faq")) && (
              <Link
                to={FAQ_MANAGEMENT_URL}
                className={
                  RouteUrl === FAQ_MANAGEMENT_URL ||
                  RouteUrl === ADD_FAQ_MANAGEMENT_URL ||
                  RouteUrlEditView === VIEW_FAQ_MANAGEMENT_URL ||
                  RouteUrlEditView === EDIT_FAQ_MANAGEMENT_URL ||
                  RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="bi bi-patch-question"></i>
                <span>FAQ Management</span>
              </Link>
            )}
            </Nav>)}
        </Scrollbars>
    </div>)
};

export default Sidebar;